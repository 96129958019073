import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

export default class BreadCrumbs extends Component {
	render() {

		const pagination = this.props.pages.map((page,index) => {
			if( page.link )
			// return (<li key={index}><a href={page.link}>{page.title}</a></li>)
			// return (<li key={index}>{page.title}</li>)

				return (<li key={index}><a href={page.link}>
					<FormattedMessage id={page.title} defaultMessage={page.title}></FormattedMessage>
					</a></li>)
			if( !page.title ) return
			return (<li key={index}><FormattedMessage id={page.title} defaultMessage={page.title}></FormattedMessage></li>)
		});

		return (
			<section className="gray-bg">
				<div className="container">
					<ul className="pagination">
						{pagination}
					</ul>
				</div>
			</section>
		)
	}
}