import React, { Component } from 'react'

import { connect }  from 'react-redux'

import { FormattedMessage } from 'react-intl'

import Header from '../partials/Header'
import BreadCrumbs from '../partials/BreadCrumbs'
import HeadBanner from '../partials/HeadBanner'
import Footer from '../partials/Footer'

import OrderSidebar from '../components/OrderSidebar'
import OrderFormListing from '../components/OrderFormListing'
import OrderCart from '../components/OrderCart'

import OrderFormService from '../services/OrderFormService'
import { fetchOrderForm } from '../actions/OrderFormActions'

import { addOrderItem, removeOrderItem, emptyCart } from '../actions/OrderCartActions'

class Order extends Component {

	constructor(props) {
		
		super(props)

		this.state = {
			orderItemsLoaded: false
		}

	}

	componentWillMount() {
		
		const match = this.props.match;
		const orderFormService = new OrderFormService()
		const self = this;

		orderFormService.fetchOrderForm(match.params.orderFormId).then((response) => {
			self.props.fetchOrderForm(response)
			self.props.loadRequiredInCart();
			self.setState({
				orderItemsLoaded: true
			})
			//console.log(response, match.params.orderFormId, "JSON Response");
		})
		
	}
	
	componentDidUpdate(prevProps) {
		if( this.props.match.params.orderFormId != prevProps.match.params.orderFormId ) {
			this.componentWillMount();
		}
	}

	isIncorporateForm() {

		const incorporateKeywords = [
			'private limited company', 'company limited by guarantee', 'partnership',
			'singapore pte ltd',
			'foreign companies in singapore', 'british virgin island',
			'cayman ec',
			'cayman llc',
			'seychelles'
		];

		if( !this.props.OrderForm.orderForm.title ) return false;

		if( !this.props.OrderForm.hasOwnProperty('orderForm') )
			return false

		if( !this.props.OrderForm.orderForm.hasOwnProperty('title') )
			return false

		const formTitle = this.props.OrderForm.orderForm.title.toLowerCase();

		const foundKeyword = incorporateKeywords.find((v) => {
			return !!~formTitle.indexOf(v)
		})

		if( foundKeyword )
			return true;

		return false

	}

	assertTitle(currentItem) {

		return this.props.currentLang == 'en' ? currentItem.title : currentItem.type

	}

	render() {
		
		const orderItemsLoaded = this.state.orderItemsLoaded;
		const isIncorporate = this.isIncorporateForm()

		return (
			<div>
				<Header />
				<HeadBanner title="Place your Order" />
				<BreadCrumbs pages={ [
					{
						title: "Home1",
						link: "/"
					},
					{
						title: "Our Solutions",
						link: "/our-solutions"
					},
					{
						title: this.assertTitle(this.props.OrderForm.orderForm)
					}
				] } />
				<section className="outer order">
					<div className="container">
						<div className={"row column-form order-form-" + this.props.OrderForm.orderForm.formId }>
							{ this.props.OrderForm.orderForm.layout == '2 Column' ? '' : (
								<OrderSidebar />
							) }
							<div className={ this.props.OrderForm.orderForm.layout != '2 Column' ? 'col-md-6 center-content' : 'col-md-9 order-wrapper'}>
								{ orderItemsLoaded ? <OrderFormListing /> : '' }
							</div>
							<div className="col-md-3 order-sidebar">
								{ orderItemsLoaded ? <OrderCart /> : '' }
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		OrderForm: state.OrderForm,
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	fetchOrderForm: (formData) => (dispatch) => {
		dispatch(fetchOrderForm(formData))
	},
	loadRequiredInCart: () => (dispatch, getState) => {

		const store = getState();
		const parentItem = null;

		dispatch(emptyCart());

		if( store.OrderForm.hasOwnProperty("orderForm") ) {

			const itemTypes = ['required', 'addon', 'recommended', 'maintenance'];

			for (var i = 0; i < itemTypes.length; i++) {

				for (var r = 0; r < store.OrderForm.orderForm[itemTypes[i]].length; r++) {
					
					let v = store.OrderForm.orderForm[itemTypes[i]][r];
					v.itemType = itemTypes[i];
					
					if( v.makeSelected ) {
						dispatch(addOrderItem({orderItem: v, parentItem}))
					}
					
					v.child.forEach((v2) => {
						v2.itemType = itemTypes[i];
						
						if( v2.makeSelected ) {
							dispatch(addOrderItem({orderItem: v2, v}))
						}
					});

				}
			}

		}

	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Order)