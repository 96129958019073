import React, { Component } from 'react'
import { Redirect } from 'react-router'

import { connect }  from 'react-redux'
import { FormattedMessage } from 'react-intl'

import Header from '../partials/Header'
import BreadCrumbs from '../partials/BreadCrumbs'
import HeadBanner from '../partials/HeadBanner'

import Footer from '../partials/Footer'

import BillingDetails from '../components/BillingDetails'
import OrderDetails from '../components/OrderDetails'
import CheckoutPaymentMethod from '../components/CheckoutPaymentMethod'


//import { addOrderItem, removeOrderItem } from '../actions/OrderCartActions'

class Thankyou extends Component {

	constructor(props) {
		
		super(props)

		this.state = {
		}

	}

	componentWillMount() {

		const self = this;
		
	}

	render() {

		//{ !this.state.isCheckoutAllowed ? ( <Redirect to="/" push /> ) : "" }
		
		return (
			<div>
				<Header />
				<HeadBanner title="Checkout " />
				<BreadCrumbs pages={ [
					{
						title: "Home",
						link: "/"
					},
					{
						title: "ORDER FORM"
					},
					{
						title: "Check Out"
					}
				] } />
				<section class="outer bg-white gray grayshape">
					<div class="container small-container">
						<div class="row">
							<div class="col-md-12 pt-4">
							    <div className="success-container">
								<div className="success"><img src="/images/check.png" /></div>
								{/* <h2 className="small-heading">Success!</h2> */}
								<h2 className="small-heading"><FormattedMessage id="Order Received" defaultMessage="Order Received"></FormattedMessage></h2>
								<p className="text16gray">
									{/* Check your email for payment confirmation and details.<br /> */}
									<FormattedMessage id="Check your email to find out the bank wire instruction." defaultMessage="Check your email to find out the bank wire instruction."></FormattedMessage>
									<br />
									<FormattedMessage id="For enquires, contact us at" defaultMessage="For enquires, contact us at"></FormattedMessage>
									<a href="tel:+6588824795">+65 8882 4795</a> <FormattedMessage id="or" defaultMessage="or"></FormattedMessage> <a href="mailto:info@jenga.io">info@jenga.io</a>
								</p>
								
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou)