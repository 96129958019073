import React, { Component } from 'react'
import { Redirect } from 'react-router'

import { connect } from 'react-redux'

import { FormattedMessage } from 'react-intl'

import Header from '../partials/Header'
import BreadCrumbs from '../partials/BreadCrumbs'
import HeadBanner from '../partials/HeadBanner'

import AcknowledgmentSection from '../components/AcknowledgmentSection'

import AcknowledgmentService from '../services/AcknowledgmentService'
import NewsListService from '../services/NewsService'
import $ from 'jquery';
import Footer from '../partials/Footer'
import Swiper from "swiper"
import "swiper/dist/css/swiper.css"
import "../css/views/NewsSwiper.css"


//import { addOrderItem, removeOrderItem } from '../actions/OrderCartActions'

class Knowledge extends Component {

	constructor(props) {

		super(props)

		this.acknowledgmentService = new AcknowledgmentService();
		this.NewsListService = new NewsListService();
		this.assertTitle = this.assertTitle.bind(this)

		this.state = {
			acknowledgmentData: [],
			videoActive: '',
			videoActivePoster: '',
			videoList: [
				{
					id: '1',
					title: '介绍',
					titleEn:'Introduction',
					videoPoster: 'images/play.png',
					videoUrl: '/videos/home-zh.mp4',
					videoUrlEn: '/videos/home-en.mp4',
					imageUrl: '/images/play-icon.png'
				},
				// {
				// 	id: '2',
				// 	title: 'News & Policies & Notices',
				// 	videoPoster: 'images/structring.png',
				// 	videoUrl: '/videos/home-en.mp4',
				// 	imageUrl: '/images/play-icon.png'
				// },
				// {
				// 	id: '3',
				// 	title: 'News & Policies & Notices',
				// 	videoPoster: 'images/tax.png',
				// 	videoUrl: '/videos/home-zh.mp4',
				// 	imageUrl: '/images/play-icon.png'
				// }

			],
			isPC:true,
			newsList: []
		}

	}

	assertTitle(currentItem) {

		return this.props.currentLang == 'en' ? currentItem.field_description : currentItem.field_chinese_description

	}

	getVideoUrl(data) {
		const nowVideo = this.props.currentLang == 'en' ? data.videoUrlEn:data.videoUrl
		this.setState({
			videoActive: nowVideo,
			videoActivePoster: data.videoPoster
		})
	}

	componentWillMount() {

		const self = this;
		if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) { //移动端
			this.setState({
				isPC: false
			})
		}
		// console.log(/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)+'1111')

		this.NewsListService.getNewsListSections().then((response) => {
			if (response.length) {
				this.setState({
					newsList: response
				})
				this.swiper = new Swiper('.swiper-container', {
					slidesPerView: this.state.isPC ? 3 : 1,
					loop: true,
					// spaceBetween: 25,
					centeredSlides: true,
					loop: true,
					autoplay: {
						delay: 3000,
						disableOnInteraction: false
					},
					pagination: {
						el: '.swiper-pagination',
						clickable: true,
					}
				})
			}
		})
		this.acknowledgmentService.getAcknowledgmentSections().then((response) => {
			if (response.length) {
				this.setState({
					acknowledgmentData: response
				})
			}
		});
		const nowVideo = this.props.currentLang == 'en' ? this.state.videoList[0].videoUrlEn:this.state.videoList[0].videoUrl
		this.setState({
			videoActive: nowVideo,
			videoActivePoster: this.state.videoList[0].videoPoster
		})

	}

	render() {

		const NewsListSections = this.state.newsList.map((section) => {
			return (
				<div class="swiper-slide" key={section.field_image}>
					<div className="newsItem">
						<a href={section.field_source_url} target="_blank" rel="noopener noreferrer">
							<div className="newsImage">
								<img className="newsimg" src={'//api.jengacorp.com/' + section.field_image} alt="" />
							</div>
							<div className="newsInfo">
								<div className='info-box'>
									{this.assertTitle(section)}
								</div>
							</div>
						</a>
					</div>
				</div>
			)
		})

		const videoListSections = this.state.videoList.map((section) => {
			return (
				<li className='video-listItem' key={section.id} onClick={this.getVideoUrl.bind(this, section)}>
					<div className='imgbox'>
						<img src={section.imageUrl} alt="" />
					</div>
					<span className='video-info bold'>{this.props.currentLang == 'en' ?section.titleEn:section.title}</span>
				</li>
			)
		})

		const acknowledgmentSections = this.state.acknowledgmentData.map((section) => {
			return (
				<AcknowledgmentSection section={section} />
			);
		});

		return (
			<div>
				<Header />
				<HeadBanner title="Knowledge Center" />
				<BreadCrumbs pages={[
					{
						title: "Home",
						link: "/"
					},
					{
						title: "Knowledge Center"
					}
				]} />

				<section class="outer knowledge bg-white gray pb-md-5">
					<div class="container mb-md-5 pb-md-5">
						<div class="small-container">
							{/* <div class="row justify-content-between position-relative bdrleft">
								<div class="col-md-5 pt-4">
									<h2 class="heading dark-gray">Article Categories</h2>
									<ul class="listing">
										{acknowledgmentSections}
									</ul>
								</div>
								<div class="col-md-7 pt-4 pull-right text14 pl-md-5 pb-md-3">
									<h3 class="small-heading bold seprator"><span>Notices</span></h3>
									<div class="gray-box">
										<div class="row">
											<div class="col-md-7">
												<ul class="p-0 site-color">
													<li>因Jenga BCG内部架构调整，秘书公司更名为Jenga Corp，您的新加坡公司地址也一并转移到我们CBD办公室，由Jenga Corp秘书统一管理，提高便捷和安全度。
													也请注意，原有地址在变更期间仍会代收贵司信件，请无需担忧。
												</li>
												</ul>
											</div>
											<div class="col-md-5">
												<img src="/images/image.jpg" alt="" />
											</div>
										</div>
									</div>
									<h3 class="small-heading bold seprator"><span>News & Policies</span></h3>
									<div class="gray-box">
										<ul class="p-0 site-color">
											<li>新加坡公司架构和注册攻略2019（收藏级）</li>
											<li>新加坡资管牌照全面解析</li>
											<li>企业动态--Jenga携美国律师成立区块链合规顾问公司</li>
										</ul>
										<ul class="p-0 mt-4">
											<li>了解更多资讯清关注Jenga微信服务号：简客秘书JengaCorp，或扫描下方二维码</li>
										</ul>
										<img src="/images/qr-code.jpg" alt="qr" width="88" />
									</div>
								</div>
							</div> */}

							<div class="mb-md-5 pb-md-5">
								<h3 class="small-heading bold fontCenter" style={this.props.currentLang == 'en'? {} : {fontFamily:'华文细黑',fontWeight:'600'} }><FormattedMessage id="NEWS" defaultMessage="NEWS"></FormattedMessage></h3>
								<p className="fontCenter bold"><FormattedMessage id="News & Policies & Notices" defaultMessage="News & Policies & Notices"></FormattedMessage></p>
								<div className="news-box row justify-content-between position-relative">
									{/* <!-- Slider main container --> */}
									<div class="swiper-container" style={{ height: 340 + 'px' }}>
										{/* <!-- Additional required wrapper --> */}
										<div class="swiper-wrapper">
											{/* <!-- Slides --> */}
											{NewsListSections}
										</div>
										{/* <!-- If we need pagination --> */}
										<div class="swiper-pagination"></div>
									</div>
								</div>
							</div>

							<div class="mb-md-5 pb-md-5">
								<h3 class="small-heading bold fontCenter" style={this.props.currentLang == 'en'? {} : {fontFamily:'华文细黑',fontWeight:'600'} }><FormattedMessage id="VIDEO" defaultMessage="VIDEO"></FormattedMessage></h3>
								<p className="fontCenter bold"><FormattedMessage id="Introduction" defaultMessage="Introduction"></FormattedMessage></p>
								<div className="video-box row justify-content-between position-relative">
									<div className="col-md-6 nopadd">
										{/* <video src="" className="player"></video> */}
										<video ref="bVideo" class="video player" poster={this.state.videoActivePoster} src={this.state.videoActive}>
											{/* <source src={this.state.videoActive} type="video/mp4" /> */}
										</video>
									</div>
									<div className="col-md-6 nopadd">
										<ul className='video-list'>
											{videoListSections}
										</ul>
									</div>
								</div>
							</div>

							<div class="mb-md-5 pb-md-5">
								<h2 class="heading dark-gray" style={this.props.currentLang == 'en'? {} : {fontFamily:'华文细黑',fontWeight:'600'} }><FormattedMessage id="Article Categories" defaultMessage="Article Categories"></FormattedMessage></h2>
								<ul class="listing">
									{acknowledgmentSections}
								</ul>
							</div>
						</div>
					</div>
				</section>

				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Knowledge)