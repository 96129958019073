export const UPDATE_CHECKOUT = 'UPDATE_CHECKOUT';
export const SET_CHECKOUT_ERROR = 'SET_CHECKOUT_ERROR';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';

export const updateCheckout = (checkoutData) => {

	return {
		type: UPDATE_CHECKOUT,
		payload: checkoutData
	};

}

export const setCheckoutError = (checkoutError) => {

	return {
		type: SET_CHECKOUT_ERROR,
		payload: checkoutError
	};

}

export const setCompanyName = (companyName) => {

	return {
		type: SET_COMPANY_NAME,
		payload: companyName
	};

}

export const clearCheckoutError = () => {

	return {
		type: SET_CHECKOUT_ERROR,
		payload: ''
	};

}