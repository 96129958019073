import React, { Component } from 'react'
import { connect }  from 'react-redux'

import AcknowledgmentEntry from './AcknowledgmentEntry'

class AcknowledgmentSection extends Component {
	
	

	render() {

		const acknowledgmentEntries = this.props.section.view.map((v) => {
			return (
				<AcknowledgmentEntry entry={v} />
			)
		})

		let entriesList = '';

		if( acknowledgmentEntries.length > 0 ) {
			entriesList = (
				<ul class="subnav">
					{ acknowledgmentEntries }
				</ul>
			);
		}

		return (
			<li>
				{this.props.currentLang == 'en'? this.props.section.title:this.props.section.field_title_cn}
				{ entriesList }
			</li>
		)
	}

}


// export default AcknowledgmentSection
const mapStateToProps = (state) => {
	return {
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(AcknowledgmentSection)