import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter as Router, Route, Link } from "react-router-dom"
import localStorage from 'local-storage'

//HOC
import ScrollHandler from './hoc/ScrollHandler'
import { addLocaleData } from 'react-intl'
// import { Provider } from 'react-redux'
import { IntlProvider, updateIntl } from 'react-intl-redux'

//Store
import reduxStore from './store'
import { connect }  from 'react-redux'

import Home from './views/Home'
import Order from './views/Order'
import Checkout from './views/Checkout'
import About from './views/About'
import Contact from './views/Contact'
import Terms from './views/Terms'
import CommonBusiness from './views/CommonBusiness'
import OurSolutions from './views/OurSolutions'
import Knowledge from './views/Knowledge'
import Thankyou from './views/Thankyou'
import Success from './views/Success'
import Error from './views/Error'
import Cancelled from './views/Cancelled'
import PrivacyPolicy from './views/PrivacyPolicy'

//Actions
import { CHANGE_LANGAUGE } from './actions/IntlActions'

//Locals
import locale_en from 'react-intl/locale-data/en';
import locale_zh from 'react-intl/locale-data/zh';

//CSS
import './css/components/App.css'
import './css/components/index.scss'

addLocaleData([...locale_en, ...locale_zh]);

const switchLanguage = (language) => {

	const state = reduxStore.getState();

	reduxStore.dispatch(updateIntl({
		locale: language,
		messages: state.locales.locales[language],
	}))

}

global.switchLanguage = switchLanguage

//import Test from './views/Test'
//<Route path="/test-payment" component={Test} />
//import reducers from '<project-path>/reducers'

class App extends Component {

	constructor(props) {

		super(props)

	}

	componentWillMount() {

		this.initLocal();

	}


	initLocal() {

		let lang = localStorage.get('lang')

		if (!lang) {
			lang = 'en'
		}

		reduxStore.dispatch({
			type: CHANGE_LANGAUGE,
			payload: {
				language: lang
			}
		})

		switchLanguage(lang)

	}

	render() {

		return (
			// <Provider store={reduxStore}>
			<IntlProvider>
				<Router>
					<ScrollHandler>
						<div className={this.props.currentLang == 'en'? 'jinga_en' : 'jinga_zh'}>
							<Route exact path="/" component={Home} />
							<Route exact path="/order/thankyou/:transactionId" component={Thankyou} />
							<Route exact path="/order/thankyou/success/:transactionId" component={Success} />
							<Route exact path="/order/thankyou/cancel/:transactionId" component={Error} />
							<Route exact path="/order/cancelled" component={Cancelled} />
							<Route path="/contact" component={Contact} />
							<Route path="/about" component={About} />
							<Route path="/terms" component={Terms} />
							<Route path="/common-business" component={CommonBusiness} />
							<Route path="/our-solutions" component={OurSolutions} />
							<Route path="/knowledge-center" component={Knowledge} />
							<Route path="/privacy-policy" component={PrivacyPolicy} />
							<Route exact path="/order-form/:orderFormId" component={Order} />
							<Route path="/order/checkout" component={Checkout} />
						</div>
					</ScrollHandler>
				</Router>
			</IntlProvider>
			// </Provider>

		);

	}

}

// export default App;

const mapStateToProps = (state) => {
	return {
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(App)
