import React, { Component } from 'react'
import { Redirect } from 'react-router'

import { connect }  from 'react-redux'
import { FormattedMessage } from 'react-intl'

import Header from '../partials/Header'
import BreadCrumbs from '../partials/BreadCrumbs'
import HeadBanner from '../partials/HeadBanner'

import Footer from '../partials/Footer'

import BillingDetails from '../components/BillingDetails'
import OrderDetails from '../components/OrderDetails'
import CheckoutPaymentMethod from '../components/CheckoutPaymentMethod'


//import { addOrderItem, removeOrderItem } from '../actions/OrderCartActions'

class Cancelled extends Component {

	constructor(props) {
		
		super(props)

		this.state = {
		}

	}

	componentWillMount() {

		const self = this;
		
	}

	render() {

		//{ !this.state.isCheckoutAllowed ? ( <Redirect to="/" push /> ) : "" }
		
		return (
			<div>
				<Header />
				<HeadBanner title="Cancelled" />
				<BreadCrumbs pages={ [
					{
						title: "Home",
						link: "/"
					},
					{
						title: "CANCELLED"
					}
				] } />
				<section class="outer bg-white gray grayshape">
					<div class="container small-container">
						<div class="row">
							<div class="col-md-12 pt-4">
							    <div className="success-container">
								<div className="success"><img src="/images/cancel.png" /></div>
								<h2 className="small-heading"><FormattedMessage id="Cancelled" defaultMessage="Cancelled"></FormattedMessage></h2>
								<p className="text16gray">
								<FormattedMessage id="Your order has been cancelled" defaultMessage="Your order has been cancelled"></FormattedMessage><br />
								<FormattedMessage id="For enquires, contact us at" defaultMessage="For enquires, contact us at"></FormattedMessage>
								<a href="tel:+6588824795">+65 8882 4795</a> <FormattedMessage id="or" defaultMessage="or"></FormattedMessage> <a href="mailto:info@jenga.io">info@jenga.io</a>
								</p>
								
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(Cancelled)