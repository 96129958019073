import BaseService from './BaseService'

export default class OrderFormService extends BaseService {

	saveCart(cart) {
		return this.apiCall(
			`order_form/save-cart`,
			'POST',
			cart,
			true
		).then((response) => {
			return response.data
		})
	}

	getCart() {
		return this.apiCall(
			`order_form/get-cart`,
			'POST',
			{},
			true
		).then((response) => {
			return response.data
		})
	}

	getCheckoutForm(checkoutData) {
		return this.apiCall(
			`order_form/payment`,
			'POST',
			checkoutData,
			true
		).then((response) => {
			return response.data
		})
	}

}