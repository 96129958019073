import React, { Component } from 'react'
import { Redirect } from 'react-router'

import { connect }  from 'react-redux'

import { FormattedMessage } from 'react-intl'

import Header from '../partials/Header'
import BreadCrumbs from '../partials/BreadCrumbs'
import HeadBanner from '../partials/HeadBanner'

import Footer from '../partials/Footer'

import BillingDetails from '../components/BillingDetails'
import OrderDetails from '../components/OrderDetails'
import CheckoutPaymentMethod from '../components/CheckoutPaymentMethod'


//import { addOrderItem, removeOrderItem } from '../actions/OrderCartActions'

class OurSolutions extends Component {

	constructor(props) {
		
		super(props)

		this.state = {
		}

	}

	componentWillMount() {

		const self = this;
		
	}

	render() {

		//{ !this.state.isCheckoutAllowed ? ( <Redirect to="/" push /> ) : "" }
		
		return (
			<div>
				<Header />
				<HeadBanner title="Our Solutions" />
				<BreadCrumbs pages={ [
					{
						title: "Home",
						link: "/"
					},
					{
						title: "Our Solutions"
					}
				] } />
				<section className="outer bg-white gray pb-0 text14">
					<div className="container transparent pb-5 ">
					 <div className="small-container pb-3">
						<div className="row mt-5 solutions-row align-items-center" id="company">
							<div className="col-md-5 image-column d-flex align-items-center"> 
								<img src="/images/incorporation.png" alt="incorporation" />
							</div>
							<div className="col-md-7 pl-md-5">
								<h4 className="mb-3 our_solutions_title">
									<FormattedMessage id="COMPANY INCORPORATION" defaultMessage="COMPANY INCORPORATION"></FormattedMessage>
								</h4>
								<p>
									<FormattedMessage id="our_solutions.company_incorporation_content" defaultMessage="Here at Jenga Corp, we offer fast and affordable company incorporation services for individuals and corporate entities in Singapore and multiple offshore jurisdictions. Our incorporation specialists advice you on the structure and type of entity that ideally fits your business needs and ensures the optimal tax benefits. We give you a head start!"></FormattedMessage>
								</p>
								<ul className="custom-list" style={this.props.currentLang == 'en'? {} : {fontFamily:'华文细黑'} }>
									<li><a href="/order-form/1"><FormattedMessage id="SINGAPORE" defaultMessage="SINGAPORE"></FormattedMessage></a></li>
									<li><a href="/order-form/54"><FormattedMessage id="BRITISH VIRGIN ISLANDS" defaultMessage="BRITISH VIRGIN ISLANDS"></FormattedMessage></a></li>
									<li><a href="/order-form/53"><FormattedMessage id="CAYMAN" defaultMessage="CAYMAN"></FormattedMessage></a></li>
									<li><a href="/order-form/62"><FormattedMessage id="SEYCHELLES" defaultMessage="SEYCHELLES"></FormattedMessage></a></li>									
								</ul>
							</div>
						</div>
						<div className="row mt-5 solutions-row align-items-center" id="immigration">
							<div className="col-md-5 image-column d-flex align-items-center"> 
								<img src="/images/imigration.png" alt="imigration" />
							</div>
							<div className="col-md-7 pl-md-5">
								<h4 className="mb-3 our_solutions_title">
									<FormattedMessage id="IMMIGRATION & WORK PERMITS" defaultMessage="IMMIGRATION & WORK PERMITS"></FormattedMessage>
								</h4>
								<p>
								<FormattedMessage id="Considering a relocation to Singapore" defaultMessage="Considering a relocation to Singapore for business or for work? Singapore offers several immigration options. We can advise you on the best viable option for you and your family. We have secured work permits, employment pass, dependant pass, entrepreneur pass and permanent resident status for applicants from all over the world. We make it easy for you!"></FormattedMessage>
								</p>
								<ul className="custom-list" style={this.props.currentLang == 'en'? {} : {fontFamily:'华文细黑'} }>
									<li><a href="/order-form/65"><FormattedMessage id="EMPLOYMENT PASS" defaultMessage="EMPLOYMENT PASS"></FormattedMessage></a></li>
									<li><a href="/order-form/66"><FormattedMessage id="ENTREPASS" defaultMessage="ENTREPASS"></FormattedMessage></a></li>
									<li><a href="/order-form/107"><FormattedMessage id="DEPENDANT PASS" defaultMessage="DEPENDANT PASS"></FormattedMessage></a></li>
									<li><a href="/order-form/65"><FormattedMessage id="GIP investment immigration" defaultMessage="GIP investment immigration"></FormattedMessage></a></li>
								</ul>
							</div>
						</div>
						<div className="row mt-5 solutions-row align-items-center" id="corporate">
							<div className="col-md-5 image-column d-flex align-items-center"> 
								<img src="/images/secretary.png" alt="secretary" />
							</div>
							<div className="col-md-7 pl-md-5">
								<h4 className="mb-3 our_solutions_title"><FormattedMessage id="CORPORATE SECRETARY" defaultMessage="CORPORATE SECRETARY"></FormattedMessage></h4>
								<p>
								<FormattedMessage id="our_solutions.rporate_secretaryco_contents" defaultMessage='As a registered filing agent certified by ACRA Singapore. We can be your secretary to assist you with Maintenance of minutes and registers, Drafting of standard directors resolutions, Preparation of annual general meeting minutes, Submission of annual return and XBRL, and Other substantial administrative "tasks".'></FormattedMessage>

								</p>
								<ul className="custom-list" style={this.props.currentLang == 'en'? {} : {fontFamily:'华文细黑'} }>
								  <li><a href="/order-form/11"><FormattedMessage id="SINGAPORE" defaultMessage="SINGAPORE"></FormattedMessage></a></li>
								  <li><a href="/order-form/60"><FormattedMessage id="BRITISH VIRGIN ISLANDS" defaultMessage="BRITISH VIRGIN ISLANDS"></FormattedMessage></a></li>
								  <li><a href="/order-form/58"><FormattedMessage id="CAYMAN" defaultMessage="CAYMAN"></FormattedMessage></a></li>
								  <li><a href="/order-form/59"><FormattedMessage id="SEYCHELLES" defaultMessage="SEYCHELLES"></FormattedMessage></a></li>

								</ul>
							</div>
						</div>
						<div className="row mt-5 solutions-row align-items-center" id="global">
							<div className="col-md-5 image-column d-flex align-items-center"> 
								<img src="/images/structring.png" alt="structring" />
							</div>
							<div className="col-md-7 pl-md-5">
							<h4 className="mb-3 our_solutions_title"><FormattedMessage id="GLOBAL STRUCTURING" defaultMessage="GLOBAL STRUCTURING"></FormattedMessage></h4>
								
								<p>
								<FormattedMessage id="our_solutions.global_structuring_contents" defaultMessage='Considering the complex range of factors including tax, legal, accounting and regulatory issues, we work with our global legal partners to help clients to identify the principal concerns that will determine the right structure for a transaction. We provide one-stop service and support for some global mainstream "structures"'></FormattedMessage>
	
								</p>
								<ul className="custom-list" style={this.props.currentLang == 'en'? {} : {fontFamily:'华文细黑'} }>
									<li><a href="/common-business"><FormattedMessage id="COMMON BUSINESS STRUCTURES" defaultMessage="COMMON BUSINESS STRUCTURES"></FormattedMessage></a></li>
								  <li><a href="/order-form/67"><FormattedMessage id="SINGAPORE VARIABLE CAPITAL COMPANIES" defaultMessage="SINGAPORE VARIABLE CAPITAL COMPANIES"></FormattedMessage></a></li>
								  <li><a href="48"><FormattedMessage id="CAYMAN FUND" defaultMessage="CAYMAN FUND"></FormattedMessage></a></li>
								  <li><a href="#"><FormattedMessage id="SINGAPORE FUND" defaultMessage="SINGAPORE FUND"></FormattedMessage></a></li>

								</ul>
							</div>
						</div>
						<div className="row mt-5 solutions-row align-items-center" id="tax">
							<div className="col-md-5 image-column d-flex align-items-center"> 
								<img src="/images/tax.png" alt="tax" />
							</div>
							<div className="col-md-7 pl-md-5">
								<h4 className="mb-3 our_solutions_title"><FormattedMessage id="TAX AND ACCOUNTING SERVICES" defaultMessage="TAX AND ACCOUNTING SERVICES"></FormattedMessage></h4>
								<p>
								<FormattedMessage id="our_solutions.tax_and_accounting_service_content" defaultMessage="Jenga offers a comprehensive range of tax and accounting services. Our professional, customer-focused staff act with integrity and honesty and carefully listen to understand your financial goals and develop a plan to attain them. All of the superior accounting assistance and tax advisory are served to you with no hidden charges, leaving you with peace of mind till the end."></FormattedMessage>

								</p>
								<ul className="custom-list" style={this.props.currentLang == 'en'? {} : {fontFamily:'华文细黑'} }>
									
							<li><a href="/order-form/55"><FormattedMessage id="BOOKKEEPING SERVICE" defaultMessage="BOOKKEEPING SERVICE"></FormattedMessage></a></li>
							<li><a href="/order-form/55"><FormattedMessage id="COMPILATION FINANCIAL STATEMENT" defaultMessage="COMPILATION FINANCIAL STATEMENT"></FormattedMessage></a></li>
							<li><a href="/order-form/24"><FormattedMessage id="CORPORATE TAX FILING" defaultMessage="CORPORATE TAX FILING"></FormattedMessage></a></li>
							<li><a href="/order-form/24"><FormattedMessage id="PERSONAL INCOME TAX" defaultMessage="PERSONAL INCOME TAX"></FormattedMessage></a></li>
									
								</ul>
							</div>
						</div>
						<div className="row mt-5 mb-0 solutions-row align-items-center" id="trademark">
							<div className="col-md-5 image-column d-flex align-items-center"> 
								<img src="/images/ip.png" alt="IP REGISTRATION" />
							</div>
							<div className="col-md-7 pl-md-5">
								<h4 className="mb-3 our_solutions_title"><FormattedMessage id="TRADEMARK REGISTRATION" defaultMessage="TRADEMARK REGISTRATION"></FormattedMessage></h4>
								<p>
								<FormattedMessage id="our_solutions.ip_registration_content" defaultMessage="Trademarks are signs used by a business to distinguish their goods or services from those ofother players in the market. Commonly-used symbols to identify a trademark are ® and TM. In Singapore, intellectual property acquisition and registration are eligible for PIC benefits.  We can register your trademark in Singapore on your behalf."></FormattedMessage>


								</p>
								<ul className="custom-list" style={this.props.currentLang == 'en'? {} : {fontFamily:'华文细黑'} }>
						<li><a href="/order-form/45"><FormattedMessage id="SINGAPORE TRADEMARK" defaultMessage="SINGAPORE TRADEMARK"></FormattedMessage></a></li>
						<li><a href="/order-form/43"><FormattedMessage id="INTERNATIONAL TRADEMARK" defaultMessage="INTERNATIONAL TRADEMARK"></FormattedMessage></a></li>
						<li><a href="/order-form/44"><FormattedMessage id="TRADEMARK MANAGEMENT AND DISPUTE" defaultMessage="TRADEMARK MANAGEMENT AND DISPUTE"></FormattedMessage></a></li>								
								</ul>
							</div>
						</div>
					</div>
				  </div>
				</section>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(OurSolutions)