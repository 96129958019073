import React, { Component } from 'react'

import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

class OrderDetails extends Component {

	constructor(props) {

		super(props)

		this.assertTitle = this.assertTitle.bind(this)

	}

	assertTitle(currentItem) {

		return this.props.currentLang == 'en' ? currentItem.title : currentItem.chinese_title

	}

	render() {

		const orderItems = this.props.orderItems.map((orderItem,index) => {
			return (
				<li key={index}>
					<span>{this.assertTitle(orderItem)} { orderItem.itemType == 'required' ? '(required)' : '' }</span><span>${orderItem.price} x {orderItem.quantity} </span>
				</li>
			);
		});

		return (
			<div class="box-gray ml-md-4">
				{/* <h2 class="checkout-title">Singapore Private Limited Company</h2> */}
				<h2 class="checkout-title">{this.assertTitle(this.props.orderForm)}</h2>
				<ul class="order-list item_list detailsorder">
					<li>
						<span><FormattedMessage id="Item" defaultMessage="Item"></FormattedMessage></span><span>USD</span>
					</li>
					{orderItems}
					{
						this.props.couponData && this.props.couponData.couponCode ? 
						(
							<li>
								<span>Discount Coupon ({ this.props.couponData.couponCode }) </span>
								<span>
									{
										( this.props.couponData.discountType == 'Percentage' ) ?
											'-'+this.props.couponData.discountValue+'%'
											: '-$'+this.props.couponData.discountValue
									}
								</span>
							</li>
						) : ''
					}
					{/* <li>
						<span>GST 7%</span><span>${Math.floor(this.props.subTotal*0.07*100)/100}</span>
					</li>
					<li><span>{this.assertTitle(this.props.orderForm)} </span><span>Total: ${this.props.subTotal + (Math.floor(this.props.subTotal*0.07*100)/100)}</span></li> */}
				</ul>
				<div class="row justify-content-end">
					<div class="col-md-9">
						<ul class="order-list item_list orderitemstotal" style={{textAlign: "right"}}>
							<li><span><FormattedMessage id="Subtotal (include discount of 0)" defaultMessage="Subtotal (include discount of 0)"></FormattedMessage></span><span>${this.props.subTotal}</span></li>
							{
								this.props.orderForm.formId === 54 || this.props.orderForm.formId === 53 ||this.props.orderForm.formId === 52 ||this.props.orderForm.formId === 62 ? (
									<li><b style={{color: 'rgb(190, 117, 115)', width: '100'+'%'}}><FormattedMessage id="Total USD" defaultMessage="Total USD"></FormattedMessage> : ${this.props.subTotal}</b></li>
								) :(
									<>
										<li><span><FormattedMessage id="GST 9%" defaultMessage="GST 9%"></FormattedMessage></span><span>${Math.floor(this.props.subTotal*0.09*100)/100}</span></li>
										<li><b style={{color: 'rgb(190, 117, 115)', width: '100'+'%'}}><FormattedMessage id="Total USD" defaultMessage="Total USD"></FormattedMessage> : ${this.props.subTotal + (Math.floor(this.props.subTotal*0.09*100)/100)}</b></li>
									</>
								)
							}
							{/* <li><span><FormattedMessage id="GST 7%" defaultMessage="GST 7%"></FormattedMessage></span><span>${Math.floor(this.props.subTotal*0.07*100)/100}</span></li>
							<li><b style={{color: 'rgb(190, 117, 115)', width: '100'+'%'}}><FormattedMessage id="Total USD" defaultMessage="Total USD"></FormattedMessage> : ${this.props.subTotal + (Math.floor(this.props.subTotal*0.07*100)/100)}</b></li> */}
							</ul>
							</div>
							</div>
			
			
			
			</div>
		)

	}

}

const mapStateToProps = (state) => {
	return {
		orderForm: state.OrderForm.orderForm,
		orderItems: state.OrderCart.orderItems,
		subTotal: state.OrderCart.subTotal,
		couponData: state.OrderCart.couponData,
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails)