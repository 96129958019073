import BaseService from './BaseService'

export default class OrderFormService extends BaseService {

	fetchOrderForm(form_id) {
		return this.apiCall(
			`order_form/${form_id}?_format=json`,
			'GET'
		).then((response) => {
			return response.data
		})
	}

	checkCoupon(couponCode) {
		return this.apiCall(
			`order/checkCoupon`,
			'POST',
			{
				couponCode
			},
			{ withCredentials: true }
		).then((response) => {
			return response.data
		})
	}

}