import React, { Component } from 'react'
import { Redirect } from 'react-router'

import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import Header from '../partials/Header'
import BreadCrumbs from '../partials/BreadCrumbs'
import HeadBanner from '../partials/HeadBanner'
import ContactUsService from '../services/ContactUsService'
import Footer from '../partials/Footer'

import BillingDetails from '../components/BillingDetails'
import OrderDetails from '../components/OrderDetails'
import CheckoutPaymentMethod from '../components/CheckoutPaymentMethod'


//import { addOrderItem, removeOrderItem } from '../actions/OrderCartActions'

class Contact extends Component {

	constructor(props) {

		super(props)
		this.ContactUsService = new ContactUsService();
		this.state = {
			contactData: {
				email: '',
				content: ''
			}
		}
		this.onEmailChange = this.onEmailChange.bind(this)
	}

	componentWillMount() {

		const self = this;

	}
	onEmailChange=(email)=>{
		return(e)=>{
			let newTestData=Object.assign(this.state.contactData,{[email]:e.target.value}); 
			this.setState({
				contactData: newTestData
			})
		}
		
	}
	onContentChange=(content)=>{
		return(e)=>{
			let newTestData=Object.assign(this.state.contactData,{[content]:e.target.value}); 
			this.setState({
				contactData: newTestData
			})
		}
		
	}
	sendEmail(data){
		const self = this;
		if(data.email==""||data.content==""){
			let message = this.props.currentLang == 'en' ? 'Please provide complete information' : '请填写完整信息'
			alert(message)
			return
		}else{
			let message = this.props.currentLang == 'en' ? 'success' : '发送成功'
		console.log("contact Us 发送",data)
		
		self.ContactUsService.sendEmailSections(data).then((response) => {
				// if (response.length) {
				// 	this.setState({
				// 		newsList: response
				// 	})
				// }

				self.setState({
						contactData: {
							email: '',
							content: ''
						}
					})
				console.log('发送成功',response);
				alert(message)
			})
		}
	}

	render() {

		//{ !this.state.isCheckoutAllowed ? ( <Redirect to="/" push /> ) : "" }

		return (
			<div>
				<Header />
				<HeadBanner title="Contact Us" />
				<BreadCrumbs pages={[
					{
						title: "Home",
						link: "/"
					},
					{
						title: "Contact Us"
					}
				]} />
				<section className="contact bg-white gray gray-shape">
					<div className="container small-container mb-md-5">
						<div className="row">
							<div className="col-md-12 pt-4">
								<div className="contact-wrap">
									<h4>
										<FormattedMessage id="jenga contact" defaultMessage="Jenga Corp is here to provide you with more information, answer any questions you may have and provide an effective solution for your business need."></FormattedMessage>
									</h4>
									{/* <h4 style={{marginBottom:0,lineHeight:28+'px'}}>
										<FormattedMessage id="jenga contact des" defaultMessage="Use the form below to drop us an e-mail. Contact:"></FormattedMessage>
										<a href="tel:+65 8889 5318"><FormattedMessage id="jenga contact number1" defaultMessage="+65 8889 5318"></FormattedMessage></a> / <a href="tel:+65 8889 8419"><FormattedMessage id="jenga contact number2" defaultMessage="8889 8419"></FormattedMessage></a> / <a href="tel:+65 8889 6998"><FormattedMessage id="jenga contact number3" defaultMessage="8889 6998"></FormattedMessage></a>
										<FormattedMessage id="Whatsapp" defaultMessage=". Whatsapp:"></FormattedMessage> 
										<a href="tel:+65 8882 4795"><FormattedMessage id="jenga contact number4" defaultMessage="+65 8882 4795 "></FormattedMessage></a>
										 <FormattedMessage id="or Wechat" defaultMessage="or Wechat:"></FormattedMessage> 
										 <a href="mailto:jengacorp"><FormattedMessage id="jenga" defaultMessage="jengacorp"></FormattedMessage></a> {this.props.currentLang == 'en' ? <span>can find us too!</span> : <span> <br />或者使用以下表格填写留言，我们会在第一时间联络您！</span>}
									</h4> */}

									<h4 style={{marginBottom:0,lineHeight:28+'px'}}>
										<FormattedMessage id="Customer Service Hotline:" defaultMessage="Customer Service Hotline:"></FormattedMessage> 
										<p><a href="tel:+65 8882 4795">+65 8882 4795</a></p>
									</h4>
									
									<h4 style={{marginBottom:0,lineHeight:28+'px'}}>
										<FormattedMessage id="Business Consulting:" defaultMessage="Business Consulting:"></FormattedMessage> 
										<p><a href="tel:+65 8920 6998" style={{marginRight:10+'px'}}>+65 8920 6998 (Miko)</a>   <a href="tel:+65 8509 8186" style={{marginRight:10+'px'}}>+65 8509 8186 (Rona) </a> <a href="tel:+65 8509 5388">+65 8509 5388 (Nikki)</a> 
										<br />
										<a href="tel:+65 8831 6652" style={{marginRight:10+'px'}}>+65 8831 6652 (Stanley)</a> <a href="tel:+65 8889 8419">+65 8889 8419 (Stefan)</a>
										</p>
									</h4>
									
									<h4 style={{marginBottom:0,lineHeight:28+'px'}}>
										<p>
										<FormattedMessage id="Whatsapp1" defaultMessage="Whatsapp:"></FormattedMessage> 
										<a href="tel:+65 8882 4795"><FormattedMessage id="jenga contact number4" defaultMessage="+65 8882 4795 "></FormattedMessage></a>
										 <FormattedMessage id="or Wechat1" defaultMessage="or Wechat:"></FormattedMessage> 
										 <a href="mailto:JengaSolutions"><FormattedMessage id="jenga" defaultMessage="JengaSolutions"></FormattedMessage></a> 
										 {this.props.currentLang == 'en' ? <span> can find us too!</span> :''}
										 </p>
									</h4>

									<h4 style={{marginBottom:0,lineHeight:28+'px'}}>
										<FormattedMessage id="Use the form below to drop us an e-mail." defaultMessage="Use the form below to drop us an e-mail. "></FormattedMessage>
									</h4>

									<form className="contactform pt-md-5">
										<div className="row align-items-end">
											<div className="col-md-9">
												<label style={{fontSize:16+'px'}}><FormattedMessage id="Email" defaultMessage="Email"></FormattedMessage></label>
												<input className="form-control" type="email" value={this.state.contactData.email} onChange={this.onEmailChange('email')} placeholder={this.props.currentLang == 'en' ? 'Enter your email' : ''} />
												<label style={{fontSize:16+'px'}}><FormattedMessage id="Message" defaultMessage="Message"></FormattedMessage></label>
												<textarea className="form-control" value={this.state.contactData.content} onChange={this.onContentChange('content')} placeholder={this.props.currentLang == 'en' ? 'Enter your message' : ''}></textarea>
												{/* <input type="submit" value={this.props.currentLang == 'en' ? 'Submit' : '提交'} onClick={this.sendEmail.bind(this, this.state.contactData)}/> */}
												<div className='form-submit' onClick={this.sendEmail.bind(this, this.state.contactData)}>
													<FormattedMessage id="Submit" defaultMessage="Submit"></FormattedMessage>
												</div>
											</div>
											<div className="col-md-3 sm-hide text-right"><img src="/images/contact-image.png" alt="" /></div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		orderForm: state.OrderForm.orderForm,
		cartItems: state.OrderCart.orderItems,
		subTotal: state.OrderCart.subTotal,
		coupon: state.OrderCart.coupon,
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)