import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

export default class CheckoutBanner extends Component {
	render() {
		return (
			<div class="banner-row">
				<div class="container">
					<div class="row">
						<div class="col-md-9">
							<div class="incorp">
								<h1 class="large-heading"><FormattedMessage id="Checkout " defaultMessage="Checkout "></FormattedMessage></h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}