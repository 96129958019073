import { FETCH_ORDER_FORM } from '../actions/OrderFormActions'
import { LOAD_CART } from '../actions/OrderCartActions'

import merge from 'merge'

const initialState = {
	orderForm: {}
};

let orderForm;

export default function(state = initialState, action) {
	switch (action.type) {

		case LOAD_CART:

			orderForm = state.orderForm;

			orderForm.formId = action.payload.order_form;

			return {...state, orderForm}

		break;

		case FETCH_ORDER_FORM:

			orderForm = {
				formId: 0,
				title: "",
				description: "",
				required: [],
				addon: [],
				recommended: [],
				maintenance: [],
				sidebar_menu: "",
				isFree: false
			};

			const formData = action.payload[0]

			orderForm.formId = formData.nid
			orderForm.title = formData.title
			orderForm.chinese_title = formData.field_chinese_title
			orderForm.type = formData.field_sub_title_cn
			orderForm.description = formData.body
			orderForm.chinese_description = formData.field_chinese_description
			orderForm.layout = formData.field_form_layout
			orderForm.sidebar_menu = formData.field_sidebar_menu
			orderForm.changeRequiredTitle = formData.field_change_required_title
			orderForm.customRequiredTitle = formData.field_custom_required_column_nam
			orderForm.cn_customRequiredTitle = formData.field_cn_custom_required
			
			formData.view.forEach((v) => {
				
				const formItem = {}

				const variantId = v.view_1[0].variation_id
				const priceWithCurrencyCode = v.view_1[0].price__currency_code
				const currencyCode = priceWithCurrencyCode.substr(0,3)
				//const price = parseFloat(priceWithCurrencyCode.substr(3))

				formItem.title = v.view_1[0].title
				formItem.chinese_title = v.view_1[0].field_chinese_title
				formItem.title_link = v.view_1[0].field_title_link
				formItem.cn_sub_title = v.view_1[0].field_cn_sub_title;
				formItem.en_sub_title = v.view_1[0].field_en_sub_title;
				formItem.price = parseFloat( v.view_1[0].price__number.toString().replace(/,/g, '') )
				formItem.pay_after = v.view_1[0].field_pay_after
				formItem.price_prefix = v.view_1[0].field_price_prefix
				formItem.description = v.body
				formItem.chinese_description = v.field_chinese_description
				formItem.currency = currencyCode
				formItem.product_id = v.product_id
				formItem.variation_id = variantId
				formItem.itemType = ''
				formItem.payment_type = v.field_payment_type
				formItem.isFree = v.field_is_free == "1" ? true : false
				formItem.checkboxHidden = v.field_hidden_checkbox == "1" ? true : false
				formItem.makeSelected = v.field_make_selected == "1" ? true : false
				formItem.itemClass = v.field_item_class
				formItem.requiredAfter = v.field_required_after == "1" ? true : false
				formItem.child = []

				v.view.forEach((v2) => {

					const formItem2 = {};

					const variantId2 = v2.view[0].variation_id;
					const priceWithCurrencyCode2 = v2.view[0].price__currency_code;
					formItem2.title = v2.view[0].title;
					formItem2.chinese_title = v2.view[0].field_chinese_title;
					const currencyCode2 = priceWithCurrencyCode2.substr(0,3)
					const price2 = parseFloat(priceWithCurrencyCode2.substr(3))

					formItem2.product_id = v2.product_id;
					formItem2.variation_id = variantId2;
					formItem2.title = v2.title;
					formItem2.itemType = ''
					formItem2.price = parseFloat( v2.view[0].price__number.toString().replace(/,/g, '') );
					formItem2.pay_after = v2.view[0].field_pay_after
					formItem2.description = v2.body
					formItem2.chinese_description = v2.field_chinese_description
					formItem2.currency = currencyCode2;
					formItem2.payment_type = v2.field_payment_type;
					formItem2.title_prefix = v2.field_child_prefix;
					formItem2.chinese_title_prefix = v2.field_chinese_child_prefix;
					formItem2.title_prefix_required = v2.field_title_prefix_required;
					formItem2.cn_child_prefix_type = v2.field_cn_child_prefix_type;
					formItem2.isFree = v2.field_is_free == "1" ? true : false
					formItem2.makeSelected = v2.field_make_selected == "1" ? true : false
					formItem2.checkboxHidden = v2.field_hidden_checkbox == "1" ? true : false
					formItem2.requiredAfter = v2.field_required_after == "1" ? true : false
					formItem2.itemClass = v2.field_item_class

					formItem.child.push(formItem2);

				})

				if( v.field_item_type == "Required" )
					orderForm.required.push(formItem)
				else if( v.field_item_type == "Addon" )
					orderForm.addon.push(formItem)
				else if( v.field_item_type == "Recommended" )
					orderForm.recommended.push(formItem)
				else if( v.field_item_type == "Maintenance" )
					orderForm.maintenance.push(formItem)

			})

			return {
				...state,
				formData: action.payload,
				orderForm
			};

		break;

		default:
			return state;
			
	}
}
