import React, { Component } from 'react'
import { connect }  from 'react-redux'

class AcknowledgmentSubEntry extends Component {

	render() {
		const url = this.props.currentLang == 'en'?this.props.subEntry.field_en_entry_document:this.props.subEntry.field_entry_document
		return (
			<li>
				<a href={"//api.jengacorp.com"+url} target="_blank">
					{this.props.currentLang == 'en'?this.props.subEntry.title:this.props.subEntry.field_title_cn_entity}
				</a>
			</li>
		);
	}

}

// export default AcknowledgmentSubEntry
const mapStateToProps = (state) => {
	return {
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(AcknowledgmentSubEntry)