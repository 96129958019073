export const ADD_CART_ITEM = 'ADD_CART_ITEM'
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
export const APPLY_COUPON = 'APPLY_COUPON'
export const LOAD_CART = 'LOAD_CART';
export const EMPTY_CART = 'EMPTY_CART';

export const CHANGE_QUANTITY = 'CHANGE_QUANTITY';

export const addOrderItem = ({ orderItem, parentItem }) => {
	
	return {
		type: ADD_CART_ITEM,
		payload: {
			orderItem,
			parentItem
		}
	}

}

export const removeOrderItem = ({ orderItem, parentItem }) => {
	
	return {
		type: REMOVE_CART_ITEM,
		payload: {
			orderItem,
			parentItem
		}
	}

}

export const changeQuantity = ({ orderItem, quantity }) => {

	return {
		type: CHANGE_QUANTITY,
		payload: {
			orderItem,
			quantity
		}
	}

}

export const emptyCart = () => {

	return {
		type: EMPTY_CART,
		payload: {}
	}

}

export const loadCart = (cartData) => {
	return {
		type: LOAD_CART,
		payload: cartData
	};
}

export const applyCoupon = (couponData) => {
	return {
		type: APPLY_COUPON,
		payload: couponData
	}
}