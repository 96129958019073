import React, { Component } from 'react'

import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import OrderCartService from '../services/OrderCartService';

import { setCheckoutError, clearCheckoutError, updateCheckout } from '../actions/OrderCheckoutActions';

import DefaultENV from '../env/default'

class CheckoutPaymentMethod extends Component {

	constructor(props) {

		super(props)
		this.orderCartService = new OrderCartService();
		this.state = {
			paymentForm: '',
			stripeSessionId: ''
		};

		this.handlePlaceOrder = this.handlePlaceOrder.bind(this);
		this.handlePaymentMethod = this.handlePaymentMethod.bind(this);

	}

	validateState() {

		const checkoutData = this.props.checkoutData;

		if (checkoutData.requestedCompanyName === '') {
			this.props.setCheckoutError('Please enter company name.')
			return false;
		}

		if (checkoutData.emailAddress === '' || !checkoutData.emailAddress.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
			this.props.setCheckoutError('Please enter a valid email address.')
			return false;
		}

		if (checkoutData.paymentMethod === '') {
			this.props.setCheckoutError('Please select a payment method.')
			return false;
		}

		return true;

	}

	handlePaymentMethod(e) {

		this.props.updateCheckout({
			paymentMethod: e.target.value
		})

	}

	handlePlaceOrder() {

		const self = this;

		this.props.clearCheckoutError();

		if (!this.validateState())
			return;

		const payload = {
			checkoutData: this.props.checkoutData,
			cartData: {
				orderItems: this.props.orderItems,
				subTotal: this.props.subTotal
			},
			couponData: this.props.couponData,
			coupon: this.props.coupon,
			formId: this.props.orderForm.formId,
			currentLang: this.props.currentLang
		}

		this.orderCartService.getCheckoutForm(payload).then((response) => {
			// console.log(response)

			if (self.props.checkoutData.paymentMethod === 'credit-card') {

				self.setState({
					stripeSessionId: response.stripeSessionId
				}, () => {

					if (response.hasOwnProperty('error') && response.error == true)
						return;

					const stripe = global.Stripe(DefaultENV.STRIPE_PUBLIC_KEY);
					stripe.redirectToCheckout({
						sessionId: this.state.stripeSessionId
					}).then(function (result) {
						console.log("Stripe Result", result);
					});

				})

			} else if (self.props.checkoutData.paymentMethod === 'alipay') {

				if (response.hasOwnProperty('error') && response.error === true)
					return;

				const stripe = global.Stripe(DefaultENV.STRIPE_PUBLIC_KEY);
				stripe.createSource({
					type: 'alipay',
					amount: response.subTotal,
					currency: 'sgd',
					redirect: {
						return_url: 'http://jengacorp.com/order/thankyou/success/' + response.order_id,
					}
				}).then(function (result) {

					if (!result.hasOwnProperty("source"))
						return;

					if (result.source.redirect.hasOwnProperty("url"))
						window.location = result.source.redirect.url;
					else
						console.log(result, "response with error");

					//console.log(result, "Error Alipay")
				});

			} else if (self.props.checkoutData.paymentMethod === 'bankwire') {
				window.location = 'https://jengacorp.com/order/thankyou/bankwire'
			} else {

				self.setState({
					paymentForm: response.paymentForm
				}, () => {
					// console.log(document.fomopay)
					document.fomopay.submit();
				})

			}

		})

	}

	render() {

		return (
			<div>
				<p className="dark-gray text16 mt-4"><FormattedMessage id="preferred" defaultMessage="Please choose you preferred payment channel and place order thereafter"></FormattedMessage></p>
				<div class="payment-method">
					<label class="d-block payemnt-row"><input type="radio" name="method" onChange={this.handlePaymentMethod} value={"credit-card"} /> <FormattedMessage id="Pay securely with any major credit or debit card" defaultMessage="Pay securely with any major credit or debit card"></FormattedMessage> <img src="/images/cards.png" alt="cards" /></label>
					{/* <label class="d-block payemnt-row"><input type="radio" name="method" onChange={this.handlePaymentMethod} value={"alipay"} /> <FormattedMessage id="Alipay" defaultMessage="Alipay"></FormattedMessage> <img src="/images/alipay.png" alt="Alipay" /></label> */}
					<label class="d-block payemnt-row"><input type="radio" name="method" onChange={this.handlePaymentMethod} value={"bankwire"} /> <FormattedMessage id="bankwiree" defaultMessage="Bank Wire (recomended to use your business or personal internet bank)"></FormattedMessage> <img src="/images/bankwire.png" alt="Bankwire" /></label>
					<div class="verification">
						<p><FormattedMessage id="Verification Content" defaultMessage="Your USD order will be converted using mid-market rates into the digital currency of your choice and will be redirected to a ConinPayments.net checkout page that will allow you to use any wallet to make payment within 30 minutes. A confirm email of your order will be sent to you as soon as  the transaction has been verified."></FormattedMessage>
						</p>
					</div>
					<hr />
					<div class="text-right">
						<button class="btn-submit" onClick={this.handlePlaceOrder}><FormattedMessage id="PLACE ORDER" defaultMessage="PLACE ORDER"></FormattedMessage></button>
					</div>
					<span dangerouslySetInnerHTML={{ __html: this.state.paymentForm }}></span>
				</div>
			</div>
		)

	}

}

const mapStateToProps = (state) => {
	return {
		orderForm: state.OrderForm.orderForm,
		orderItems: state.OrderCart.orderItems,
		coupon: state.OrderCart.coupon,
		couponData: state.OrderCart.couponData,
		subTotal: state.OrderCart.subTotal,
		currentLang: state.locales.currentLang,
		checkoutData: state.OrderCheckout
	}
}

const mapDispatchToProps = {
	setCheckoutError: (checkoutError) => (dispatch) => {
		dispatch(setCheckoutError(checkoutError))
	},
	clearCheckoutError: () => (dispatch) => {
		dispatch(clearCheckoutError())
	},
	updateCheckout: (checkoutData) => (dispatch) => {
		dispatch(updateCheckout(checkoutData))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentMethod)