import { UPDATE_CHECKOUT, SET_CHECKOUT_ERROR, SET_COMPANY_NAME } from '../actions/OrderCheckoutActions'
import merge from 'merge'

const initialState = {
	clientName: '',
	requestedCompanyName: '',
	emailAddress: '',
	createAnAccount: false,
	orderNotes: '',
	paymentMethod: '',
	checkoutError: ''
};

export default function(state = initialState, action) {
	switch (action.type) {

		case SET_COMPANY_NAME:

			state.requestedCompanyName = action.payload;

			return {...state}

		break;

		case SET_CHECKOUT_ERROR:

			state.checkoutError = action.payload

			return {...state}

		break;
		case UPDATE_CHECKOUT:

			if( action.payload.hasOwnProperty('createAnAccount') ) {

				state.createAnAccount = action.payload.createAnAccount;
				state.clientName = action.payload.clientName;
				state.requestedCompanyName = action.payload.requestedCompanyName;
				state.emailAddress = action.payload.emailAddress;
				state.orderNotes = action.payload.orderNotes;

			} else if( action.payload.hasOwnProperty('paymentMethod') ) {

				state.paymentMethod = action.payload.paymentMethod;

			}

			return {...state};

		break;

		default:
			return state;

	}
}
