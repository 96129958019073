import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import Moment from 'react-moment'
import NormalMessage from '../components/NormalMessage'
import AcraService from '../services/AcraService'
import { connect } from 'react-redux'


class HomeInCorp extends Component {

	constructor(props) {

		super(props)

		this.state = {
			companyNameCheckSuccess: false,
			companyNameCheckMessage: '',
			companyNameCheckProcessing: false,
			companyProposedName: '',
			companyExistenceName: '',
			companyNameExistenceSuccess: false,
			companyNameExistenceMessage: '',
			companyNameExistenceProcessing: false,
			companyNameExistenceInfo: {},
			companyNameCheck: '',
			bizFileName: '',
			bizFileProcessing: false,
			bizFileMessage: '',
			bizFileSuccess: false
		}

		this.acraService = new AcraService();

		this.handleInputChange = this.handleInputChange.bind(this)
		this.checkAvailability = this.checkAvailability.bind(this)
		this.handleCheckAvailability = this.handleCheckAvailability.bind(this)
		this.checkExistence = this.checkExistence.bind(this)
		this.saveBizfile = this.saveBizfile.bind(this)

	}

	handleInputChange(e) {

		if( e.target.name == 'companyProposedName' ) {
			this.setState({
				companyNameCheckMessage: '',
				companyNameCheckSuccess: false
			})
		} else if( e.target.name == 'companyExistenceName' ) {
			this.setState({
				companyNameExistenceMessage: '',
				companyNameExistenceSuccess: false,
				companyNameExistenceInfo: {}
			})
		}

		this.setState({
			[e.target.name]: e.target.value
		})
	}

	checkAvailability() {

		const self = this

		if( self.state.companyProposedName.trim().length === 0 )
			return false;

		this.setState({
			companyNameCheckProcessing: true,
			companyNameCheckMessage: ''
		}, () => {
			self.acraService.checkNameAvailability(self.state.companyProposedName).then((response) => {
				if( response.exist === true ) {
					self.setState({
						companyNameCheckSuccess: false,
						companyNameCheckProcessing: false,
						companyNameCheckMessage: 'Unavailable',
						companyNameCheck: response.companyName
					})
				} else {
					self.setState({
						companyNameCheckSuccess: true,
						companyNameCheckProcessing: false,
						companyNameCheckMessage: 'Available',
						companyNameCheck: ''
					})
				}
			})
		})

	}

	checkExistence() {

		const self = this

		if( self.state.companyExistenceName.trim().length === 0 )
			return false;

		this.setState({
			companyNameExistenceProcessing: true,
			companyNameExistenceMessage: '',
			companyNameExistenceInfo: {}
		}, () => {
			self.acraService.checkNameExistence(self.state.companyExistenceName).then((response) => {
				if( response.exist === true ) {
					self.setState({
						companyNameExistenceSuccess: true,
						companyNameExistenceProcessing: false,
						companyNameExistenceMessage: 'Records Found',
						companyNameExistenceInfo: response.companyInfo
					})
				} else {
					self.setState({
						companyNameExistenceSuccess: false,
						companyNameExistenceProcessing: false,
						companyNameExistenceMessage: 'No Record Found'
					})
				}
			})
		})

	}

	handleCheckAvailability(event) {
		
		event.preventDefault();
		this.checkAvailability();

	}

	saveBizfile() {

		const self = this

		if( self.state.bizFileName.trim().length === 0 )
			return false;

		this.setState({
			bizFileProcessing: true,
			bizFileMessage: ''
		}, () => {
			self.acraService.setBizfileName(self.state.bizFileName).then((response) => {
				self.setState({
					bizFileSuccess: true,
					bizFileProcessing: false,
					bizFileMessage: ''
				})
				window.location = '/order-form/1';
			})
		})

	}

	render() {

		const checkAvailabilityProps = {
			disabled: this.state.companyNameCheckProcessing
		};

		const checkExistenceProps = {
			disabled: this.state.companyNameExistenceProcessing
		};

		//{ this.state.companyNameCheckMessage ? <NormalMessage success={this.state.companyNameCheckSuccess} message={this.state.companyNameCheckMessage} /> : '' }

		/*
		<div className="message">
			<span className="red-color">
				The proposed name is identical to the following names: &nbsp;
			</span>
			<span className="dgrey-color text-uppercase">
				{this.state.companyNameCheck}
			</span>
		</div>
		*/

		return (
			<div className="incorpor">
				<div className="small-container">
					<div className="tabs-row">
						<div className="entit">
							<h2>
								<FormattedMessage id="Online Incorporation of Global Entities" defaultMessage="Online Incorporation of Global Entities"></FormattedMessage>
							</h2>
							<p style={this.props.currentLang == 'en'? {fontFamily:'华文细黑'} : {} }>
								<FormattedMessage id="Digitalized Corporate Secretary Solutions" defaultMessage="Digitalized Corporate Secretary Solutions"></FormattedMessage>
							</p>
						</div>
						<div className="shore-tabs">
							<ul className="nav nav-tabs responsive " role="tablist">
								<li className="nav-item">
									<a className="nav-link active" data-toggle="tab" href="#singapore" role="tab">
										<FormattedMessage id="Singapore" defaultMessage="Singapore"></FormattedMessage>
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" data-toggle="tab" href="#offshore" role="tab">
										<FormattedMessage id="Offshore" defaultMessage="Offshore"></FormattedMessage>
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link" data-toggle="tab" href="#more" role="tab">
										<FormattedMessage id="More Options" defaultMessage="More Options"></FormattedMessage>
									</a>
								</li>
							</ul>
							<div className="tab-content responsive">
								<div className="tab-pane active" id="singapore" role="tabpanel">
									<div className="filing">
										<h3>
											<FormattedMessage id="Singapore Entity Incorporation" defaultMessage="Singapore Entity Incorporation"></FormattedMessage>
										</h3>
										<p>
											<FormattedMessage id="home.singapore_entity_certificate" defaultMessage="As a certified filing agent (FA) authorised by the Accounting and Corporate Regulatory Authority (ACRA), Jenga Corp is your trusted partner for incorporation of Singapore companies and registration of businesses."></FormattedMessage>
											<br />
											<FormattedMessage id="To check our certificates, please click" defaultMessage="To check our certificates, click here"></FormattedMessage> <a target="_blank" href="/documents/Jenga_Human_Resources_Pte.pdf"><FormattedMessage id="here!" defaultMessage="here!"></FormattedMessage></a> 
										</p>{/* <a target="_blank" href="/documents/Certificate_of_RFA_Jenga_Corp_Pte_Ltd.pdf"> */}
									</div>
									<div className="check-dta">
										<div className="row">
											<div className="col-md-4 pr-md-2" style={{paddingLeft:8+'px'}}>
												<div className="pricing-colam">
													<div className="price-head">
														<h4>
															<FormattedMessage id="Private Limited Company" defaultMessage="Private Limited Company"></FormattedMessage>
														</h4>
														<span><FormattedMessage id="From US" defaultMessage="From US"></FormattedMessage>$450 <FormattedMessage id="qi" defaultMessage=""></FormattedMessage></span> 
													</div>
													<div className="pricing-inner">
														<div className="price-tham"><img src="images/company-picture.jpg" alt="#" /> </div>
														<ul className="audit">
															<li>
																<FormattedMessage id="17% corporate tax based on chargeable income" defaultMessage="17% corporate tax based on chargeable income"></FormattedMessage>
															</li>
															<li>
																<FormattedMessage id="Minimum paid up capital of S$1" defaultMessage="Minimum paid up capital of S$1"></FormattedMessage>
															</li>
															<li>
																<FormattedMessage id="Maximum of 50 shareholders" defaultMessage="Maximum of 50 shareholders"></FormattedMessage>
															</li>
															<li>
																<FormattedMessage id="At least one local director*" defaultMessage="At least one local director*"></FormattedMessage>
															</li>
															<li>
																<FormattedMessage id="Able to open corporate bank account" defaultMessage="Able to open corporate bank account"></FormattedMessage>
															</li>
															<li>
																<FormattedMessage id="Small companies enjoy audit exemption" defaultMessage="Small companies enjoy audit exemption"></FormattedMessage>
															</li>
															<li>
																<FormattedMessage id="Able to apply for work pass (Learn more)" defaultMessage="Able to apply for work pass (Learn more)"></FormattedMessage>
															</li>
															<li>
																<FormattedMessage id="Commonly used for" defaultMessage="Commonly used for"></FormattedMessage> <br />
																1) <FormattedMessage id="Startups" defaultMessage="Startups"></FormattedMessage><br />
																2) <FormattedMessage id="Global or Regional headquarters" defaultMessage="Global or Regional headquarters"></FormattedMessage><br />
																3) <FormattedMessage id="Subsidiaries" defaultMessage="Subsidiaries"></FormattedMessage>
															</li>
														</ul>
														<div className="btn-by">
															<Link to={'/order-form/1'}>
																<FormattedMessage id="Start Now" defaultMessage="Start Now"></FormattedMessage>
															</Link>
														</div>
														<p className="mant"><FormattedMessage id="For annual maintenance, please click" defaultMessage="For annual maintenance, please click "></FormattedMessage> <a href="/order-form/11"><FormattedMessage id="here!" defaultMessage="here!"></FormattedMessage></a></p>
													</div>
												</div>
											</div>
											<div className="col-md-4 pr-md-2 pl-md-2">
												<div className="pricing-colam">
													<div className="price-head">
														<h4>
															<FormattedMessage id="Company Limited By Guarantee" defaultMessage="Company Limited By Guarantee"></FormattedMessage>
														</h4>
														<span><FormattedMessage id="From US" defaultMessage="From US"></FormattedMessage>$600 <FormattedMessage id="qi" defaultMessage=""></FormattedMessage></span> 
													</div>
													<div className="pricing-inner">
														<div className="price-tham"><img src="images/guarantee-picture.jpg" alt="#" /> </div>
														<ul className="audit">
															<li>
																<FormattedMessage id="17% corporate tax based on chargeable income" defaultMessage="17% corporate tax based on chargeable income"></FormattedMessage>
															</li>
															<li>
																<FormattedMessage id="home.has_member_instead_content" defaultMessage="Has member Instead of shareholders. These members agree to pay a fixed sum in case the company is wound up At least one local director*"></FormattedMessage>
															</li>
															<li>
																<FormattedMessage id="At least one local director*" defaultMessage="At least one local director*"></FormattedMessage>
															</li>
															<li>
																<FormattedMessage id="Annual audit is required" defaultMessage="Annual audit is required"></FormattedMessage>
															</li>
															<li>
																<FormattedMessage id="Commonly used for" defaultMessage="Commonly used for"></FormattedMessage> <br />
																1) <FormattedMessage id="Non-profit making activities such us promoting arts" defaultMessage="Non-profit making activities such us promoting arts"></FormattedMessage>, <br />
																2) <FormattedMessage id="Open source projects" defaultMessage="open source projects"></FormattedMessage>
																{/* 3) <FormattedMessage id="Token sale or ICO projects" defaultMessage="Token sale or ICO projects"></FormattedMessage><br />
																4) <FormattedMessage id="Public blockchain projects." defaultMessage="Public blockchain projects"></FormattedMessage> */}
															</li>
														</ul>
														<div className="btn-by"><a href="/order-form/5"><FormattedMessage id="Start Now" defaultMessage="Start Now"></FormattedMessage></a></div>
														<p className="mant"><FormattedMessage id="For annual maintenance, please click" defaultMessage="For annual maintenance, please click "></FormattedMessage> <a href="/order-form/23"><FormattedMessage id="here!" defaultMessage="here!"></FormattedMessage></a></p>
													</div>
												</div>
											</div>
											<div className="col-md-4 pr-md-2 pl-md-2">
												<div className="pricing-colam">
													<div className="price-head">
														<h4>
															<FormattedMessage id="Other Company Type" defaultMessage="Other Company Type"></FormattedMessage>
														</h4>
														<span><FormattedMessage id="From US" defaultMessage="From US"></FormattedMessage>$450 <FormattedMessage id="qi" defaultMessage=""></FormattedMessage></span> 
													</div>
													<div className="pricing-inner">
														<div className="price-tham"><img src="images/company-type-picture.jpg" alt="#" /> </div>
														<ul className="audit">
															<li><strong class="dark-gray1"><FormattedMessage id="Sole Proprietorship (SP):" defaultMessage="Sole Proprietorship (SP):"></FormattedMessage></strong>
															{/* <br /> */}
																<FormattedMessage id="Business entity with only one owner" defaultMessage="Business entity with only one owner"></FormattedMessage>
															</li>
															<li>
															<strong class="dark-gray1"><FormattedMessage id="Partnership:" defaultMessage="Partnership:"></FormattedMessage></strong><FormattedMessage id="General Partnership (GP)" defaultMessage=" General Partnership (GP), Limited Partnership (LP), Limited Liability Partnership (LLP). A LLP is primarily meant for carrying a profession (e.g. accountants, law firms, etc.)"></FormattedMessage>
															</li>
															<li>
															<strong class="dark-gray1"><FormattedMessage id="Foreign company setup in SG:" defaultMessage="Foreign company setup in SG:"></FormattedMessage></strong><FormattedMessage id="Foreign companies wishing" defaultMessage=" Foreign companies wishing to setup a presence in Singapore, have 3 options: branch office, subsidiary, or a representative office."></FormattedMessage>
															</li>
														</ul>
														<div className="btn-by"><a href="/order-form/61"><FormattedMessage id="Start Now" defaultMessage="Start Now"></FormattedMessage></a></div>
														{/* <p className="mant"><FormattedMessage id="For annual maintenance, please click" defaultMessage="For annual maintenance, please click "></FormattedMessage> <a href="#">here</a></p> */}
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* <div className="check-form">
										<form onSubmit={this.handleCheckAvailability}>
											<div className="row">
												<div className="col-md-9 pr-md-0">
													<label><FormattedMessage id="Company Name Check" defaultMessage="Company Name Check"></FormattedMessage></label>
													<div className="input-degf">
														<input className="form-control" type="text" placeholder={this.props.currentLang == 'en'?'Input Proposed Name':'输入拟用公司名称'} onChange={this.handleInputChange} name="companyProposedName" />
														
													</div>
													{ this.state.companyNameCheckProcessing ? ( <img className="loading-image" src="/images/spinner.gif" /> ) : '' }
													{ this.state.companyNameCheckMessage ? 
														(
															!this.state.companyNameCheckSuccess ? (
																<div className="normal-message error">
																	{this.state.companyNameCheckMessage}
																</div>
															) : (
																<div className="normal-message success">
																	{this.state.companyNameCheckMessage}
																</div>
															)
														) : ''
													}
												</div>
												<div className="col-md-3 pl-md-2">
													<div className="avai-btn">
														<button type="button" onClick={this.checkAvailability} className="btn btn-pink" {...checkAvailabilityProps}><FormattedMessage id="Check Availability" defaultMessage="Check Availability"></FormattedMessage></button>
													</div>
												</div>
											</div>
										</form>
										<form onSubmit={this.handleCheckAvailability}>
											<div className="row">
												<div className="col-md-9 pr-md-0">
													<label><FormattedMessage id="Check the Existence of Your Singapore Entity" defaultMessage="Check the Existence of Your Singapore Entity"></FormattedMessage></label>
													<div className="input-degf">
														<input className="form-control" type="text" placeholder={this.props.currentLang == 'en'?'Input the Name of Your Entity':'输入公司名称'} onChange={this.handleInputChange} name="companyExistenceName" />
														
													</div>
													{ this.state.companyNameExistenceMessage ? <NormalMessage success={this.state.companyNameExistenceSuccess} message={this.state.companyNameExistenceMessage} /> : '' }
													{ this.state.companyNameExistenceProcessing ? ( <img className="loading-image" src="/images/spinner.gif" /> ) : '' }
													{ Object.keys(this.state.companyNameExistenceInfo).length ? (
														<div class="table-responsive record">
															<table class="table company-existence">
																<thead>
																	<tr>
																		<th>
																			UEN
																		</th>
																		<th>
																			NAME
																		</th>
																		<th>
																			Next Agm Date
																		</th>
																		<th>
																			UEN Status
																		</th>
																	</tr>
																</thead>
																<tbody>
																	<tr>
																		<td>{this.state.companyNameExistenceInfo.uen}</td>
																		<td>{this.state.companyNameExistenceInfo.name}</td>
																		<td>
																			<Moment date={this.state.companyNameExistenceInfo.coAgmDue.nextAgmDueDateS175} format="YYYY/MM/DD" />
																		</td>
																		<td>{this.state.companyNameExistenceInfo.status.description}</td>
																	</tr>
																</tbody>
															</table>
														</div>
													) : '' }
												</div>
												<div className="col-md-3 pl-md-2">
													<div className="avai-btn">
														<button type="button" onClick={this.checkExistence} className="btn btn-pink" {...checkExistenceProps}><FormattedMessage id="Check Availability" defaultMessage="Check Availability"></FormattedMessage></button>
													</div>
												</div>
											</div>
										</form>
										<form onSubmit={this.handleCheckAvailability}>
											<div className="row">
												<div className="col-md-9 pr-md-0">
													<label><FormattedMessage id="Buy Bizfile of Existing Companies" defaultMessage="Buy Bizfile of Existing Companies"></FormattedMessage></label>
													<div className="input-degf">
														<input className="form-control" type="text" placeholder={this.props.currentLang == 'en'?'Input Company Name':'输入公司名称'} onChange={this.handleInputChange} name="bizFileName" />
														
													</div>
													{ this.state.bizFileMessage ? <NormalMessage success={this.state.bizFileSuccess} message={this.state.bizFileMessage} /> : '' }
													{ this.state.bizFileProcessing ? ( <img className="loading-image" src="/images/spinner.gif" /> ) : '' }
												</div>
												<div className="col-md-3 pl-md-2">
													<div className="avai-btn">
														<button type="button" className="btn btn-pink" onClick={this.saveBizfile}><FormattedMessage id="Get Started" defaultMessage="Get Started"></FormattedMessage></button>
													</div>
												</div>
											</div>
										</form>
										<form onSubmit={this.handleCheckAvailability}>
											<div className="row conp">
												<div className="col-md-4 pr-md-0">
													<label><FormattedMessage id="Buy Individual Ownership Profile" defaultMessage="Buy Individual Ownership Profile"></FormattedMessage></label>
													<div className="input-degf">
														<input className="form-control" type="text" placeholder={this.props.currentLang == 'en'?'Shareholder/Director Name':'股东/董事姓名'} />
														
													</div>
													<div className="normal-message success"></div>
												</div>
												<div className="col-md-4 pl-md-2 pr-md-0">
													<div className="input-degf">
														<input className="form-control" type="text" placeholder={this.props.currentLang == 'en'?'Passport No.':'护照号码'} />
														
													</div>
												</div>
												<div className="col-md-3 pl-md-2">
													<div className="avai-btn">
														<button type="submit" className="btn btn-pink"><FormattedMessage id="Get Started" defaultMessage="Get Started"></FormattedMessage></button>
													</div>
												</div>
											</div>
										</form>
									</div> */}
								</div>
								<div className="tab-pane" id="offshore" role="tabpanel">
									<div className="filing">
										<h3><FormattedMessage id="Offshore Global Entity Incorporation" defaultMessage="Offshore Global Entity Incorporation"></FormattedMessage></h3>
										<p><FormattedMessage id="We provide offshore entity" defaultMessage="We provide offshore entity incorporation in 3 mainstream jurisdictions including British Virgin Islands, Cayman Islands, and the Seychelles. Incorporate your offshore company for tax planning, protection of shareholders' / directors' privacy and operation of your global business."></FormattedMessage>
										</p>
									</div>
									<div className="check-dta offshore">
										<div className="row">
											<div className="col-md-4 pr-md-2">
												<div className="pricing-colam">
													<div className="price-head">
														<h4><FormattedMessage id="BVI Business Company" defaultMessage="BVI Business Company"></FormattedMessage>
														</h4>														
														<span><FormattedMessage id="US" defaultMessage="US"></FormattedMessage>$2,500</span> 
													</div>
													<div className="pricing-inner">
														<div className="price-tham"><img src="images/business-picture.jpg" alt="#" /> </div>
							                            <ul className="audit">
															<li> <FormattedMessage id="One of the most established offshore jurisdictions" defaultMessage="One of the most established offshore jurisdictions"></FormattedMessage> </li>
															<li> <FormattedMessage id="Recognized and familiar to financial institutions globally" defaultMessage="Recognized and familiar to financial institutions globally"></FormattedMessage> </li>
															
															<li> <FormattedMessage id="Under common law system" defaultMessage="Under common law system"></FormattedMessage> </li>
															<li><FormattedMessage id="Has a sophisticated professional trust sector" defaultMessage="Has a sophisticated professional trust sector, modern trusts legislation and an effective judicial system"></FormattedMessage></li>
														</ul>
														<div className="btn-pdf"><a href="/order-form/54" className="btn-by-btn"><FormattedMessage id="Start Now" defaultMessage="Start Now"></FormattedMessage></a></div>
														<p className="mant"><FormattedMessage id="For annual maintenance, please click" defaultMessage="For annual maintenance, please click "></FormattedMessage> <a href="/order-form/60"><FormattedMessage id="here!" defaultMessage="here!"></FormattedMessage></a></p>
													</div>
												</div>
											</div>
											<div className="col-md-4 pr-md-2 pl-md-2">
												<div className="pricing-colam">
													<div className="price-head">
														<h4><FormattedMessage id="Cayman Business Company" defaultMessage="Cayman Business Company"></FormattedMessage>
														</h4>
														<span><FormattedMessage id="US" defaultMessage="US"></FormattedMessage>$5,800</span> 
													</div>
													<div className="pricing-inner">
														<div className="price-tham"><img src="images/cayman-business-picture.jpg" alt="#" /> </div>
														<ul className="audit">
															<li><FormattedMessage id="Abundance of expertise in the investment fund space" defaultMessage="Abundance of expertise in the investment fund space"></FormattedMessage>
															</li>
															
															<li><FormattedMessage id="Can be used as listing vehicles on international stock exchanges" defaultMessage="Can be used as listing vehicles on international stock exchanges"></FormattedMessage>
															 </li>
															<li><FormattedMessage id="Unlike many other “tax havens”, Cayman is on the OECD “White List”" defaultMessage="Unlike many other “tax havens”, Cayman is on the OECD “White List”"></FormattedMessage>
															</li>
															
															<li><FormattedMessage id="Has approachable, flexible, innovative and efficient regulators" defaultMessage="Has approachable, flexible, innovative and efficient regulators"></FormattedMessage>
															 </li>
														</ul>
														<div className="btn-pdf"><a href="/order-form/53" className="btn-by-btn"><FormattedMessage id="Start Now" defaultMessage="Start Now"></FormattedMessage></a></div>
														<p className="mant"><FormattedMessage id="For annual maintenance, please click" defaultMessage="For annual maintenance, please click "></FormattedMessage> <a href="/order-form/58"><FormattedMessage id="here!" defaultMessage="here!"></FormattedMessage></a></p>
													</div>
												</div>
											</div>
											<div className="col-md-4 pr-md-2 pl-md-2">
												<div className="pricing-colam">
													<div className="price-head">
														<h4><FormattedMessage id="Seychelles IBC" defaultMessage="Seychelles IBC"></FormattedMessage>
														</h4>
														<span><FormattedMessage id="US" defaultMessage="US"></FormattedMessage>$1,200</span>
													</div>
													<div className="pricing-inner">
														<div className="price-tham"><img src="images/seychelles-picture.jpg" alt="#" /> </div>
														<ul className="audit">
															<li> <FormattedMessage id="Low registration and maintenance fee" defaultMessage="Low registration and maintenance fee"></FormattedMessage>
															</li>
															<li><FormattedMessage id="Stable independent republic, no US or EU IRS interference" defaultMessage="Stable independent republic, no US or EU IRS interference"></FormattedMessage>
															 </li>
															<li> <FormattedMessage id="Acceptable to a wide range of international banks" defaultMessage="Acceptable to a wide range of international banks"></FormattedMessage>
															</li>
															<li><FormattedMessage id="Nominee shareholders and directors are permitted" defaultMessage="Nominee shareholders and directors are permitted"></FormattedMessage>
															 </li>
														</ul>
														<div className="btn-pdf"><a href="/order-form/62" className="btn-by-btn"><FormattedMessage id="Start Now" defaultMessage="Start Now"></FormattedMessage></a></div>
														<p className="mant"><FormattedMessage id="For annual maintenance, please click" defaultMessage="For annual maintenance, please click "></FormattedMessage> <a href="/order-form/59"><FormattedMessage id="here!" defaultMessage="here!"></FormattedMessage></a></p>
													</div>
												</div>
											</div>
										</div>
									</div>									
									
								</div>
								<div className="tab-pane" id="more" role="tabpanel">
									<div className="filing">
										<h3><FormattedMessage id="More Options" defaultMessage="More Options"></FormattedMessage>
										</h3>										
									</div>
									<div>
										<div className="email-box">											
											<div className="jeng">
												<h3><FormattedMessage id="Send us an email: " defaultMessage="Send us an email: "></FormattedMessage>
												<a href="mailto:info@jenga.io"><FormattedMessage id="info@jenga.io" defaultMessage="info@jenga.io"></FormattedMessage>
												</a> 
												</h3>
												
											</div>
										</div>
										<div className="email-box">											
											<div className="jeng">
												<h3><FormattedMessage id="Call us now: " defaultMessage="Call us now: "></FormattedMessage> <a href="tel:+65 8882 4795"> +65 8882 4795</a> </h3>
												
											</div>
										</div>
									</div>
									<div className="filing col-md-12 p-0">
										<p><FormattedMessage id="Our incorporation specialist will" defaultMessage="Our incorporation specialist will get back to you with a customized solution shortly."></FormattedMessage>

										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tabs-row vie">
						<div className="entit col-md-10 p-0">
							<h2> <FormattedMessage id="We work closely " defaultMessage="We work closely with our legal partners to provide business structure solutions"></FormattedMessage>
                                </h2>
						</div>
						<div className="shore-tabs business">
							<ul className="nav nav-tabs responsive " role="tablist">
								{/* <li className="nav-item"> <a className="nav-link active" data-toggle="tab" href="#variable" role="tab"><FormattedMessage id="Variable Interest Entity (VIE)" defaultMessage="Variable Interest Entity (VIE)"></FormattedMessage></a> </li> */}
								<li className="nav-item"> <a className="nav-link active" data-toggle="tab" href="#caymen" role="tab"><FormattedMessage id="Cayman Fund" defaultMessage="Cayman Fund"></FormattedMessage></a> </li>
								<li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#fund" role="tab"><FormattedMessage id="Singapore Fund" defaultMessage="Singapore Fund"></FormattedMessage></a> </li>
								<li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#office" role="tab"><FormattedMessage id="Family Office" defaultMessage="Family Office"></FormattedMessage></a> </li>
							</ul>
							<div className="tab-content responsive">
								{/* <div className="tab-pane active" id="variable" role="tabpanel">
									<div className="filing">
										<h3><FormattedMessage id="Variable Interest Entity (VIE)" defaultMessage="Variable Interest Entity (VIE) "></FormattedMessage>
										</h3><p><FormattedMessage id="Variable Interest Entity Content " defaultMessage="VIE is the abbreviation of Variable Interest Entity. Variable Interest Entities, also known as the Agreement Control Structure, refers to the separation of the registered entities registered overseas from the domestic operating entities. That is, the overseas listedentities control the domestic operating entities through agreements, thereby separating the listed entity registered overseas from domestic business operation entity to achieve various purposes such as overseas financing and listing."></FormattedMessage></p>
									</div>
									<div className="buy-dta"> <span className="buy-td"><a href="#" className="shadow"><FormattedMessage id="Learn More" defaultMessage="Learn More"></FormattedMessage></a></span>  <a href="#" className="envel shadow"><i className="far fa-file-pdf"></i></a><a href="mailto:info@jenga.io" className="envel shadow"><i className="far fa-envelope"></i></a> </div>
								</div> */}
								<div className="tab-pane active" id="caymen" role="tabpanel">
									<div className="filing">
										<h3><FormattedMessage id="Cayman Fund" defaultMessage="Cayman Fund"></FormattedMessage></h3>
										<p><FormattedMessage id="The Cayman Islands" defaultMessage="The Cayman Islands continues to be the leading jurisdiction for fund formation. Investment funds established in the Cayman Islands fall into two broad categories: open-ended funds and close-ended funds. Open-ended funds provide investors with voluntary redemption or repurchase rights, while closed-ended funds are fixed. Typically, open-ended funds will invest in liquid assets that can be readily realised to fund redemptions (e.g. listed, liquid, tradable securities) and close-ended funds will invest in non-liquid assets requiring time to liquidate/realise value (e.g. real estate, unlisted growth companies)."></FormattedMessage>
										</p>
									</div>
									<div className="buy-dta"> <span className="buy-td"><a href="/order-form/53" className="shadow"><FormattedMessage id="Start Now" defaultMessage="Start Now"></FormattedMessage></a></span>  <a href="#" className="envel shadow"><i className="far fa-file-pdf"></i></a><a href="mailto:info@jenga.io" className="envel shadow"><i className="far fa-envelope"></i></a> </div>
								</div>
								<div className="tab-pane" id="fund" role="tabpanel">
									<div className="filing">
										<h3><FormattedMessage id="Singapore Fund" defaultMessage="Singapore Fund"></FormattedMessage></h3>
										<p>
										<FormattedMessage id="Singapore Fund Management Content" defaultMessage="Singapore Fund Management Company is regulated by Monetary Authority of Singapore (MAS) under the Securities and Futures Act (Cap. 289). To conduct the regulated activity of fund management, Fund Management Company needs to obtain either registration or license from MAS as a Registered Fund Management Company (RFMC), Capital Markets Services License (CMSL) as a Licensed Fund Management Company (LFMC) or a Venture Capital Fund Manager (VCFM), or be expressly exempted from holding a Licence. RFMC and VCFM are subject to less stringent compliance requirements and oversight, whereas the Licensed Fund Management Company would be deemed to be a full license and would have stricter preconditions before being granted to an applicant. You can incorporate your fund management company, which is a Private Limited company by shares, in Singapore anytime, and contact us for regulatory requirement support."></FormattedMessage></p>
									</div>
									<div className="buy-dta"> <span className="buy-td"><a href="/order-form/1" className="shadow"><FormattedMessage id="Start Now" defaultMessage="Start Now"></FormattedMessage></a></span>  <a href="#" className="envel shadow"><i className="far fa-file-pdf"></i></a><a href="mailto:info@jenga.io" className="envel shadow"><i className="far fa-envelope"></i></a> </div>
								</div>
								<div className="tab-pane" id="office" role="tabpanel">
									<div className="filing">
										<h3><FormattedMessage id="Family Office" defaultMessage="Family Office"></FormattedMessage></h3>
										<p><FormattedMessage id="Family Office Content According to MAS" defaultMessage="According to MAS, the term ‘ Single family office’ (SFO) is not defined under the Securities and Futures Act (SFA) in Singapore. An SFO typically refers to an entity that manages assets for or on behalf of only one family and is wholly owned or controlled by members of the same family. It is not MAS’ intention to license or regulate SFOs. There are existing class exemptions from licensing under the SFA and the Financial Advisers Act for the provision of fund management and financial advisory services respectively to related corporations. However, a Multi-Family Office (MFO) needs to obtain relevant registration or license from MAS. You can incorporate your family office, which is a Private Limited company by shares, in Singapore anytime, and contact us for regulatory requirement support."></FormattedMessage></p>
									</div>
									<div className="buy-dta"> <span className="buy-td"><a href="/order-form/1" className="shadow"><FormattedMessage id="Start Now" defaultMessage="Start Now"></FormattedMessage></a></span>  <a href="#" className="envel shadow"><i className="far fa-file-pdf"></i></a><a href="mailto:info@jenga.io" className="envel shadow"><i className="far fa-envelope"></i></a> </div>
								</div>
							</div>
						</div>
					</div>
					<div className="clients">
						<div className="entit">
							<h2> <FormattedMessage id="Customer Satisfaction is Our Top Priority" defaultMessage="Customer Satisfaction is Our Top Priority"></FormattedMessage>
							 </h2>
						</div>
						<div className="clients-ratin">
							<div id="myCarousel" className="carousel slide" data-ride="carousel">
								<ol className="carousel-indicators">
									<li data-target="#myCarousel" data-slide-to="0" className="active"  >
										<div className="img-box"><img src="images/steve_ahn.jpg" alt="" /></div>
									</li>
									<li data-target="#myCarousel" data-slide-to="1">
										<div className="img-box"><img src="images/james_clark.jpg" alt="" /></div>
									</li>
									<li data-target="#myCarousel" data-slide-to="2">
										<div className="img-box"><img src="images/harvey_huang.jpg" alt="" /></div>
									</li>
									<li data-target="#myCarousel" data-slide-to="0"  >
										<div className="img-box"><img src="images/steve_ahn.jpg" alt="" /></div>
									</li>
								</ol>
								<div className="carousel-inner">
									<div className="item carousel-item active">
										<p className="testimonial" style={this.props.currentLang == 'en'? {}:{fontSize:14+'px'}}><FormattedMessage id="My firm worked with Jenga for" defaultMessage="My firm worked with Jenga for registration of our Singapore rep office as well for my EP application.  I was greatly impressed by their professionalism and responsiveness.  They were able to answer all of my questions in a timely manner and obtained our registration and EP in the shortest time possible."></FormattedMessage></p>
										<p className="overview"><b><FormattedMessage id="- Steve Ahn (Korea)" defaultMessage="- Steve Ahn (Korea)"></FormattedMessage>
										</b><br /><FormattedMessage id="Partner at SEUM Law" defaultMessage="Partner at SEUM Law"></FormattedMessage></p>
									</div>
									<div className="item carousel-item">
										<p className="testimonial" style={this.props.currentLang == 'en'? {}:{fontSize:14+'px'}}><FormattedMessage id="We engaged Jenga to guide us" defaultMessage="We engaged Jenga to guide us in establishing a Singapore corporation to manage our intellectual property and royalty agreements with our on-shore, mainland China business partners.   The Jenga team brought  the full breadth of skills - regulatory, accounting and administrative -  to support us as well as provided insight on cross-border China market issues as well as Singapore regulatory compliance.  Each Team member supporting us was knowledgeable, efficient and pleasant to work with. We are very satisfied with Jenga’s work."></FormattedMessage></p>
										<p className="overview"><b><FormattedMessage id="James Clark (USA)" defaultMessage="James Clark (USA)"></FormattedMessage>
										</b><br /><FormattedMessage id="President, Red Summit Ventures(Former CEO at QVC China)" defaultMessage="President, Red Summit Ventures(Former CEO at QVC China)"></FormattedMessage></p>
									</div>
									<div className="item carousel-item">
										<p className="testimonial" style={this.props.currentLang == 'en'? {}:{fontSize:14+'px'}}><FormattedMessage id="We were very satisfied working with Jenga" defaultMessage="We were very satisfied working with Jenga for the whole process of setting up our fund Singapore management company, licensing with MAS for Capital Market Service, as well as launching our first fund under the new structure VCC. For businesses intending to carry out regulated activities, especially fund management, Jenga has been phenomenal due to their knowledge in obtaining approval and licenses. Their in-depth understanding of the rules has allowed a swift and efficient licensing process."></FormattedMessage></p>
										<p className="overview"><b><FormattedMessage id="- Harvey Huang (Singapore)" defaultMessage="- Harvey Huang (Singapore)"></FormattedMessage>
										</b><br /><FormattedMessage id="CEO, Harvest Capital" defaultMessage="CEO, Harvest Capital"></FormattedMessage></p>
									</div>
								</div>
								<a className="carousel-control left carousel-control-prev" href="#myCarousel" data-slide="prev">
								<i className="fas fa-arrow-left"></i>
								</a>
								<a className="carousel-control right carousel-control-next" href="#myCarousel" data-slide="next">
								<i className="fas fa-arrow-right"></i>
								</a>
							</div>
						</div>
					</div>
					<div className="directly">
						<div className="entit">
							<h2 className="mb-5"> <FormattedMessage id="We work directly with" defaultMessage="We work directly with"></FormattedMessage></h2>
						</div>
						<div className="all-ct">
							<ul>
								<li><a href="https://www.iras.gov.sg/irashome/default.aspx"><img src="images/ct01.png" alt="#"/></a></li>
								<li><a href="https://www.acra.gov.sg/"><img src="images/ct02.png" alt="#" style={{width:'60%'}}  /></a></li>
								<li><a href="https://www.corppass.gov.sg/"><img src="images/ct03.png" alt="#"/></a></li>
								<li><a href="https://www.mom.gov.sg/"><img src="images/ct04.png" alt="#" style={{width:'60%'}}  /></a></li>
								<li><a href="https://www.edb.gov.sg/connections-concierge/service-providers/jenga-corp-pte-ltd.html"><img src="images/edb-logo.svg" alt="#" style={{width:'60%'}} /></a></li>
								<li><a href="https://www.ipos.gov.sg/"><img src="images/ct05.png" alt="#" style={{width:'60%'}} /></a></li>
								<li><a href="https://www.enterprisesg.gov.sg/"><img src="images/ct06.png" alt="#" /></a></li>
								<li><a href="https://www.dbs.com.sg/"><img src="images/ct07.png" alt="#" /></a></li>
								<li><a href="https://www.uob.com.sg/"><img src="images/ct08.png" alt="#" /></a></li>
								<li><a href="https://info.maybank2u.com.sg/"><img src="images/ct09.png" alt="#" /></a></li>
								<li><a href="https://www.ocbc.com/"><img src="images/ct10.png" alt="#" /></a></li>
								<li><a href="https://www.airwallex.com/"><img src="images/airwallx.png" alt="#" /></a></li>
								<li><a href="https://www.anext.com.sg/"><img src="images/anext.png" alt="#" /></a></li>
								<li><a href="https://aspireapp.com/"><img src="images/aspire.png" alt="#" /></a></li>
								<li><a href="https://www.fomopay.com/"><img src="images/fomo-pay.png" alt="#" /></a></li>
							</ul>
						</div>
					</div>
					<div className="jiga-app">
						<div className="row justify-content-between">
							<div className="col-md-6 col-7">
								<div className="app-colam">
									<h4><FormattedMessage id="JengaCorp Mobile Application" defaultMessage="JengaCorp Mobile Application"></FormattedMessage></h4>
									<p className="pt-md-2"><FormattedMessage id="Launching Soon Content" defaultMessage="Digital company incorporation platform providing corporate governance and secretarial service."></FormattedMessage></p>
									<h1 className="mb-md-4"><FormattedMessage id="Launching Soon" defaultMessage="Launching Soon"></FormattedMessage></h1>
								</div>
							</div>
							<div className="col-md-4 col-5">
								<div className="app-img"><img src="images/app.png" alt="#" /></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(HomeInCorp)