import React, { Component } from 'react'
import { Redirect } from 'react-router'

import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import Header from '../partials/Header'
import BreadCrumbs from '../partials/BreadCrumbs'
import HeadBanner from '../partials/HeadBanner'

import Footer from '../partials/Footer'


class Terms extends Component {

	constructor(props) {

		super(props)

		this.state = {
		}

	}

	componentWillMount() {

		const self = this;

	}

	render() {


		return (
			<div>
				<Header />
				<HeadBanner title="Terms and Conditions" />
				<BreadCrumbs pages={[
					{
						title: "Home",
						link: "/"
					},
					{
						title: "Terms and Conditions"
					}
				]} />
				<section className="outer bg-white gray pb-0 text14">
					<div className="container transparent pb-5 ">
						<div className="small-container pb-3">
							<div className="terms-list">
								<ol>
									<li>
										<h4>
											<FormattedMessage id="CONTRACTING PARTY" defaultMessage="CONTRACTING PARTY"></FormattedMessage>
										</h4>
										<ol>
											<li>
												<p>Jenga Corp Pte ltd (the "Company") is registered in Singapore as a Filing Agent under section 28F of the Accounting and Corporate Regulatory Authority Act with its registered office at 3 Fraser Street, DUO Tower, #05- 21 Singapore 189352.</p>
											</li>
											<li>
												<p>All references in these Standard Terms of Engagement to we, us, our or the Company (and all similar terms) are references to the Company acting through its personnel, being either members or employees or consultants of or to it.</p>
											</li>
										</ol>
									</li>
									<li><h4>SERVICES</h4><ol><li><p>Our services are solely limited to consultancy services in the nature of support services.</p></li><li><p>We shall devote such time and efforts to the performance of the services contemplated hereby as we may deem reasonably necessary or appropriate, provided that no minimum number of hours is required to be devoted on a weekly, monthly, annual or other basis. You acknowledge that the services provided are not exclusive to you, and we may render similar services to other persons and entities.</p></li><li><p>We shall in no way guarantee or be responsible for the success of the project or undertaking for which our services are required.</p></li><li><p>We make no representations or warranties regarding the work product and/or services of third party service providers which may be appointed for your project or undertaking.</p></li><li><p>You shall use your best effort to co-operate with us to enable us to provide our services, and you shall respond as soon as practicable to any reasonable requests made by us for approvals, instructions, information, documents, or assistance. If you are able to provide the requested information and instructions promptly, we will be able to work more efficiently and cost effectively for you. All fee arrangements and estimates, and timetables, are dependent on you and others appointed by you in providing information and instructions in a timely manner.</p></li></ol></li>
									<li><h4>FEES</h4><ol><li><p>Our fees take account of all circumstances relating to the project and/or undertaking as informed to us, including limitation the complexity of the project and/or undertaking and the difficulty or novelty of the issues raised, the skill, labour and specialised knowledge required, time spent, and the value of the project and/or undertaking.</p></li></ol></li>
									<li><h4>DISBURSEMENTS</h4><ol><li><p>In addition to our fees, you are responsible for all our other expenses and disbursements (including travelling expenses, specific disbursements such as court fees, and the fees, costs and charges of counsel, foreign lawyers and other experts whom we have engaged to provide services on your behalf if required). We may also incur communications, copying, courier charges and other costs in connection with the provision of our services.</p></li></ol></li>
									<li><h4>STORAGE OF PAPERS AND DOCUMENTS</h4><ol><li><p>We are entitled to keep all your papers and documents and any records of electronic communications for up to 7 years.</p></li></ol></li>
									<li><h4>CLIENT INFORMATION AND DATA PROTECTION</h4><ol><li><p>Before we start work, we may be required to obtain information from you in order to comply with regulations relating to KYC, money laundering and other matters (such as verifying the identity of certain persons). This may include making enquiries of third party data providers.</p></li><li><p>We may use personal data in any way that we consider is necessary to carry out our responsibilities to you or to maintain and preserve our records. This may include releasing the data to third parties or transferring the data outside Singapore. We may also use personal information to send to you selected information that we produce relating to our services. If you prefer not to receive this type of material, please let us know.</p></li><li><p>On the payment of a small fee, you have the right to be provided with a copy of the personal data we hold about you and an explanation of how we use it. In certain circumstances you may also require us to alter or remove any personal data we may hold about you.</p></li></ol></li>
									<li><h4>NO FINANCIAL SERVICES</h4><ol><li><p>The provision of our services may relate to investments. No communications from the Company is, nor is it intended to be, an invitation or inducement (direct or indirect) to engage in investment activity.</p></li></ol></li>
									<li><h4>CONFIDENTIAL INFORMATION</h4><ol><li>Any information that we receive from you and any other consultants or advisers during the course of providing the services will be treated as confidential and we will not disclose such information without your prior consent, except to directors, members, employees of or consultants to the Company or any entity affiliated to the Company or to your consultants or advisers where we consider that it is appropriate for that person to know such information.</li><li>In certain circumstances we may be obliged by law to disclose to relevant authority’s information relating to our appointment which would otherwise be confidential. We may also be required not to inform you of such disclosure. If we believe that we are obliged to disclose to any relevant authority or third party information confidential to you, then we shall be free to do so without incurring any liability to you as a result.</li><li>We will not disclose to you any information obtained by us in relation to the affairs of any other client of the Company, without that client's prior consent.</li><li>We assume that information which you give or otherwise disclose to us and which is subject to confidentiality obligations owed by you to a third party has not been given and/or disclosed to us in breach of those obligations. You shall be responsible for obtaining the necessary consents from these third parties separately.</li></ol></li>
									<li><h4>TERMINATION</h4><ol><li>We reserve the right to terminate any of the services that we have provided or will be providing in part or in whole for any reason if the you breach any of the terms. We shall not be liable for any loss or damage incurred by you due to the terminate</li><li>After the payment of the annual fees for our services, you may not terminate any or all of the services for which such fees are prepaid and any deposit held will be repaid at the end of the period covered by the fees paid.</li></ol></li>
									<li><h4>INDEMNITY</h4><ol><li>You agree to indemnify and hold harmless (a) the Company, (b) any entity affiliated to the Company, (c) its respective directors, members, shareholders, employee of or consultant of the Company or such entity affiliated to the Company (collectively, Indemnified Persons), from and against all losses, damages, claims, liabilities and expenses as incurred (including legal fees on an indemnity basis, disbursements of counsel and the costs of the Company's time), joint or several (including actions or proceedings in respect thereof) (collectively, Losses), relating to or arising out of our provision of the services or the project and/or undertaking for which our services are required.</li><li>You also agree that no Indemnified Person shall have any liability (whether direct or indirect, in contract or in tort or otherwise) to you or any person claiming through you, including without limitation its owners, parents, affiliates, security holders or creditors, for any Losses suffered by you or any such other person relating to or arising out of our provision of the services or the project and/or undertaking for which our services are required, and that the Company shall be reimbursed for any expenses as incurred by any Indemnified Persons relating to the foregoing (including legal fees on an indemnity basis, disbursements of counsel and the costs of the Company's time).</li></ol></li>
									<li><h4>LIABILITY FOR LOSSES SUFFERED BY YOU</h4><ol><li>We will not be liable to you for any loss arising out of or in connection with our appointment, in contract, tort, by statute or otherwise, unless the loss is primarily caused by our negligence or default or our liability cannot be excluded or limited by law. We will also not be liable for any loss of profit, loss of business, loss of opportunity or other indirect or consequential losses whatsoever arising from any negligence or default on our part, nor will we have any liability for any loss which directly or indirectly arises out of or results from or otherwise involves, however remotely, any act of terrorism or wars or warlike operations (whether war be declared or not) or civil unrest.</li><li>It is agreed that our aggregate liability to you for any losses for which we are liable arising out of the relevant transaction(s) or arrangements shall not exceed the total amount of fees paid to us for the services.</li><li>You agree that by engaging the Company you will not bring any claim arising out of or in connection with our engagement personally against any individual member or director of or other person employed in any capacity by us or any other entity affiliated to us, however, this restriction will not operate to limit or exclude the liability of the Company.</li><li>You also agree that if, as part of our appointment governed by these terms, we involve the services of another office of the Company acting through an entity other than the through an entity other the Company, or any individual whether engaged as a director, member, employee or in any capacity whatsoever, you will not bring any claim against that other office or entity or any individual who is a director, member, shareholder, employee of or consultant to that other office or entity, and that any recourse arising out of such appointment shall be exclusively against the Company.</li></ol></li>
									<li><h4>ELECTRONIC COMMUNICATIONS</h4><ol><li>We may communicate electronically with each other or handle information electronically. We will each be responsible for protecting our own systems and interests in relation to electronic communications, and we will have no liability to you or your agents on any basis, whether in contract, tort (including negligence) or otherwise, in respect of any error, damage, loss, omission or delay arising from or in connection with the electronic handling or communication of information between us, or any failure in any electronic handling or communication system. We do not guarantee that electronic information and electronic transmission of information will be secure and virus and error free nor do we guarantee that it will not be accessed improperly, intercepted, corrupted, lost or destroyed, or that it will arrive promptly or complete or otherwise be unaffected or safe to use. We use filtering software which may filter out legitimate correspondence and you are advised to confirm with us that any important correspondence has been safely received.</li></ol></li>
									<li><h4>PUBLICITY</h4><ol><li>Unless we otherwise agree with you, we may at any time after public announcement of the project and/or undertaking in respect of which our services are required, publish marketing and/or promotional materials relating to our involvement using your name and logo and otherwise containing only publicly available information.</li></ol></li>
									<li><h4>INTELLECTUAL PROPERTY</h4><ol><li>We retain the copyright and all other relevant intellectual property rights in our work products (including without limitation concepts, know-how, tools, frameworks, models, and industry perspectives developed or enhanced outside of or in connection with the services) but you will have a licence to use and make copies of the documents (including materials provided in electronic form) we prepare for the purposes of the project and/or undertaking in connection with which the work product was created but not (unless otherwise agreed) for other projects or matters. </li><li>You acknowledge that the Company may develop for itself, or for others, problem solving approaches, frameworks or other tools or information similar to the materials and processes developed for the purpose of the project and/or undertaking, and nothing contained herein precludes the Company from developing or disclosing such materials and information.</li></ol></li>
									<li><h4>NO PARTNERSHIP OR AGENCY</h4><ol><li>Nothing in our relationship is intended to, or shall be deemed to, establish any partnership or joint venture between us, or constitute any party the agent of the other party, or authorise any party to make or enter into any commitments for or on behalf of the other party (save as expressly provided).</li></ol></li>
									<li><h4>RIGHTS OF THIRD PARTIES</h4><ol><li>Our engagement by you and for you creates rights and obligations only between you and us and the rights of third parties arising under the provisions of the Contracts (Rights of Third Parties) Act (Chapter 53B of Singapore) are specifically excluded from the terms of our engagement with you.</li></ol></li>
									<li><h4>GOVERNING LAW</h4><ol><li>The Standard Terms of Engagement shall bel governed by and construed in accordance with the laws of the Republic of Singapore.</li></ol></li>
								</ol>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Terms)