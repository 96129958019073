import React, { Component } from 'react'
import Header from '../partials/Header'
import HomeBanner from '../partials/HomeBanner'
import HomeInCorp from '../partials/HomeInCorp'
import Footer from '../partials/Footer'

export default class Home extends Component {

	componentWillMount() {
	}

	render() {
		return (
			<div>
				<Header />
				<HomeBanner />
				<HomeInCorp />
				<Footer />
			</div>
		)
	}
}