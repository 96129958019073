import axios from 'axios'
import DefaultENV from '../env/default'

export default class BaseService {

	constructor() {

		this.axiosConf = {
			baseURL: DefaultENV.API_BASE
		}

	}

	apiCall(url, method = 'GET', data = null, withCredentials = false) {

		let axiosConf = {
			...this.axiosConf,
			url: url,
			method: method
		}

		if( data ) {
			axiosConf.data = data
		}

		/*if(method == 'POST') {
			axiosConf.withCredentials = withCredentials
			axiosConf.crossDomain = true;
		}*/
		axiosConf.withCredentials = withCredentials
		axiosConf.crossDomain = true

		return axios(axiosConf)

	}

}
