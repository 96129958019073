import BaseService from './BaseService'

export default class NewsListService extends BaseService {

	getNewsListSections() {
		return this.apiCall(
			`weChatNews?_format=json`,
			'GET',
			{},
			true
		).then((response) => {

			return response.data;
			
		})
	}

}