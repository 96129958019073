//import { combineReducers } from 'redux';
import DataReducer from './DataReducer';
import OrderFormReducer from './OrderFormReducer';
import OrderCartReducer from './OrderCartReducer';
import OrderCheckoutReducer from './OrderCheckoutReducer';
import { intlReducer } from 'react-intl-redux'

export default {
  Data: DataReducer,
  OrderForm: OrderFormReducer,
  OrderCart: OrderCartReducer,
  OrderCheckout: OrderCheckoutReducer,
  intl: intlReducer,
  locales: null
};
