import React, { Component } from 'react'
import { Redirect } from 'react-router'

import { connect }  from 'react-redux'

import '../css/views/PrivacyPolicy.css'

import Header from '../partials/Header'
import BreadCrumbs from '../partials/BreadCrumbs'
import HeadBanner from '../partials/HeadBanner'

import Footer from '../partials/Footer'

import BillingDetails from '../components/BillingDetails'
import OrderDetails from '../components/OrderDetails'
import CheckoutPaymentMethod from '../components/CheckoutPaymentMethod'


//import { addOrderItem, removeOrderItem } from '../actions/OrderCartActions'

class PrivacyPolicy extends Component {

	constructor(props) {
		
		super(props)

		this.state = {
		}

	}

	componentWillMount() {

		const self = this;
		
	}

	render() {

		//{ !this.state.isCheckoutAllowed ? ( <Redirect to="/" push /> ) : "" }
		
		return (
			<div>
				<Header />
				<HeadBanner title="Privacy Policy" />
				<BreadCrumbs pages={ [
					{
						title: "Home",
						link: "/"
					},
					{
						title: "Privacy Policy"
					}
				] } />
				<section class="outer bg-white gray privacy-policy">
					<div class="container">
						<div class="row">
							<div class="col-md-12 pt-4">
								<table>
									<tbody>
										<tr>
											<td valign="top">
												<h2>1. Scope</h2>
												<p>
													All data subjects whose personal data is collected, in line with the requirements of the GDPR.
												</p>
											</td>
										</tr>
										<tr>											
											<td valign="top">
												<h2>2. Responsibilities</h2>
											</td>
										</tr>
										<tr>
											<td valign="top">
												<p>2.1
													The Data Protection Officer (DPO) is responsible for ensuring that this notice is made available to data subjects prior to Jenga Corp Pte. Ltd., its partners, and associates’, henceforth be collectively known as "Jenga BCG", collection of/processing their personal data. <br/>
												
												2.2
													All Employees of Jenga BCG, who interact with data subjects are responsible for ensuring that this notice is drawn to the data subject’s attention and their consent to the processing of their data is secured.
												</p>
											</td>
										</tr>
										<tr>
											<td valign="top">
												<h2>
													3. Privacy notice
												</h2>
											</td>
										</tr>
										<tr>
											<td valign="top">
												<h3>
													3.1 Who are we?
												</h3>
												<p>
													Headquartered in Singapore, a premier fintech and blockchain hub in the APAC, Jenga BCG is a multi-discipline advisory for global business expansion. We advise on a wealth of business related challenges from corporate structuring and incorporation of structure across multiple jurisdiction to global banking and compliance challenges. We also provide advisory and support for our clients’ marketing and communication strategy for different APAC markets and specialise in assisting Chinese, Korean and South East Asian ventures into the global arena through Singapore as a launchpad.
												</p>
												<p>
													Jenga is founded with the vision of championing technology innovation and the ecosystem of entrepreneurship by empowering technology start-ups and accelerating their growth. By engaging with our team of highly regarded experts in the various fields of blockchain, investment banking, venture capitalist, marketing and management consulting, you will be receive valuable support in strategic business planning, implementation and advisory built upon the solid foundation of industry best practices to ensure your company’s perpetual success.
												</p>
												<p>
													Our Data Protection Officer (DPO) and data protection representatives can be contacted directly here:
												</p>
												<ul>
													<li>
														Jared Ng (jared@jenga.io)
													</li>
													<li>
														Mobile (+65 8750 5116)
													</li>
												</ul>
											</td>
										</tr>
										<tr>
											<td valign="top">
												<h3>
													3.2 Our Services
												</h3>
												<ul>
													<li>
														The provision of company incorporation or onboarding of an existing entity;
													</li>
													<li>
														Maintenance of clients' statutory registers in Jenga Corp's company secretarial database in accordance with applicable statutory requirements;
													</li>
													<li>
														Provision of registered office address;
													</li>
													<li>
														Management and payment of regulatory filing and compliance requirements;
													</li>
													<li>
														Changes to the officers, directors or shareholders of our client<span>'</span>s entity
													</li>
													<li>
														Preparation of all relevant documents necessary for the day to day governance of the entities under the management of Jenga Corp.
													</li>
													<li>
														Submitting all necessary filings with the local government registration agents if and when such changes are required under such applicable laws, including all changes to the officers, directors or shareholders and any other changes to our clients' entity.
													</li>
													<li>
														Administering other standard corporate governance matters.
													</li>
													<li>
														Provision of other corporate services including but not limited to; bank account opening, application for work permits and employment passes with local authorities, accounting, tax compliance and filing.
													</li>
												</ul>
											</td>
										</tr>
										<tr>
											<td valign="top">
												<h3>
													3.3 Personal Data
												</h3>
												<p>
													The personal data we would like to collect from you is as follows:
												</p>
												<table border="1" width="90%" class="tablelarge">
													<tr>
														<td>
															<b>
																Personal data type:
															</b>
														</td>
														<td>
															<b>Source</b> (where Jenga BCG obtained the personal data from if it has not been collected directly from you, the data subject. Note if the personal data has been accessed from publicly accessible sources):
														</td>
													</tr>
													<tr>
														<td>Name</td>
														<td>Accounting and Corporate Regulatory Authority, Singapore</td>
													</tr>
													<tr>
														<td>Copy of ID/Driving License</td>
														<td> </td>
													</tr>
													<tr>
														<td>ID/Driving License Number</td>
														<td> </td>
													</tr>
													<tr>
														<td>Copy of Passport</td>
														<td> </td>
													</tr>
													<tr>
														<td>Passport Number</td>
														<td>Accounting and Corporate Regulatory Authority, Singapore</td>
													</tr>
													<tr>
														<td>Nationality</td>
														<td> </td>
													</tr>
													<tr>
														<td>Marital Status</td>
														<td> </td>
													</tr>
													<tr>
														<td>Education Status</td>
														<td> </td>
													</tr>
													<tr>
														<td>Religion</td>
														<td> </td>
													</tr>
													<tr>
														<td>Education Certificates</td>
														<td> </td>
													</tr>
													<tr>
														<td>Residency Status</td>
														<td> </td>
													</tr>
													<tr>
														<td>Residential Address</td>
														<td>Accounting and Corporate Regulatory Authority, Singapore</td>
													</tr>
													<tr>
														<td>Mailing Address</td>
														<td> </td>
													</tr>
													<tr>
														<td>Telephone Number</td>
														<td> </td>
													</tr>
													<tr>
														<td>Email Address</td>
														<td> </td>
													</tr>
													<tr>
														<td>Profession, job title or occupation</td>
														<td> </td>
													</tr>
													<tr>
														<td>Politically Exposed Persons Status Check</td>
														<td>Artemis by cynopsis.co</td>
													</tr>
													<tr>
														<td>KYC Screening</td>
														<td>Artemis by cynopsis.co</td>
													</tr>
													<tr>
														<td>Anti-Money Laundering Risk Assessment </td>
														<td>Artemis by cynopsis.co</td>
													</tr>
													<tr>
														<td>Counter-Terrorism Financing Risk Assessment</td>
														<td>Artemis by cynopsis.co</td>
													</tr>
													<tr>
														<td>
															Assessment
														</td>
														<td> </td>
													</tr>
												</table>
												<br />
												<p>
													We collect your personal data directly from you, unless unreasonable or impracticable to do so. When collecting personal data, we may collect it in various other ways, including:
												</p>
												<ul>
													<li>
														The access and use of our website and/or the use of our online platform through the use of cookies and other information gathered from users and visitors accessing our website;
													</li>
													<li>
														Through the clients' entities under our management or public accessible information available or procurable through governmental institutions.
													</li>
													<li>
														Upon your completion of an order through our online platform; or
													</li>
													<li>
														Through emails, messaging applications or other file sharing means, which you use to provide the personal data to us.
													</li>
												</ul>
												<p>
													If you do not provide us with any of the personal data required, we may not be able to provide the requested services to you or your entity, either to the same standard as if you had provided the personal data, or at all.
												</p>
											</td>
										</tr>
										<tr>
											
											<td valign="top">
												<h3>
													3.4 Purpose for Collection, Use and Disclosure
												</h3>
												<p>
													We collect, use and disclose your personal data for the following purposes:
												</p>
												<ul>
													<li>
														Provide the requested Jenga Corp services to you and/or your entity and to send communications as requested, or for consent purposes by you and/or your entity.
													</li>
													<li>
														Verify you and/or your entity's identity.
													</li>
													<li>
														Provide access to you and/or your entity to our online platform.
													</li>
													<li>
														Provide you with access to all or certain areas of our website.
													</li>
													<li>
														Answer enquiries and provide information or advice regarding our services.
													</li>
													<li>
														Keep our records and your contact details up to date.
													</li>
													<li>
														Process and respond to any complaints, enquiries or feedback from you.
													</li>
													<li>
														Comply with any law, regulation, binding decision or direction of a regulator and cooperate with any governmental authority.
													</li>
													<li>
														that requires the provision of personal data to third party organisations and/or governmental institutions.
													</li>
												</ul>
												<p>
													We will not share, disclose or otherwise dispose of your personal data other than in accordance with the Clause 3.5
													<br />
													We may maintain the personal data upon expiry or early termination of services provided by Jenga Corp, if and to any extent necessary to enforce any of our outstanding rights hereunder or as required by applicable laws, whichever period is longer.
												</p>
											</td>
										</tr>
										<tr>
											<td valign="top">
												<h3>
													3.5 Disclosure of Personal Data
												</h3>
												<p>
													We may disclose your personal data to:
												</p>
												<ul>
													<li>
														Our affiliated companies or third parties service providers for the following purposes:
														<ul>
															<li>
																For the provision of services as set out in Clause 3.4 above.
															</li>
															<li>
																Operation of our website and businesses
															</li>
															<li>
																Fulfillment of yours and/or your entity's request.
															</li>
															<li>
																Processing and verification to enable us to comply with our internal policies.
															</li>
															<li>
																Provision of our service(s) to you and/or your entity.
															</li>
														</ul>
													</li>
													<li>
														The relevant entity's Registry for the purposes of us providing corporate secretarial services.
													</li>
													<li>
														Law enforcement agencies or regulatory authorities in Singapore or abroad, if we are required to do so by law.
													</li>
													<li>
														Relevant parties if it is necessary for legal proceedings.
													</li>
													<li>
														Any organisation for any authorised purpose with your express consent
													</li>
													<li>
														We may disclose your personal data to our affiliated companies or third-party providers abroad for some of the pourposes listed above. In these cases, the disclosure will be on a need to know basis.
													</li>
													<li>
														We will store your personal data on cloud servers and backed up to offline servers. The use of these cloud servers may result in your personal data being stored abroad. The recipients of the encrypted files may not reside in countries which have data protection laws comparable to Singapore and/or EU<span>'</span>s GDPR.
													</li>
												</ul>
												<p>
													Any legitimate interests pursued by us, or third parties we use, are as follows:
												</p>
												<ul>
													<li>
														Our legitimate interests bound by the legal standards applicable to Know-Your-Client ("KYC"), Anti-Money Laundering ("AML"), Anti-Terrorist Funding ("ATF") checks and any other checks or provision of personal data that is required by law in any jurisdiction that you open your entity in or has any jurisdiction that your entity may have operations in. This will apply to all third parties whose service we engage in for the performance of the contract with you.
													</li>
												</ul>
											</td>
										</tr>
										<tr>
											<td valign="top">
												<h3>
													3.6 Consent
												</h3>
												<p>
													By consenting to this privacy notice you are giving us permission to process your personal data specifically for the purposes identified.
													<br />
													Consent is required for us to process both types of personal data, but it must be explicitly given. Where we are asking you for sensitive personal data we will always tell you why and how the information will be used. 
												</p>
												<p>
													You may withdraw consent at any time by informing us via email.
												</p>
											</td>
										</tr>
										<tr>
											<td valign="top">
												<h3>
													3.7 Disclosure
												</h3>
												<p>
													We will pass on your personal data to third parties according to clause 3.
												</p>
											</td>
										</tr>
										<tr>
											<td valign="top">
												<h3>
													3.8 Retention period
												</h3>
												<p>
													We will process your personal data for the entire period of the engagement according to the contract entered into with us and will store the personal data for as long as the requirements of the various governmental organisations.
												</p>
											</td>
										</tr>
										<tr>
											<td valign="top">
												<h3>
													3.9 Your rights as a data subject
												</h3>
												<p>
													At any point while we are in possession of or processing your personal data, you, the data subject, have the following rights:
												</p>
												<ul>
													<li>
														Right of access – you have the right to request a copy of the information that we hold about you.
													</li>
													<li>
														Right of rectification – you have a right to correct data that we hold about you that is inaccurate or incomplete.
													</li>
													<li>
														Right to be forgotten – in certain circumstances you can ask for the data we hold about you to be erased from our records.
													</li>
													<li>
														Right to restriction of processing – where certain conditions apply to have a right to restrict the processing.
													</li>
													<li>
														Right of portability – you have the right to have the data we hold about you transferred to another organisation.
													</li>
													<li>
														Right to object – you have the right to object to certain types of processing such as direct marketing.
													</li>
													<li>
														Right to object to automated processing, including profiling – you also have the right to be subject to the legal effects of automated processing or profiling.
													</li>
													<li>
														Right to judicial review: in the event that we refuse your request under rights of access, we will provide you with a reason as to why. You have the right to complain as outlined in clause 3.6 below.
													</li>
												</ul>
												<p>
													All of the above requests will be forwarded on should there be a third party involved (as stated in 3.4 above) in the processing of your personal data.
												</p>
											</td>
										</tr>
										<tr>
											<td valign="top">
												<h3>
													3.10 Complaints
												</h3>
												<p>
													In the event that you wish to make a complaint about how your personal data is being processed by us (or third parties as described in Clause 3), or how your complaint has been handled, you have the right to lodge a complaint directly with the supervisory authority and our data protection representatives.
												</p>
												<table border="1" class="tablelarge">
													<thead>
														<tr>
															<th>
																&nbsp;
															</th>
															<th>
																Supervisory authority contact details
															</th>
															<th>
																DPO contact details
															</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																Contact Name:
															</td>
															<td>
																Jared Ng
															</td>
															<td>
																Jared Ng
															</td>
														</tr>
														<tr>
															<td>
																Address line 1:
															</td>
															<td>
																3 Fraser Street
															</td>
															<td>
																3 Fraser Street
															</td>
														</tr>
														<tr>
															<td>
																Address line 2:
															</td>
															<td>
																#05-21 Duo Tower
															</td>
															<td>
																#05-21 Duo Tower
															</td>
														</tr>
														<tr>
															<td>
																Address line 3:
															</td>
															<td>
																Singapore 189352
															</td>
															<td>
																Singapore 189352
															</td>
														</tr>
														<tr>
															<td>
																Address line 4:
															</td>
															<td>
																 
															</td>
															<td>
																 
															</td>
														</tr>
														<tr>
															<td>
																Address line 5:
															</td>
															<td>
																 
															</td>
															<td>
																 
															</td>
														</tr>
														<tr>
															<td>
																Email:
															</td>
															<td>
																jared@jenga.io
															</td>
															<td>
																jared@jenga.io
															</td>
														</tr>
														<tr>
															<td>
																Telephone:
															</td>
															<td>
																88824795
															</td>
															<td>
																88824795
															</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
										<tr>
											<td valign="top"><br />
												<h3>
													3.11 Privacy statement
												</h3>
												<p>
													Read more about how and why we use your data here <a href="/privacy-policy">Privacy Policy</a>. 
												</p>
											</td>
										</tr>
										<tr>
											
											<td valign="top">
												<h2>
													4. Online privacy statement
												</h2>
												<p>
													<u>
														Personal data
													</u>
													<br/>
													Under the EU’s General Data Protection Regulation (GDPR) personal data is defined as: <br/>
													“any information relating to an identified or identifiable natural person ('data subject'); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person”.
												</p>
												<p>
													<u>
														How we use your information
													</u>
													<br/>
													This privacy notice tells you how we will collect and use your personal data as set out in Clause 3 above.
												</p>
												<p>
													<u>
														Why do we need to collect and store personal data?
													</u>
													<br/>
													In order for us to provide you with access to our website and the services we provide, we need to collect personal data as set out in Clause 3. In any event, we are committed to ensuring that the information we collect and use is appropriate for this purpose, and does not constitute an invasion of your privacy. 
												</p>
												<p>
													In terms of being contacted for marketing purposes, agreeing to our terms and conditions upon entering our website would be considered your consent to be contacted for marketing purposes.
												</p>
												<p>
													<u>
														Will we share my personal data with anyone else?
													</u>
													<br/>
													We may pass your personal data on to third-party service providers contracted to us in the course of dealing with you. Any third parties that we may share your data with are obliged to keep your details securely, and to use them only to fulfil the service they provide you on our behalf. When they no longer need your data to fulfil this service, they will dispose of the details in line with the procedures of theirs and the requirements by various governmental authorities. If we wish to pass your sensitive personal data onto a third party we will only do so once we have obtained your consent, unless we are legally required to do otherwise.
												</p>
												<p>
													<u>
														How will we use the personal data it collects about you?
													</u>
													<br/>
													We will process (collect, store and use) the information you provide in a manner compatible with the EU’s General Data Protection Regulation (GDPR). We will endeavour to keep your information accurate and up to date, and not keep it for longer than is necessary. We required to retain information in accordance with the law, such as information needed for income tax and audit purposes. How long certain kinds of personal data should be kept may also be governed by specific business-sector requirements and agreed practices. Personal data may be held in addition to these periods depending on individual business needs.
												</p>
												<p>
													<u>
														Under what circumstances will we contact you?
													</u>
													<br/>
													Our aim is not to be intrusive, and we undertake not to ask irrelevant or unnecessary questions. Moreover, the information you provide will be subject to rigorous measures and procedures to minimise the risk of unauthorised access or disclosure.
												</p>
												<p>
													<u>
														Can I find out the personal data that the organisation holds about me?
													</u>
													<br/>
													We, at your request, can confirm what information we hold about you and how it is processed. If we do not hold personal data about you, you can request the following information: 
												</p>
												<ul>
													<li>
														Identity and the contact details of the person or organisation that has determined how and why to process your data. 
													</li>
													<li>
														Contact details of the data protection officer, where applicable.
													</li>
													<li>
														The purpose of the processing as well as the legal basis for processing.
													</li>
													<li>
														If the processing is based on the legitimate interests of ours or a third party, information about those interests.
													</li>
													<li>
														The categories of personal data collected, stored and processed.
													</li>
													<li>
														Recipient(s) or categories of recipients that the data is/will be disclosed to.
													</li>
													<li>
														If we intend to transfer the personal data to a third country or international organisation, information about how we ensure this is done securely. The EU has approved sending personal data to some countries because they meet a minimum standard of data protection. In other cases, we will ensure there are specific measures in place to secure your information.
													</li>
													<li>
														How long the data will be stored.
													</li>
													<li>
														Details of your rights to correct, erase, restrict or object to such processing.
													</li>
													<li>
														Information about your right to withdraw consent at any time.
													</li>
													<li>
														How to lodge a complaint with the supervisory authority.
													</li>
													<li>
														Whether the provision of personal data is a statutory or contractual requirement, or a requirement necessary to enter into a contract, as well as whether you are obliged to provide the personal data and the possible consequences of failing to provide such data.
													</li>
													<li>
														The source of personal data if it wasn’t collected directly from you.
													</li>
													<li>
														Any details and information of automated decision making, such as profiling, and any meaningful information about the logic involved, as well as the significance and expected consequences of such processing.
													</li>
												</ul>
												<p>
													<u>
														What forms of ID will I need to provide in order to access this? 
													</u>
													<br/>
													We accept the following forms of ID when information on your personal data is requested:
													<br />
													Passport, driving licence, birth certificate, utility bill (from last 3 months), Identification Card, Email Verification, Phone Verification.
												</p>
												<p>
													<u>
														Contact details of the [Data Protection Officer (DPO)] / [GDPR Owner]:
													</u>
												</p>
												<table border="1">
													<tr>
														<td>&nbsp;</td>
														<td>
															<b>
																DPO contact details
															</b>
														</td>
													</tr>
													<tr>
														<td>
															Contact Name:
														</td>
														<td>
															Jared Ng
														</td>
													</tr>
													<tr>
														<td>
															Address line 1:
														</td>
														<td>
															3 Fraser Street
														</td>
													</tr>
													<tr>
														<td>
															Address line 2:
														</td>
														<td>
															#05-21 Duo Tower
														</td>
													</tr>
													<tr>
														<td>
															Address line 3:
														</td>
														<td>
															Singapore 189352
														</td>
													</tr>
													<tr>
														<td>
															Address line 4:
														</td>
														<td>
															 
														</td>
													</tr>
													<tr>
														<td>
															Address line 5:
														</td>
														<td>
															 
														</td>
													</tr>
													<tr>
														<td>
															Email:
														</td>
														<td>
															jared@jenga.io
														</td>
													</tr>
													<tr>
														<td>
															Telephone:
														</td>
														<td>
															88824795
														</td>
													</tr>
												</table>
												<br />
												<p>
													Document Owner and Approval<br />
													The DPO is the owner of this document and is responsible for ensuring that this record is reviewed in line with the review requirements fo GDPR<br />
												
													A current version of this document is available to all members of staff in our organisation.
												</p>
												<div>Change History Record</div>
												<table border="1">
													<tr>
														<td>Issue</td>
														<td>Description of Change</td>
														<td>Approval</td>
														<td>Date of Issue</td>
													</tr>
													<tr>
														<td>1</td>
														<td>Initial issue</td>
														<td>Jared </td>
														<td>26 July 2019</td>
													</tr>
													<tr>
														<td>&nbsp;</td>
														<td>&nbsp;</td>
														<td>&nbsp;</td>
														<td>&nbsp;</td>
													</tr>
													<tr>
														<td>&nbsp;</td>
														<td>&nbsp;</td>
														<td>&nbsp;</td>
														<td>&nbsp;</td>
													</tr>
													<tr>
														<td>&nbsp;</td>
														<td>&nbsp;</td>
														<td>&nbsp;</td>
														<td>&nbsp;</td>
													</tr>
												</table>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
	}
}

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy)