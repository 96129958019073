import { ADD_CART_ITEM, REMOVE_CART_ITEM, LOAD_CART, CHANGE_QUANTITY , APPLY_COUPON, EMPTY_CART } from '../actions/OrderCartActions'
import merge from 'merge'

const initialState = {
	orderItems: [],
	subTotal: 0,
	coupon: "",
	couponData: {}
};

let orderItems, subTotal, foundItem, discountPrice;

export default function(state = initialState, action) {
	switch (action.type) {

		case CHANGE_QUANTITY:

			const currentOItemToChange = action.payload.orderItem;

			foundItem = state.orderItems.find((item) => item.product_id == currentOItemToChange.product_id)

			if( foundItem && ( (foundItem.itemType == "required" && action.payload.quantity >= 1) || foundItem.itemType != "required" ) ) {

				foundItem.quantity = action.payload.quantity;

				if( foundItem.quantity <= 0 ) {
					state.orderItems.splice( state.orderItems.indexOf(foundItem), 1 )
				}

			}

			subTotal = state.orderItems.reduce((sum, _orderItem) => sum+(_orderItem.price*_orderItem.quantity), 0)

			if( state.coupon != "" ) {
				
				if( state.couponData.discountType == 'Percentage' ) {
					discountPrice = subTotal - ( subTotal * (parseFloat(state.couponData.discountValue)/100) )
				} else {
					discountPrice = subTotal - parseFloat(state.couponData.discountValue)
				}
				
				if( discountPrice < 0 )
					discountPrice = 0;

				subTotal = discountPrice;

			}

			return {...state, subTotal};

		break;

		case LOAD_CART:

			orderItems = action.payload.orderItems;
			state.coupon = action.payload.coupon;
			state.couponData = action.payload.couponData;

			console.log(action.payload, "CHECKING ORDER ITEMS");
			
			subTotal = orderItems.reduce((sum, _orderItem) => sum+(_orderItem.price*_orderItem.quantity), 0)

			if( state.coupon != "" ) {
				
				if( state.couponData.discountType == 'Percentage' ) {
					discountPrice = subTotal - ( subTotal * (parseFloat(state.couponData.discountValue)/100) )
				} else {
					discountPrice = subTotal - parseFloat(state.couponData.discountValue)
				}
				
				if( discountPrice < 0 )
					discountPrice = 0;

				subTotal = discountPrice;

			}

			return {...state,orderItems, subTotal};

		break;

		case EMPTY_CART:

			state.orderItems = []
			state.subTotal = 0

			return {...state};

		break;

		case ADD_CART_ITEM:

			const itemToAdd = {
				product_id: 0,
				variant_id: 0,
				title: "",
				chinese_title: "",
				itemType: "",
				price: "",
				currency: "",
				quantity: 1
			};

			const currentItemToAdd = action.payload.orderItem;

			foundItem = state.orderItems.find((item) => item.product_id == currentItemToAdd.product_id)

			if( foundItem )
				return state;

			itemToAdd.product_id = currentItemToAdd.product_id;
			itemToAdd.variant_id = currentItemToAdd.variation_id;
			itemToAdd.title = currentItemToAdd.title;
			itemToAdd.chinese_title = currentItemToAdd.chinese_title;
			itemToAdd.itemType = currentItemToAdd.itemType;
			itemToAdd.price = currentItemToAdd.price;
			itemToAdd.currency = currentItemToAdd.currency;

			orderItems = state.orderItems;
			orderItems.push(itemToAdd);

			subTotal = orderItems.reduce((sum, _orderItem) => sum+(_orderItem.price*_orderItem.quantity), 0)

			if( state.coupon != "" ) {
				
				if( state.couponData.discountType == 'Percentage' ) {
					discountPrice = subTotal - ( subTotal * (parseFloat(state.couponData.discountValue)/100) )
				} else {
					discountPrice = subTotal - parseFloat(state.couponData.discountValue)
				}
				
				if( discountPrice < 0 )
					discountPrice = 0;

				subTotal = discountPrice;

			}

			return {
				...state,
				orderItems,
				subTotal
			};

		break;

		case APPLY_COUPON:

			orderItems = state.orderItems;

			subTotal = orderItems.reduce((sum, _orderItem) => sum+(_orderItem.price*_orderItem.quantity), 0)

			if( action.payload.coupon != "" ) {
				
				if( action.payload.discountType == 'Percentage' ) {
					discountPrice = subTotal - ( subTotal * (parseFloat(action.payload.discountValue)/100) )
				} else {
					discountPrice = subTotal - parseFloat(action.payload.discountValue)
				}
				
				if( discountPrice < 0 )
					discountPrice = 0;

				subTotal = discountPrice;

			}

			return {
				...state,
				coupon: action.payload.couponCode ? action.payload.couponCode : '',
				orderItems,
				subTotal,
				couponData: {
					couponCode: action.payload.couponCode,
					discountValue: action.payload.discountValue,
					discountType: action.payload.discountType
				}
			};

		break;

		case REMOVE_CART_ITEM:

			const currentItem = action.payload.orderItem;

			orderItems = state.orderItems;

			const foundOrderItem = orderItems.find((_item) => _item.product_id == currentItem.product_id)
			
			if( foundOrderItem )
				orderItems.splice(orderItems.indexOf(foundOrderItem), 1)

			subTotal = orderItems.reduce((sum, _orderItem) => sum+(_orderItem.price*_orderItem.quantity), 0)

			if( state.coupon != "" ) {
				
				if( state.couponData.discountType == 'Percentage' ) {
					discountPrice = subTotal - ( subTotal * (parseFloat(state.couponData.discountValue)/100) )
				} else {
					discountPrice = subTotal - parseFloat(state.couponData.discountValue)
				}
				
				if( discountPrice < 0 )
					discountPrice = 0;

				subTotal = discountPrice;

			}

			return {
				...state,
				orderItems,
				subTotal
			};

		break;

		default:
			return state;

	}
}
