import React, { Component } from 'react'

import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import OrderFormItem from './OrderFormItem'

class OrderFormListing extends Component {

	constructor(props) {

		super(props)

		this.assertTitle = this.assertTitle.bind(this)
		this.assertDescription = this.assertDescription.bind(this)

	}

	assertTitle(currentItem) {

		return this.props.currentLang == 'en' ? currentItem.title : currentItem.field_chinese_title

	}

	assertDescription(currentItem) {

		return this.props.currentLang == 'en' ? currentItem.body : currentItem.field_chinese_description

	}

	render() {

		if (!this.props.OrderForm.hasOwnProperty('formData'))
			return null;

		const formData = this.props.OrderForm.formData[0];
		const orderForm = this.props.OrderForm.orderForm

		const requiredItems = orderForm.required.map((currentItem, currentIndex) => {
			return (
				<OrderFormItem currentItem={currentItem} currentIndex={currentIndex} itemType={"required"} />
			)
		});

		const recommendedItems = orderForm.recommended.map((currentItem, currentIndex) => {
			return (
				<OrderFormItem currentItem={currentItem} currentIndex={currentIndex} itemType={"recommended"} />
			)
		});

		const addonItems = orderForm.addon.map((currentItem, currentIndex) => {
			return (
				<OrderFormItem currentItem={currentItem} currentIndex={currentIndex} itemType={"addon"} />
			)
		});

		const maintenanceItems = orderForm.maintenance.map((currentItem, currentIndex) => {
			return (
				<OrderFormItem currentItem={currentItem} currentIndex={currentIndex} itemType={"maintenance"} />
			)
		});

		return (
			<div className="middleContent">
				<div className="heading">
					{this.assertTitle(formData)}
				</div>
				<div className="heading_content" dangerouslySetInnerHTML={{ __html: this.assertDescription(formData) }}></div>

				<ul className="required-items">
					{
						requiredItems.length > 0 ? (
							<li className="title-top">
								{this.props.currentLang == 'en' ? (
									<span className="small-title">
										{
											orderForm.changeRequiredTitle ? orderForm.customRequiredTitle : 'Required Items'
										}
									</span>
								) : (
										<span className="small-title">
											{
												orderForm.changeRequiredTitle ? orderForm.cn_customRequiredTitle : '注册必备项目'
											}
										</span>
									)}
								<FormattedMessage id="Price(USD)" defaultMessage="Price(USD)"></FormattedMessage>
							</li>
						) : ''
					}
					{requiredItems}
					{recommendedItems}
					{addonItems}
					{maintenanceItems}
				</ul>
			</div>
		)

	}
}

const mapStateToProps = (state) => {
	return {
		OrderForm: state.OrderForm,
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(OrderFormListing)