import React, { Component } from 'react'
import { findDOMNode } from 'react-dom';
import { connect }  from 'react-redux'

import jQuery from 'jquery'

import AcknowledgmentSubEntry from './AcknowledgmentSubEntry'

class AcknowledgmentEntry extends Component {

	constructor(props) {

		super(props)

		this.state = {
			hideChildNodes: true
		}

		this.toggleChildNodes = this.toggleChildNodes.bind(this)

	}

	toggleChildNodes() {

		const currentState = !this.state.hideChildNodes;

		this.setState({
			hideChildNodes: currentState
		}, (state) => {
			
			const acknowledgeItem = jQuery(findDOMNode(this)).find(".dropdowns")

			if( currentState ) {
				acknowledgeItem.stop( true, true ).slideUp('fast')
			} else {
				acknowledgeItem.stop( true, true ).slideDown('fast')
			}

		})

	}

	render() {
		
		const entryTitle = this.props.entry.field_entry_document.length ? (
			<a href={"//api.jengacorp.com" + this.props.entry.field_entry_document} target="_blank">
				{this.props.currentLang == 'en'?this.props.entry.title:this.props.entry.field_title_cn_entity}
			</a>
		) : this.props.currentLang == 'en'?this.props.entry.title:this.props.entry.field_title_cn_entity;

		const subEntries = this.props.entry.view.map((v) => {
			return (
				<AcknowledgmentSubEntry subEntry={v} />
			)
		});

		let subEntriesList = '';

		const extraProps = {
			onClick: () => {},
			style: {}
		};

		if( subEntries.length > 0 ) {

			subEntriesList = (
				
				<ul class="dropdowns" style={{ display: "none" }}>
					{subEntries}
				</ul>
			);

			extraProps.style = { "cursor": "pointer" };

			extraProps.onClick = this.toggleChildNodes

		}

		return (
			<li>
				<span className="acknowledge-item-parent" {...extraProps}>
					<span class="plus"><i class="fa fa-plus"></i></span>
					{entryTitle}
				</span>
				{subEntriesList}
			</li>
		);
	}

}

// export default AcknowledgmentEntry
const mapStateToProps = (state) => {
	return {
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(AcknowledgmentEntry)