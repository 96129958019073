import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

export default class HeadBanner extends Component {
	render() {
		return (
			<div className="banner-row">
				<div className="container">
					<div className="row">
						<div className="col-md-9">
							<div className="incorp">
								<h1 className="large-heading" style={{fontFamily:"'Montserrat-Bold','华文细黑'"}}>
									<FormattedMessage id={this.props.title} defaultMessage={this.props.title}></FormattedMessage>
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}