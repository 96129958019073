export default {
  "before expiry": "到期前",
  "per filling": "每次",
  "person/time": "人/次",
  "per year": "每年",
  "per UEN": "每个公司",
  "per transfer": "每次",
  "per transaction": "每次",
  "per time": "每次",
  "per set": "每套",
  "per person per month": "每人每月",
  "per person": "每人",
  "per month": "每月",
  "per item": "每项",
  "per each account": "每户",
  "each": "每个",
  "per company": "每户",
  "per class": "每类",
  "per change": "每次",
  "per application": "每次",
  "per allotment": "每次",
  "per additional transaction": "每笔额外交易",
  "per additional person": "每增加一个人",
  "one-time": "一次性",
  "one-off payment": "一次性付款",
  "hourly": "每小时",
  "first transaction": "第一次交易",
  "first person": "第一个人",
  "Please Contact Us": "请联系我们",
  "below 3 item": "小于3项"
  }
