import React, { Component } from 'react'
import { Redirect } from 'react-router'

import { connect }  from 'react-redux'

import Header from '../partials/Header'
import CheckoutBanner from '../partials/CheckoutBanner'
import BreadCrumbs from '../partials/BreadCrumbs'

import Footer from '../partials/Footer'

import BillingDetails from '../components/BillingDetails'
import OrderDetails from '../components/OrderDetails'
import CheckoutPaymentMethod from '../components/CheckoutPaymentMethod'


import OrderCartService from '../services/OrderCartService';

import OrderFormService from '../services/OrderFormService';
import { fetchOrderForm } from '../actions/OrderFormActions';

import { setCompanyName } from '../actions/OrderCheckoutActions';

import AcraService from '../services/AcraService';

import { loadCart } from '../actions/OrderCartActions';


//import { addOrderItem, removeOrderItem } from '../actions/OrderCartActions'

class Order extends Component {

	constructor(props) {
		
		super(props)

		this.state = {
			orderItemsLoaded: false,
			isCheckoutAllowed: true
		}

		this.orderCartService = new OrderCartService();
		this.orderFormService = new OrderFormService();
		this.acraService = new AcraService();

	}

	componentWillMount() {

		const self = this;

		this.orderCartService.getCart().then((response) => {

			self.props.loadCart(response);

			self.orderFormService.fetchOrderForm(response.order_form).then((formResponse) => {
				self.props.fetchOrderForm(formResponse)

				self.acraService.getBizfileName().then((response2) => {
			
					if( response2.companyName )
						self.props.setCompanyName( response2.companyName )

				})

			})

		});
		
	}

	render() {
		
		const orderItemsLoaded = this.state.orderItemsLoaded;
		
		return (
			<div>
				{ !this.state.isCheckoutAllowed ? ( <Redirect to="/" push /> ) : "" }
				<Header />
				<CheckoutBanner />
				<BreadCrumbs pages={ [
					{
						title: "Home",
						link: "/"
					},
					{
						title: "Place Order",
						link: "/order-form/" + this.props.orderForm.formId
					},
					{
						title: "Checkout"
					}
				] } />
				<section class="outer bg-white gray gray-shape">
					<div class="container small-container">
						<div class="row">
							<div class="col-md-5 pt-4">
								<BillingDetails />
							</div>
							<div class="col-md-7 pt-4">
								<OrderDetails />
							</div>
						</div>
						<div class="row mt-4">
							<div class="col-md-12">
								<CheckoutPaymentMethod />
							</div>
						</div>
					</div>
				</section>
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		orderForm: state.OrderForm.orderForm,
		cartItems: state.OrderCart.orderItems,
		subTotal: state.OrderCart.subTotal,
		coupon: state.OrderCart.coupon
	}
}

const mapDispatchToProps = {
	loadCart: (cartData) => (dispatch) => {
		dispatch(loadCart(cartData))
	},
	fetchOrderForm: (formData) => (dispatch) => {
		dispatch(fetchOrderForm(formData))
	},
	setCompanyName: (companyName) => (dispatch) => {
		dispatch(setCompanyName(companyName))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Order)