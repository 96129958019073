import rootReducer from './reducers';
import { compose, applyMiddleware, createStore, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { updateIntl } from 'react-intl-redux'

import { UPDATE_LOCALES, CHANGE_LANGAUGE } from './actions/IntlActions'

import messages_en from './translations/en.json'
import messages_zh from './translations/zh.json'

const initialState = {
	intl: {
		defaultLocale: 'en',
		locale: 'en',
		messages: {}
	}
}

const initialLocalState = {
	locales: {
		en: messages_en,
		zh: messages_zh
	},
	currentLang: null
};

const localReducer = function(state = initialLocalState, action) {
	switch (action.type) {
		case UPDATE_LOCALES:
			return {
				...state,
				...action.payload,
			}
		break;
		case CHANGE_LANGAUGE:

			if( state.currentLang == action.payload.language )
				return state;

			if( !state.locales.hasOwnProperty(action.payload.language) )
				return state;

			return {
				...state,
				currentLang: action.payload.language
			};

		break;
		default:
			return state;
	}
}

rootReducer.locales = localReducer;

/*

this.state = {
	
}

*/

const middlewares = [thunk];

//window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : null

let store;

if( window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ) {
	store = createStore(combineReducers(rootReducer),initialState, compose(
		applyMiddleware(...middlewares),
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
	));
} else {
	store = createStore(combineReducers(rootReducer),initialState, compose(
		applyMiddleware(...middlewares)
	));
}

export default store