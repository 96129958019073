import BaseService from './BaseService'

export default class AcknowledgmentService extends BaseService {

	getAcknowledgmentSections() {
		return this.apiCall(
			`acknowledgment-sections?_format=json`,
			'GET',
			{},
			true
		).then((response) => {

			return response.data;
			
		})
	}

}