import React, { Component } from 'react'

export default class NormalMessage extends Component {

	render() {

		if( this.props.message === '' )
			return '';

		let classes = 'normal-message ';

		if( this.props.success === false )
			classes += 'error'
		else
			classes += 'success'

		return (
			<div className={classes}>
				{this.props.message}
			</div>
		)

	}

}