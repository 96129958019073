import React, { Component } from 'react'
import { Redirect } from 'react-router'

import { connect } from 'react-redux'

import { FormattedNumber, FormattedMessage } from 'react-intl'

import { changeQuantity, applyCoupon } from '../actions/OrderCartActions';

import '../css/components/OrderCart.css';

import OrderCartService from '../services/OrderCartService';
import OrderFormService from '../services/OrderFormService';

class OrderCart extends Component {

	constructor(props) {

		super(props)
		this.state = {
			termsAccepted: false,
			showError: false,
			checkoutProcessing: false,
			redirectToCheckout: false,
			showCouponError: '',
			couponProcessing: false,
			cartItemsQuantities: []
		};

		this.toggleAccept = this.toggleAccept.bind(this)
		this.handleCheckout = this.handleCheckout.bind(this)
		this.assertTitle = this.assertTitle.bind(this)
		this.onInputChange = this.onInputChange.bind(this)
		this.handleCouponCheck = this.handleCouponCheck.bind(this)

		this.orderCartService = new OrderCartService();
		this.orderFormService = new OrderFormService();

	}

	toggleAccept() {
		if( !this.state.termsAccepted )
			this.setState({ termsAccepted: true })
		else
			this.setState({ termsAccepted: false })
	}

	saveCartAndSubmit() {

		const payload = {
			orderItems: {},
			subTotal: 0,
			order_form: 0,
			coupon: "",
			couponData: {}
		};

		payload.orderItems = this.props.cartItems;
		payload.subTotal = this.props.subTotal;
		payload.order_form = this.props.orderForm.formId;
		payload.couponData = this.props.couponData;
		payload.coupon = this.props.coupon;

		const self = this;

		this.orderCartService.saveCart(payload).then((response) => {
			self.setState({
				redirectToCheckout: true
			});
		});

	}

	handleCouponCheck(e) {

		e.preventDefault();

		if( this.state.coupon == '' )
			return false;

		this.setState({
			showCouponError: '',
			couponProcessing: true
		})

		this.orderFormService.checkCoupon(this.state.coupon).then((response) => {
			this.setState({
				showCouponError: response.check ? '' : 'Coupon Code is invalid',
				couponProcessing: false
			}, () => {
				if( this.state.showCouponError == '' )
					this.props.applyCoupon(response)
			})
		})

	}

	handleCheckout() {

		if( this.state.showError == true )
			this.setState({showError: false});
		
		if( !this.state.termsAccepted ) {
			this.setState({
				showError: true
			})
			return false;
		}

		this.setState({ checkoutProcessing: true });

		this.saveCartAndSubmit();

	}

	assertTitle(cartItem) {

		return this.props.currentLang == 'en' ? cartItem.title : cartItem.chinese_title

	}

	onInputChange(e) {

		const self = this;

		this.setState({
			[e.target.name]: e.target.value
		})

	}

	render() {

		const cartItems = this.props.cartItems.map((cartItem) => {

			this.state.cartItemsQuantities[cartItem.product_id] = cartItem.quantity;

			return (
				<li className="orderdesc">
				    <div className="cart-info">
					<span>{this.assertTitle(cartItem)}</span>
					<span className="order-price">
						$<FormattedNumber value={this.state.cartItemsQuantities[cartItem.product_id]*cartItem.price}></FormattedNumber>
					</span>
					</div>
					<div className="quantity">
						<button className="minus" onClick={(e) => {
							if( this.state.cartItemsQuantities[cartItem.product_id] > 1 ) {
								
								const newQty = this.state.cartItemsQuantities[cartItem.product_id] - 1

								this.props.changeQuantity({ orderItem: cartItem, quantity: newQty })
								this.setState({
									cartItemsQuantities: this.state.cartItemsQuantities
								});
								this.props.changeQuantity({ orderItem: cartItem, quantity: newQty })

							}
						}}>-</button>
						<input className="qty" type="number" value={ this.state.cartItemsQuantities[cartItem.product_id] } min="1" onChange={ (e) => {
							this.state.cartItemsQuantities[cartItem.product_id] = e.target.value;
							this.setState({
								cartItemsQuantities: this.state.cartItemsQuantities
							});
							this.props.changeQuantity({ orderItem: cartItem, quantity: e.target.value })
						}} />
						<button className="plus" onClick={(e) => {
							
							const newQty = this.state.cartItemsQuantities[cartItem.product_id] + 1

							this.props.changeQuantity({ orderItem: cartItem, quantity: newQty })
							this.setState({
								cartItemsQuantities: this.state.cartItemsQuantities
							});
							this.props.changeQuantity({ orderItem: cartItem, quantity: newQty })

						}}>+</button>
					</div>
				</li>
			);

		})

		const checkoutError = (
			<div className="form-error">
				{this.props.currentLang == 'en'?'Please accept terms to checkout':'请勾选付款条件'}
			</div>
		);

		const couponError = (
			<div className="form-error" style={ { margin: "5px 0" } }>
				{this.state.showCouponError}
			</div>
		);

		const couponProcessingProps = {
			disabled: this.state.couponProcessing
		}

		return (
			<div className="order-box">
				<h4><FormattedMessage id="Your Order" defaultMessage="Your Order"></FormattedMessage> <i className="fas"><img src="/images/cart.png" alt="cart" /></i></h4>
				<ul className="order-list">
					{ cartItems }
				</ul>
				<div className="coupon">
					{
						!this.props.couponData.couponCode ?
						(
							<form onSubmit={this.handleCouponCheck}>
								<div className="coupon-wrap">
									<input type="text" placeholder={this.props.currentLang == 'en'?'Coupon Code':'折扣代码'} name="coupon" onChange={this.onInputChange} />
									<input type="submit" value={this.props.currentLang == 'en'?'Apply':'申请'} {...couponProcessingProps} />
								</div>
								{ this.state.showCouponError ? couponError : '' }
							</form>
						) : (
							<div>
								<span>
									Coupon:
								</span>
								<span>
									{this.props.couponData.couponCode}
								</span>
							</div>
						)
					}
				</div>
				<ul className="order-list">
					{
						this.props.orderForm.formId === 54 || this.props.orderForm.formId === 53 ||this.props.orderForm.formId === 52 ||this.props.orderForm.formId === 62 ? '' :(
							<li>
								<span><strong><FormattedMessage id="GST 9%" defaultMessage="GST 9%"></FormattedMessage>:</strong></span><span className="order-price red">
									$<FormattedNumber  value={Math.floor(this.props.subTotal*0.09*100)/100}></FormattedNumber>
								</span>
							</li>
						)
					}
					<li>
						<span><strong><FormattedMessage id="Subtotal(USD)" defaultMessage="Subtotal(USD)"></FormattedMessage>:</strong></span><span className="order-price red">
							$
							{/* <FormattedNumber  value={this.props.subTotal + (Math.floor(this.props.subTotal*0.07*100)/100) }></FormattedNumber> */}
							{
								this.props.orderForm.formId === 54 || this.props.orderForm.formId === 53 ||this.props.orderForm.formId === 52 ||this.props.orderForm.formId === 62 ? (<FormattedNumber  value={this.props.subTotal }></FormattedNumber>) : 
								(<FormattedNumber  value={this.props.subTotal + (Math.floor(this.props.subTotal*0.09*100)/100) }></FormattedNumber>)
							}
						</span>
					</li>
				</ul>
				{ this.state.showError ? checkoutError : '' }
				<button className="checkout" disabled={this.state.checkoutProcessing} onClick={this.handleCheckout}><FormattedMessage id="CHECKOUT" defaultMessage="CHECKOUT"></FormattedMessage></button>
				<div className="terms">
					<label>
						{/* <span className="custom-checkbox">
							<input type="checkbox" checked={this.state.termsAccepted} onChange={this.toggleAccept} />
							<span className="check"></span>
						</span> */}
						<div className="mycheckbox" checked={this.state.termsAccepted} onClick={this.toggleAccept}>
							<div className={this.state.termsAccepted?"mycheckboxActive":""}></div>
						</div>
						<FormattedMessage id="Tick this checkbox to accept our" defaultMessage="Tick this checkbox to accept our"></FormattedMessage>
						  <a href="/terms" className="red"><FormattedMessage id="terms and conditions" defaultMessage="terms and conditions"></FormattedMessage></a>.
					</label>
				</div>
				{ this.state.redirectToCheckout ? (<Redirect to="/order/checkout" push />) : "" }
			</div>
		)

	}

}

const mapStateToProps = (state) => {
	return {
		cartItems: state.OrderCart.orderItems,
		subTotal: state.OrderCart.subTotal,
		orderForm: state.OrderForm.orderForm,
		couponData: state.OrderCart.couponData,
		coupon: state.OrderCart.coupon,
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	changeQuantity: ({ orderItem, quantity }) => (dispatch) => {
		dispatch(changeQuantity({ orderItem, quantity }))
	},
	applyCoupon: (couponData) => (dispatch) => {
		dispatch(applyCoupon(couponData))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCart)