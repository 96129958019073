import React, { Component, Fragment } from 'react'

import { Link } from "react-router-dom"

import { connect } from 'react-redux'
import { FormattedNumber, FormattedMessage } from 'react-intl'

import { addOrderItem, removeOrderItem } from '../actions/OrderCartActions'

import paymentTypeAll from '../constants/payment_type'

class ChildFormItem extends Component {

	constructor(props) {

		super(props)
		
		this.toggleCartItem = this.toggleCartItem.bind(this)
		this.state = {
			isChecked: false
		}

		this.assertTitle = this.assertTitle.bind(this)
		this.assertDescription = this.assertDescription.bind(this)
		this.assertTitle_prefix = this.assertTitle_prefix.bind(this)
		this.assertPrice = this.assertPrice.bind(this)

	}

	assertTitle(currentItem) {

		// return this.props.currentLang == 'en' ? currentItem.title : currentItem.chinese_title
		if(this.props.currentLang == 'en'){
			return currentItem.title
		} else {
			let title_prefix_required = '';
			if(currentItem.title_prefix_required){
				title_prefix_required = '(' + currentItem.title_prefix_required +')'
			}
			return (
			<span><strong class='chinese_title_prefix'>{title_prefix_required}</strong>{currentItem.chinese_title}</span>
			);
		}

	}

	assertDescription(currentItem) {

		return this.props.currentLang == 'en' ? currentItem.description : currentItem.chinese_description

	}

	assertTitle_prefix(currentItem) {

		// return this.props.currentLang == 'en' ? currentItem.title_prefix : currentItem.chinese_title_prefix
		if(this.props.currentLang == 'en'){
			return currentItem.title_prefix + ': ';
		} else {
			let cn_child_prefix_type = '';
			let chinese_title_prefix = '';
			if(currentItem.cn_child_prefix_type){
				cn_child_prefix_type = currentItem.cn_child_prefix_type;
			}
			if(currentItem.chinese_title_prefix){
				chinese_title_prefix = currentItem.chinese_title_prefix + ': ';
			}
			return (
				`<span>${cn_child_prefix_type}<span class='chinese_title_prefix'>${chinese_title_prefix}</span></span>`
			)
		}

	}

	toggleCartItem(event) {

		const currentItem = this.props.currentItem
		currentItem.itemType = "child"

		if( event.target.checked ) {
			this.props.addToCart(currentItem, this.props.parentItem)
		} else {
			this.props.removeFromCart(currentItem, this.props.parentItem)
		}

		this.setState({
			isChecked: event.target.checked
		});

	}

	// showPaymentType(currentItem) {
	// 	let innerContent = '';
	// 	return currentItem.payment_type == '' ? '' : <span>(
	// 		{
	// 			~currentItem.payment_type.indexOf('Contact') ? (
	// 				<Fragment>
	// 					{currentItem.payment_type.replace(/(Contact Us)/g,'')}
	// 					<Link to={'/contact'}>Contact Us</Link>
	// 				</Fragment>
	// 			) : currentItem.payment_type
	// 		}
	// 	)</span>
	// }
	showPaymentType(currentItem) {
		let innerContent = '';
		let payment_type = currentItem.payment_type;
		if(this.props.currentLang == 'en'){
			if(payment_type == ''){
				return '';
			}else{
				return <span>(
					{
						~payment_type.indexOf('Contact') ? (
							<Fragment>
								{payment_type.replace(/(Contact Us)/g,'')}
								<Link to={'/contact'}>Contact Us</Link>
							</Fragment>
						) : payment_type
					}
					)</span>;
			}
		}else{
			if(paymentTypeAll[payment_type]){
				let cn_txt = paymentTypeAll[payment_type];
				if(payment_type == 'Please Contact Us'){
					return <Fragment>请<Link to={'/contact'}>联系我们</Link>获得报价。</Fragment>
				}else{
					return '(' + cn_txt + ')';
				}
			}else{
				return ''
			}
		}
	}

	assertPrice(currentItem){
		if(currentItem.price > 0){
			return (
				<span className="price">
					$<FormattedNumber minimumFractionDigits={0} value={currentItem.price}></FormattedNumber>
					{this.props.currentLang == 'en'?'':currentItem.pay_after}
				</span>
			)
		}else if(currentItem.isFree){
			return (
				<span className="price">
					<FormattedMessage id="Free" defaultMessage="Free"></FormattedMessage>
				</span>
			)
		}else{
			return ''
		}
	}

	render() {

		const currentItem = this.props.currentItem

		if( this.props.existInCart( currentItem.product_id, this.props.cart ) && this.state.isChecked != true ) {
			this.setState({
				isChecked: true
			})
		}

		return (
			<Fragment>
				<div className={"d-flex row mt-2 justify-content-between child_product_top child-product-" + currentItem.product_id + " " + currentItem.itemClass }>
					<div className="col-sm-8">
						{
							(currentItem.price > 0 || currentItem.isFree) && !currentItem.checkboxHidden ? (
								<label className="custom-checkbox">
									<input type="checkbox" onChange={this.toggleCartItem} checked={this.state.isChecked} />
									<span className="check"></span>
								</label>
							) : ''
						}
						{
							currentItem.title_prefix == ''
							? (<p>{this.assertTitle(currentItem)} {this.showPaymentType(currentItem)} </p>)
							: (
								<p>
									<strong dangerouslySetInnerHTML={{ __html: this.assertTitle_prefix(currentItem) }}>
										{/* {this.assertTitle_prefix(currentItem)}: &nbsp; */}
										{/* {currentItem.title_prefix}: &nbsp; */}
									</strong>
									{this.assertTitle(currentItem)} <span>{this.showPaymentType(currentItem)}
										{/* ({~currentItem.payment_type.indexOf('Contact') ? (
											<Link to={'/contact'}>{currentItem.payment_type}</Link>
										) : currentItem.payment_type}) */}
										</span>
								</p>
							)
						}
						<p dangerouslySetInnerHTML={{ __html: this.assertDescription(currentItem) }}></p>
					</div>
					<div className="col-sm-3">
						{this.assertPrice(currentItem)}
						{/* {
							currentItem.price > 0 ? (
								<span className="price">
									$<FormattedNumber minimumFractionDigits={0} value={currentItem.price}></FormattedNumber>
									{this.props.currentLang == 'en'?'':currentItem.pay_after}
								</span>
							) : (
								currentItem.isFree ? (
									<span className="price">
										<FormattedMessage id="Free" defaultMessage="Free"></FormattedMessage>
									</span>
								) : ''
							)
						} */}
					</div>
				</div>
				{ currentItem.requiredAfter ? (
					<li className="title-top">
						<span className="small-title">
						<FormattedMessage id="Required Items" defaultMessage="Required Items"></FormattedMessage>
						</span>
						<FormattedMessage id="Price(USD)" defaultMessage="Price(USD)"></FormattedMessage>
					</li>
				) : '' }
			</Fragment>
		)

	}

}

const mapStateToProps = (state) => {
	return {
		cart: state.OrderCart.orderItems,
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	existInCart: (itemId, cart) => (dispatch) => {
		const found = cart.find((item) => item.product_id == itemId)
		if( found )
			return true;
		return false;
	},
	addToCart: (orderItem,parentItem) => (dispatch) => {
		dispatch(addOrderItem({orderItem, parentItem}))
	},
	removeFromCart: (orderItem,parentItem) => (dispatch) => {
		dispatch(removeOrderItem({orderItem, parentItem}))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildFormItem)