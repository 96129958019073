import React, { Component } from 'react'

import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import { updateCheckout } from '../actions/OrderCheckoutActions';

class BillingDetails extends Component {

	constructor(props) {

		super(props)

		this.state = {
			clientName: '',
			requestedCompanyName: '',
			emailAddress: '',
			createAnAccount: false,
			orderNotes: ''
		}

		this.onInputChange = this.onInputChange.bind(this)
		this.onCheckboxChange = this.onCheckboxChange.bind(this)
		//this.onTextAreaChange = this.onTextAreaChange.bind(this)

	}

	onInputChange(e) {

		const self = this;

		this.setState({
			[e.target.name]: e.target.value
		}, () => {
			self.updateCheckoutState()
		})

	}

	onCheckboxChange(e) {

		const self = this;

		this.setState({
			[e.target.name]: e.target.checked
		}, () => {
			self.updateCheckoutState()
		})

	}

	/*onTextAreaChange(e) {

		this.setState({
			[e.target.name]: e.target.value
		})

		this.updateCheckoutState()

	}*/

	updateCheckoutState() {
		this.props.updateCheckout({
			clientName: this.state.clientName,
			requestedCompanyName: this.state.requestedCompanyName,
			emailAddress: this.state.emailAddress,
			createAnAccount: this.state.createAnAccount,
			orderNotes: this.state.orderNotes
		})
	}

	render() {

		const checkoutError = (
			<div className="form-error">
				{this.props.checkoutError}
			</div>
		);

		return (
			<div class="box-gray">
				<h2 class="checkout-title"><FormattedMessage id="Billing Details" defaultMessage="Billing Details"></FormattedMessage></h2>
				<div class="checkout-form">
					<label><FormattedMessage id="How should we address you?*" defaultMessage="How should we address you?*"></FormattedMessage></label>
					<input type="text" name="clientName" onChange={this.onInputChange} />
					<label><FormattedMessage id="What is the preferred name of your company?*" defaultMessage="What is the preferred name of your company?*"></FormattedMessage></label>
					<input type="text" name="requestedCompanyName" value={this.props.checkout.requestedCompanyName} onChange={this.onInputChange} />
					<label><FormattedMessage id="Email address*" defaultMessage="Email address*"></FormattedMessage></label>
					<input type="email" name="emailAddress" onChange={this.onInputChange} />
					<p style={{ display: "none" }}><label><input type="checkbox" name="createAnAccount" onChange={this.onCheckboxChange} /> <FormattedMessage id="Create an account?" defaultMessage="Create an account?"></FormattedMessage></label></p>
					<p><input type="checkbox" disabled style={{cursor:"no-drop"}}/> <FormattedMessage id="Create an account?" defaultMessage="Create an account?"></FormattedMessage></p>
					<p><input type="checkbox" disabled style={{cursor:"no-drop"}}/> <FormattedMessage id="Save my information for recurring payment" defaultMessage="Save my information for recurring payment"></FormattedMessage></p>
					<label><FormattedMessage id="Order notes (optional)" defaultMessage="Order notes (optional)"></FormattedMessage></label>
					<textarea name="orderNotes" placeholder={this.props.currentLang == 'en'?'Tell us more about your order, eg. special arrangement for your company.':'更多订单信息，如公司的特别需求'} onChange={this.onInputChange}>{this.state.orderNotes}</textarea>
				</div>
				{ this.props.checkoutError ? checkoutError : '' }
			</div>
		)

	}

}

const mapStateToProps = (state) => {
	return {
		checkout: state.OrderCheckout,
		checkoutError: state.OrderCheckout.checkoutError
	}
}

const mapDispatchToProps = {
	updateCheckout: (checkoutData) => (dispatch) => {
		dispatch(updateCheckout(checkoutData))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingDetails)