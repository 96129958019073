export default {

	// API_BASE: 'http://jenga/api/v1/',

	API_BASE: 'https://api.jengacorp.com/api/v1/',
	// API_BASE: 'http://192.168.101.32:8989/api/v1/',
	// API_BASE: 'http://47.97.123.82:9798/api/v1/',

	// 测试后台地址
	// API_BASE: 'http://116.62.134.184:9798/api/v1/',
	// STRIPE_PUBLIC_KEY: 'pk_test_YQRw1iYnqYx3nvkwsDvTU5Sp00n8V7Bdo1'
	// STRIPE_PUBLIC_KEY: 'pk_live_vQ0IEo9dwo7q37DkmsKVo9sN001CnZPUJ7'
	STRIPE_PUBLIC_KEY: 'pk_live_TxNpxz0WPiXIcFlAURSLMOrG'
	// STRIPE_PUBLIC_KEY: 'pk_live_TxNpxz0WPiXIcFlAURSLMOrG'

}