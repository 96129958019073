import React, { Component } from 'react'
import { Link } from "react-router-dom"
import { FormattedMessage } from 'react-intl'


export default class Footer extends Component {
	render() {
		return (
			<div className="footer">
				<div className="container">
					<div className="row justify-content-between">
						<div className="col-md-5">
							<div className="footer-titel">
								<h2><FormattedMessage id="About Jenga Corp" defaultMessage="About Jenga Corp"></FormattedMessage></h2>
							</div>
							<div className="foot-about">
								<p><FormattedMessage id="Footer contents" defaultMessage="A Singapore ACRA-certified corporate secretary and MOM-certified employment agent that provides one-stop corporate services to companies pursuing global expansion through Singapore, Jenga Corp provides timely and digitised solutions for the high-efficiency demands of cross-border businesses."></FormattedMessage></p>
							</div>
						</div>
						<div className="col-md-3 col-small">
							<div className="footer-titel">
								<h2><FormattedMessage id="Site Map" defaultMessage="Site Map"></FormattedMessage></h2>
							</div>
							<div className="foot-nav">
								<ul>
									<li>
										<Link to={'/'} exact>
											<FormattedMessage id="Home" defaultMessage="Home"></FormattedMessage>
										</Link>
									</li>
									<li>
										<Link to={'/our-solutions'} exact>
											<FormattedMessage id="Our Solutions" defaultMessage="Our Solutions"></FormattedMessage>
										</Link>
									</li>
									<li>
										<Link to={'/about'} exact>
											<FormattedMessage id="About Us" defaultMessage="About Us"></FormattedMessage>
										</Link>
									</li>
									<li>
										<Link to={'/contact'} exact>
											<FormattedMessage id="Contact Us" defaultMessage="Contact Us"></FormattedMessage>
										</Link>
									</li>
									<li>
										<Link to={'/knowledge-center'} exact>
											<FormattedMessage id="Knowledge Center" defaultMessage="Knowledge Center"></FormattedMessage>
										</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-md-3  col-small">
							<div className="footer-titel">
								<h2><FormattedMessage id="Our Solutions" defaultMessage="Our Solutions"></FormattedMessage></h2>
							</div>
							<div className="foot-nav">
								<ul>
									<li><a classs="link" href="/our-solutions#company"><FormattedMessage id="Incorporation" defaultMessage="Incorporation"></FormattedMessage></a></li>
									<li><a classs="link" href="/our-solutions#corporate"><FormattedMessage id="Corporate Secretary Services" defaultMessage="Corporate Secretary Services"></FormattedMessage></a></li>
									<li><a classs="link" href="/our-solutions#tax"><FormattedMessage id="Accounting and Bookkeeping" defaultMessage="Accounting and Bookkeeping"></FormattedMessage></a></li>
									{/* <li><a classs="link" href="/order-form/47"><FormattedMessage id="EP and Payroll Services" defaultMessage="Employment Pass and Payroll Services"></FormattedMessage></a></li> */}
									<li><a classs="link" href="/order-form/65"><FormattedMessage id="Employment Pass" defaultMessage="Employment Pass"></FormattedMessage></a></li>
									<li><a classs="link" href="/our-solutions#global"><FormattedMessage id="Professional Structure Solutions" defaultMessage="Professional Structure Solutions"></FormattedMessage></a></li>
									<li><a classs="link" href="/our-solutions#trademark"><FormattedMessage id="Global IP Registration" defaultMessage="Global Trademark Registration"></FormattedMessage></a></li>
								</ul>
							</div>		
						</div>
					</div>
					<div className="row footer-bottom justify-content-between align-items-end">
						<div className="col-md-2">
							<div className="foot-logo">
								<img src="/images/logo.png" alt="#" />
							</div>							
						</div>
						<div className="col-md-2">
							<div className="foot-social col-md-10 d-flex p-0 justify-content-between">
								<a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a> 
								<a href="#" target="_blank"><i className="fab fa-twitter"></i></a> 
								<a href="#" target="_blank"><i className="fab fa-google"></i></a> 
								<a href="#" target="_blank"><i className="fab fa-youtube"></i></a> 
							</div>
					    </div>
						<div className="col-md-5 test">
							<p className="copy-right"><FormattedMessage id="© 2019 JengaCorp. All Rights Reserved." defaultMessage="© 2019 JengaCorp. All Rights Reserved."></FormattedMessage></p>
						</div>
						<div className="col-md-3">
							<div className="foot-right">
								<span>
									<Link to={"/privacy-policy"}>
										<FormattedMessage id="Privacy" defaultMessage="Privacy"></FormattedMessage>
									</Link> </span>
									  | <Link to={"/terms"}> <FormattedMessage id="Terms and Conditions" defaultMessage="Terms and Conditions"></FormattedMessage> </Link>
								 
								<a href="#root" className="goTop scroll" id="go-to-top"><i className="fas fa-arrow-up"></i><FormattedMessage id="GO TO TOP" defaultMessage="GO TO TOP"></FormattedMessage></a>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}