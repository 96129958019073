import React, { Component } from 'react'
import { Redirect } from 'react-router'

import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import Header from '../partials/Header'
import BreadCrumbs from '../partials/BreadCrumbs'
import HeadBanner from '../partials/HeadBanner'

import Footer from '../partials/Footer'


class CommonBusiness extends Component {

  constructor(props) {

    super(props)

    this.state = {
      isActiveList: [],
      lastActive: ''
    }

    // this.handClick = this.handClick.bind(this)

  }

  handClick(key) {
    let keys = key
    // console.log("点击")
    let item = 'accordion_content' + key
    // console.log(this.refs['accordion_content' + key])
    let list = [0, 0, 0, 0, 0, 0, 0, 0];
    if (this.state.lastActive && this.state.lastActive == key) {
      keys = ''
      const obj = this.refs['accordion_content' + key]
      obj.style.height = 0 + 'px'
      obj.style.overflow = 'hidden'
    } else {
      list[key - 1] = 1;
      const obj = this.refs['accordion_content' + key]
      const height = obj.childNodes[0].clientHeight
      obj.style.height = height + 'px'
      setTimeout(() => {
        obj.style.overflow = 'visible'
      }, 400)
      // obj.style.animation = 'mymove 0.4s infinite'
      if (this.state.lastActive) {
        const obj = this.refs['accordion_content' + this.state.lastActive]
        obj.style.height = 0 + 'px'
        obj.style.overflow = 'hidden'
      }
    }

    // if(this.state.lastActive && this.state.lastActive == key){
    //   keys = ''
    //   const obj = this.refs.accordion_content1
    //   obj.style.height = 0+'px'
    // }else{
    //   list[key-1] = 1;
    //   const obj = this.refs.accordion_content1
    //   const height = obj.childNodes[0].clientHeight
    //   obj.style.height = height+'px'
    // if(keys){
    //   const obj = this.refs.accordion_content1
    //   obj.style.height = 0+'px'
    // }
    // }

    this.setState({
      isActiveList: list,
      lastActive: keys
    })
  }
  componentWillMount() {

    const self = this;

  }

  render() {

    return (
      <div>
        <Header />
        <HeadBanner title="Common business Structure" />
        <BreadCrumbs pages={[
          {
            title: "Home",
            link: "/"
          },
          {
            title: "Our Solutions",
            link: "/our-solutions"
          },
          {
            title: "Global Structure"
          }
        ]} />
        {this.props.currentLang == 'en' ? (
          <section className="outer bg-white gray pb-0 text14">
            <div className="container pb-5  position-relative spaceleft140">
              <div className="globe">
                <img src="images/globe.png" alt="globe" />
              </div>
              <div className="small-container mini">
                <div className="large-heading mb-4 spaceLeft35">Common Business Structure</div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">Sole Corporation or Joint Venture</h3>
                  <p>Sole or multiple corporate shareholders are common among Singapore companies. Businesses worldwide like to set up their subsidiaries or joint ventures in Singapore, mostly attracted by Singapore’s tax benefits and advanced banking system as a global financial centre.</p>
                  <div className="accordion_content" ref="accordion_content1">
                    <div className="row mt-3 business-row">
                      <div className="col-md-6">
                        <div className="border-box">
                          <h5 className="mb-4 text14 dark-gray">Demo chart</h5>
                          <img src="images/joint-venture.png" alt="joint-venture" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="border-box">
                          <h4 className="mb-1 text14 dark-gray">Key Benefits</h4>
                          <ul className="default-list">
                            <li>Limited liability of the shareholders.</li>
                            <li>Physical office in Singapore is not required.</li>
                            <li>Dividends distributed to corporate shareholders enjoy tax transparency in Singapore.</li>
                            <li>Undistributed profit can be flexibly retained in the corporate account until shareholders are ready to take a dividend.</li>
                            <li>An advanced global banking system makes Singapore a great place for multi-national enterprises to set up their administration centre.</li>
                          </ul>
                          <h4 className="mb-1 text14 dark-gray">Suitable for</h4>
                          <ul className="default-list">
                            <li>Overseas expansion</li>
                            <li>Cross-border Joint Ventures or M&amp;A </li>
                            <li>HQ for multi-national enterprise</li>
                          </ul>
                          <p className="text12"><a href="/order-form/1" className="link">Click here</a> for Singapore Pte Ltd Incorporation</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[0] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 1)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">Investment Holding</h3>
                  <p>An investment holding company refers to a company that owns investments such as properties and shares for long term investment and derives investment income (“non-trade income”) such as dividends, interest or rental income. The company’s principal activity is&nbsp;investment holding.</p>
                  <div className="accordion_content" ref="accordion_content2">
                    <div className="row mt-3 business-row">
                      <div className="col-md-6">
                        <div className="border-box">
                          <h5 className="mb-4 text14 dark-gray">Demo chart</h5>
                          <img src="images/holding.png" alt="holding" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="border-box">
                          <h4 className="mb-1 text14 dark-gray">Key Reasons and Benefits</h4>
                          <ul className="default-list">
                            <li>Business expansion to a new industry or new countries. E.g. Alibaba has an investment holding company in Singapore to hold shares of Singpost and Lazada for the purpose of overseas business expansions.</li>
                            <li>Partnership development. Companies often grow their existing businesses by developing new partners. They may set up a subsidiary under the holding company and appoint the new partner to be the director and shareholder of the subsidiary.</li><li>Flexibility on corporate structure. Changes to the shareholder or directorship of the holding company does not affect the structure of the subsidiaries.</li>
                          </ul>
                          <p className="mb-4">Find out more about Singapore investment holding company from IRAS: </p>
                          <p className="mb-4"><a target="_blank" href="https://www.iras.gov.sg/irashome/Businesses/Companies/Working-out-Corporate-Income-Taxes/Specific-industries/Investment-Holding-Companies/" className="link">https://www.iras.gov.sg/irashome/Businesses/Companies/Working-out-Corporate-Income-Taxes/Specific-industries/Investment-Holding-Companies/</a></p>
                          <p className="text12"><a href="/order-form/1" className="link">Click here</a> to incorporate a Singapore investment holding company.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[1] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 2)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">Import-export and Trading Company</h3>
                  <p>Singapore has long been known as an important “port of call” for traders, especially those dealing across eastern and the western time zones. To start a trading business in Singapore, you will need to incorporate a company first.&nbsp;It can be a subsidiary of your existing business overseas or a new entity set up by a few shareholders. The private limited company is commonly used by import-export/trading companies in Singapore.</p>
                  <div className="accordion_content" ref="accordion_content3">
                    <div>
                      <p>All importers and exporters are required to activate their account with Singapore Customs before they can import/export goods in and out of Singapore. Account activation is processed within 1-2 working days after submission of the application and you will be issued a Customs approval letter that is valid for as long as your company exists. For import of all goods (including controlled and non-controlled items) into Singapore, you are required to obtain an IN Permit through TradeNet® before goods are imported into Singapore. For export of all goods (including controlled and non-controlled items) out of Singapore, you are required to obtain an OUT Permit through TradeNet®.</p>
                      <p className="mb-4">Find out more about running an import-export/trading business in Singapore from:<br /><a href="https://www.customs.gov.sg/businesses/importing-goods/quick-guide-for-importers/" target="_blank" className="link">https://www.customs.gov.sg/businesses/importing-goods/quick-guide-for-importers/</a></p>
                      <p><a href="/order-form/1" className="link">Click here</a> to incorporate your import-export/trading company</p>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[2] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 3)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">Variable Interest Entities</h3>
                  <p>The variable interest entity ("VIE") has long been a popular structure for foreign parties to invest in sectors that are restricted by China's industrial policy on foreign investment. In addition, the VIE structure has also been used as a means by which Chinese domestic entities list offshore on international capital markets.</p>
                  <div className="accordion_content" ref="accordion_content4">
                    <div>
                      <p>The first well knew VIE structure was that of Sina.com in its 2000 listing on NASDAQ. Indeed the VIE structure is also commonly known as a "Sina Structure". Sina used the VIE as a workaround structure to avoid restrictions on foreign direct investment (FDI) in the value-added telecom services sector. Since then, both foreign and Chinese investors alike have replicated the VIE structure in many other sectors of China’s economy where FDI is either restricted or prohibited for foreign investors.</p>
                      <p>In essence, a VIE structure refers to a structure whereby an entity established in China which is fully or partially foreign-owned ("Controlling Company") has control over an operating company ("Operation Company") which holds the necessary license(s) to operate in a FDI restricted/prohibited sector. As such a sector is restricted/prohibited by the PRC authorities, foreign investors are not able to directly invest in such an Operation Company. Accordingly, the foreign investors adopt various contractual arrangements between the Controlling Company and the Operation Company in order to obtain de facto control over the operation and management of the Operation Company. The profits of such an Operation Company would also flow back to the Controlling Company and then ultimately be consolidated by the foreign investors. </p>
                      <p>For domestic companies, especially companies in the restricted industries without many physical assets (such as internet or telecommunications), the VIE structure is widely used to enable them to obtain financing from overseas markets through overseas listings. Gradually, companies from heavy industries have also started to adopt the VIE structure to list overseas and the overseas shell company started adopting such VIE structures to circumvent the approval requirements stipulated by relevant PRC M&amp;A Rules.</p>
                      <h4 className="mb-1 text14 dark-gray">Sample VIE Structure</h4>
                      <div className="text-center pt-3 pb-4">
                        <img src="images/entities.png" alt="entities" />
                      </div>
                      <h4 className="mb-1 text14 dark-gray pt-2">Key Reasons/Benefits</h4>
                      <ul className="default-list nodots mb-4">
                        <li>1st lay:  Each ultimate beneficial owner to setup their wholly owned BVI entities.</li>
                        <li>2nd lay: Cayman Holding company owned by the BVIs/investors that is to be listed on stock markets.</li>
                        <li>3rd lay:  HK or Singapore company to be used as an overseas operating company.</li>
                        <li>4th lay:  China WOFE (Wholly Owned Foreign Entity) as the “Controlling company” to hold the “Operation Company” in China.</li>
                      </ul>
                      <ul className="default-list nodots">
                        <li><a href="/contact" className="link">Contact us</a> for more information regarding the VIE structure setup.</li>
                        <li><a href="/order-form/54" className="link">Click here</a> to incorporate your BVI company.</li>
                        <li><a href="/order-form/53" className="link">Click here</a> to incorporate your Cayman company.</li>
                        <li><a href="/order-form/1" className="link">Click here</a> to incorporate your Singapore operating company.</li>
                      </ul>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[3] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 4)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">Non-profit Organisation</h3>
                  <p>A non-profit organisation (NPO) in Singapore is a legally constituted organisation whose main purpose is to support or engage in activities of public or private interest without any commercial or monetary profit. When NPOs earn a “profit”, more accurately called a surplus, it is retained by the organisation for its future activities and unlike a profit-making organisation, does not distribute its earnings amongst its members. NPOs are commonly referred to as Voluntary Welfare Organisations (VWOs) in Singapore and can be registered under the law as a (1) public company limited by guarantee, (2) society, or (3) charitable trust.</p>
                  <div className="accordion_content" ref="accordion_content5">
                    <div>
                      <hr />
                      <div className="text12">
                        <h4 className="mb-1 text14 dark-gray">Public Company Limited by Guarantee</h4>
                        <p>Most charities and non-profit organisations typically set up in Singapore under the public company limited by guarantee (CLG) option. A&nbsp;CLG carries out non-profit making activities that have some basis of national or public interest e.g. promoting art or charity.<br />A CLG has members, rather than shareholders, the members of the company guarantee/undertake to contribute a predetermined sum to the liabilities of the company which becomes due in the event of the company being wound up. The sum set aside as guarantee may be as low as SGD 1. Also, there is no share capital.<br />A company limited by guarantee is a legal entity that exists in its own right in the eyes of the law, separate and distinct from the individuals who are involved in it.<br />A company is like a person in law. It can sue, or to be sued, in its own name; it can enter into contracts and can own property all in its own name.<br />A charity set up under the company limited by guarantee option must lodge a Memorandum and Articles of Association (M&amp;A) in order to register the company.</p>
                        <p><a href="/order-form/5" className="link">Click here</a> to incorporate your Company Limited by Guarantee.</p>
                        <h4 className="mb-1 text14 dark-gray">Society</h4>
                        <p>A society is defined as a club, company, partnership or association of 10 or more persons, whatever its nature or objective, and not already registered under any other law. A society is suitable for membership or volunteer-based groups, especially smaller groups with strong community links and not heavily dependent on donations and external funding.<br />Societies registered with the Registrar of Societies (ROS) are exempt from income tax if surplus funds are from members’ contributions; or if over 50% of gross revenue receipts are from members and are not tax-deductible for members. For full tax exemption, the society must apply for Charity status after its registration.</p>
                        <p>Find out more about Society from <a href="https://www.ros.mha.gov.sg/cc" target="_blank" className="link">www.ros.mha.gov.sg</a></p>
                        <h4 className="mb-1 text14 dark-gray">Charitable Trust</h4>
                        <p>A charitable trust or foundation in Singapore is a legal entity that can be set up by anyone who has decided they want to commit to setting aside some of their assets or income for charitable causes and who wishes to take a structured and ongoing approach to give. A common use of a charitable trust is for the administration of a scholarship or bursary.<br />Trusts are licensed by the Monetary Authority of Singapore (MAS) and are governed by the Singapore Trust Companies Act. Professional advice is recommended if you are interested in registering a Charitable Trust in Singapore.</p>
                        <p>Find out more about Charitable Trust from <a href="https://www.charities.gov.sg/Pages/Home.aspx" target="_blank" className="link">www.charities.gov.sg</a></p>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[4] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 5)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">Token Sale Structure</h3>
                  <p>Token Sales (ICO) are often done in a non-profit structure or other such entity that does not have shares. Singapore Public Companies Limited by Guarantee (CLG) may be established as a non-profit vehicle with the corporate objective of promoting the particular blockchain technology and providing a formal governance structure. A company limited by guarantee does not have any shareholders and so it may be preferable to a company limited by shares by participants in an&nbsp;ICO. This is because the shareholders of a company limited by shares can more readily access the proceeds of an&nbsp;ICO&nbsp;by requiring the directors to distribute profits or return capital.<br />Still, there is uncertainty around the nature of token sales from legal and tax perspectives in different jurisdictions; token sale projects often set up an on-shore off-shore structure to divide their token issuer and business operations across different jurisdictions (as below).</p>
                  <div className="accordion_content" ref="accordion_content6">
                    <div>
                      <hr />
                      <div className="mt-5 pb-5 text-center"> <img src="images/structure.png" alt="structure" /></div>
                      <hr />
                    </div>
                  </div>
                  <div className={this.state.isActiveList[5] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 6)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">Intellectual Property Holding Company</h3>
                  <p>Intellectual property holding companies are those specifically designd for owning another's intellectual property – i.e. patents,&nbsp;copyrights,&nbsp;trademarks, service marks, trade secrets, etc. – for the purpose to manage, sell and/or licensing the same to third parties for the right to exploit said intellectual property in a given or agreed-upon manner. An intellectual property holding company can shield intellectual property assets by separating the ownership of the IPs from the operating business companies. This separation in business structure is also ideal for those business owners wishing to franchise based on the fact said owners can license their intellectual property thorough their holding company to a third party seeking to open the business in another state. </p>
                  <div className="accordion_content" ref="accordion_content7">
                    <div>
                      <hr />
                      <h4 className="mb-1 text14 dark-gray pb-4">A IP holding structure illustration:</h4>
                      <div className="pb-4 text-center">
                        <img src="images/property-holding.png" alt="property holding" />
                      </div>
                      <ul className="default-list nodots">
                        <li><a href="/order-form/54" className="link">Click here</a> to incorporate a BVI company.</li>
                        <li><a href="/order-form/1" className="link">Click here</a> to incorporate a Singapore Private Limited Company.</li>
                      </ul>
                      <hr />
                    </div>
                  </div>
                  <div className={this.state.isActiveList[6] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 7)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">Asset Management Company and Family Offices</h3>
                  <p className="mb-0">"Singapore is a leading asset management hub for institutional investors and fund managers to access pan-Asian opportunities." (Ravi Menon, Managing Director, MAS)</p>
                  <div className="accordion_content" ref="accordion_content8">
                    <div>
                      <p className="mb-0">Companies (including family offices) that wish to conduct regulated fund management activities under the&nbsp;Securities and Futures Act (SFA)&nbsp;must be registered with MAS or hold a capital markets services (CMS) licence to operate either as a: </p>
                      <ul className="default-list text-14">
                        <li>Registered fund management company (RFMC).</li>
                        <li>Licensed fund management company (LFMC).</li>
                        <li>Managers of venture capital funds may also apply to operate under the venture capital fund manager (VCFM) regime.</li>
                      </ul>
                      <p className="mb-0">Individuals performing key functions in a fund management company, such as portfolio construction and allocation, research and advisory, business development and marketing or client servicing is required to be representatives.<br />When assessing an application to be an RFMC, LFMC or VCFM, MAS takes into account<br />factors such as:</p>
                      <ul className="default-list text-14">
                        <li>Fitness and propriety of the applicant, its shareholders and directors.</li>
                        <li>Track record and fund management expertise of the applicant and its parent company or major shareholders.</li>
                        <li>Ability to meet the minimum financial requirements prescribed under the SFA.</li>
                        <li>Strength of internal risk management and compliance systems.</li>
                        <li>Business model/plans and projections and associated risks.</li>
                      </ul>
                      <p>In addition, your company would need to operate from a dedicated and secure office space that is accessible only by your company's directors and staff. According to MAS, the term 'single-family office' (SFO) is not defined under the Securities and Futures Act (SFA). An SFO typically refers to an entity that manages assets for or on behalf of only one family and is wholly owned or controlled by members of the same family.<br />The term 'family' in this context may refer to individuals who are lineal descendants from a single ancestor, as well as the spouses,ex-spouses, adopted children and step-children of these individuals.<br />It is not MAS' intention to license or regulate SFOs. There are existing className exemptions from licensing under the SFA and the Financial Advisers Act for the provision of fund management and financial advisory services respectively to related corporations.</p>
                      <p className="text12">Find out more from MAS: <a href="https://www.mas.gov.sg/regulation/capital-markets/Fund-Management-Licensing-and-Registration" target="_blank" className="link">www.mas.gov.sg/regulation/capital-markets/Fund-Management-Licensing-and-Registration</a></p>
                      <ul className="default-list nodots">
                        <li><a href="/order-form/1" className="link">Click here</a> to incorporate a Singapore Private Limited Company for fund management business or family office.</li>
                        <li><a href="/contact" className="link">Contact us</a> for more information regarding registration or licensing with MAS.</li>
                      </ul>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[7] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 8)}>click</div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="outer common-business bg-white gray pb-0 text14">
            <div className="container pb-5  position-relative spaceleft140">
              <div className="globe">
                <img src="images/globe.png" alt="globe" />
              </div>
              <div className="small-container mini">
                <div className="large-heading mb-4 spaceLeft35">常见海外架构</div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">独资/合资架构</h3>
                  <p>独资合资控股结构是指一个或者多个企业股东来控股新加坡公司从而达到海外拓展，海外结算或者税务优化的目的。</p>
                  <div className="accordion_content" ref="accordion_content1">
                    <div className="row mt-3 business-row">
                      <div className="col-md-6">
                        <div className="border-box">
                          <h5 className="mb-4 text14 dark-gray tostring">图示</h5>
                          <div className="left12"><img src="images/joint-venture-zh.png" alt="joint-venture" /></div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="border-box">
                          <h4 className="mb-1 text14 dark-gray tostring">优势</h4>
                          <p className="text12">股东承担有限责任，运作模式灵活，可以开设银行账户后离岸操作，也可以在岸投入运营公司收入可以通过分红回流股东公司，股东公司分红无需二次征税盈余资金可以保留在海外账户，享受新加坡的税收政策新加坡金融银行体系发达，适合跨国企业建立自己的金融结算中心。</p>
                          <br/>
                          <h4 className="mb-1 text14 dark-gray tostring">适用于</h4>
                          <p className="text12">有意向拓展东南亚和全球市场的公司</p>
                          <p className="text12">有意向进行海外投资和并购公司</p>
                          <p className="text12">有意向设立全球结算中心的公司</p>
                          <br/>
                          <p className="text12">新加坡公司注册<a href="/order-form/1" className="link">点击开始注册</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[0] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 1)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">投资控股公司架构</h3>
                  <p>成立控股公司来持有其他具有特定目的的分支机构或子公司的股份。 控股公司不会与子公司进行交易，而是作为中央调解人 - 代表集团内主要股东持有股份。 根据下图，控股公司将持有子公司A和其他公司的股份，这些公司可随着业务的增长而开设。</p>
                  <div className="accordion_content" ref="accordion_content2">
                    <div className="row mt-3 business-row">
                      <div className="col-md-6">
                        <div className="border-box">
                          <h5 className="mb-4 text14 dark-gray tostring">图示</h5>
                          <div className="left12"><img src="images/holding-zh.png" alt="holding" /></div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="border-box">
                          <h4 className="mb-1 text14 dark-gray tostring">在新加坡成立控股公司的优势</h4>
                          <p className="text12">( 1 ) 新发展</p>
                          <p className="text12">公司希望将业务扩展到（a）更多行业（b）更多国家 如：阿里巴巴控股公司在新加坡投资并持股Singpost和Lazada达到业务扩张的目的。</p>
                          <p className="text12">( 2 ) 伙伴关系</p>
                          <p className="text12">公司希望通过纳入新伙伴的业务进而扩展自身业务的扩展，则可以在控股结构中添加子公司，由新合伙人在子公司中持股并担任董事。如：阿里巴巴通过Singpost完成在新行业中的轻松扩张，让自已成为具有新加坡物流专业运营团队的值得信赖的合作伙伴。</p>
                          <p className="text12">( 3 ) 公司变更</p>
                          <p className="text12">如果控股公司扩张导致股权或董事变更，不影向各子公司结构。</p>
                          <br/>
                          <h4 className="mb-1 text14 dark-gray tostring">如何设置控股公司结构：</h4>
                          <p className="text12">第一步：注册控股公司</p>
                          <p className="text12">注册一家股份制有限公司作为控股公司，并指定主要董事和股东。</p>
                          <p className="text12">第二步：控股公司的股权</p>
                          <p className="text12">注册其他子公司并在整个架构内明确每个子公司的股权结构。</p>
                          <p className="text12">第三步：分配角色</p>
                          <p className="text12">根据治理要求，确定各分公司的董事。</p>
                          <br/>
                          <p className="text12">新加坡公司注册<a href="/order-form/1" className="link">点击开始注册</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[1] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 2)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">跨境贸易架构</h3>
                  <p>跨境贸易公司结构是一种较为常见的贸易公司模式。这种模式是指，个人或者机构，以独资或者合资的形式成立一个新加坡公司。由该公司与货物生产国以及货物购买国的公司开展业务往来，从而达到全球自由贸易，国际结算，税收优化等目的。</p>
                  <div className="accordion_content" ref="accordion_content3">
                    <div className="border-line">
                      <h5 className="mb-4 text14 dark-gray tostring">图示</h5>
                      <div className="left12 pb-5"><img src="images/trading-zh.png" alt="trading-zh" /></div>
                      <p className="text12">在低税国家设立运营主体：开设对公帐户和雇佣当地员工来支持业务所需的基础运营支持。在此，我们建议采用新加坡公司作为购销主体，以享受当地外汇便利和税务政策利好。新加坡公司税后利润分红无税，是否需要汇回股东所在税务国家可依股东和税务利好情况决定。</p>
                      <br/>
                      <p className="text12">新加坡公司注册<a href="/order-form/1" className="link">点击开始注册</a></p>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[2] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 3)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">VIE架构</h3>
                  <p>VIE，可变利益实体，是Variable Interest Entity的简称。可变利益实体（VariableInterest Entities）又被称为协议控制结构, 指的是境外注册的上市主体与境内的运营实体相分离，境外上市主体通过协议的方式控制境内运营实体，从而将境外注册的上市实体与境内的业务运营实体相分离，以达到境外融资和上市等多种目的。路透社数据显示，在纽约证券交易所和纳斯达克上市的200多家中国企业中，有95家使用VIE结构。这种持股方式最早出自于2000年在美国上市的新浪。此后，新东方、百度等，均通过这种形式向国际投资者募集资金。而这些企业的注册地，大多选择开曼群岛。腾讯科技曾统计：过去20年间，内地赴港上市的家族企业资产规模最大的50家之中，共有44家注册于开曼群岛。 </p>
                  <div className="accordion_content" ref="accordion_content4">
                    <div className="border-line">
                      <h4 className="mb-1 text14 dark-gray pt-2 tostring">VIE架构的组成及图解</h4>
                      <p className="text12">VIE一般由三部分架构组成，即境外上市主体、境内外资全资子公司(WFOE，Wholly Foreign Owned Enterprise)或境内外资公司(FIE，Foreign Invested Enterprise)和持牌（实际运营）公司(外资受限业务牌照持有者)。其中，境外上市主体一般选择使用开曼公司，也有使用香港壳公司，甚至并存的多重模式。不过搭建架构的时候，需要确保整体架构的稳定性以及股东利益的一致性。</p>
                      <div className="mt-5 pb-5 text-center"><img src="images/entities-zh.png" alt="entities-zh" /></div>
                      <h4 className="mb-1 text14 dark-gray pt-2 tostring">VIE架构每层权益主体的作用</h4>
                      <ul className="default-list nodots mb-4">
                        <li>（一）设立海外第一层权益主体——BVI 公司</li>
                        <li>在英属维尔京群岛（BVI）注册成立第一层权益主体 BVI 公司。</li>
                        <li>（二）设立海外第二级权益主体——开曼公司</li>
                        <li>开曼公司作为上市主体，股东为第一层的BVI公司。</li>
                        <li>（三）设立海外第三级权益主体——香港公司或者新加坡公司</li>
                        <li>开曼公司全资控股香港或者新加坡公司作为海外运营主体，以获得运营便利和税务优惠。</li>
                        <li>（四）香港壳公司在境内设立 WOFE</li>
                        <li>WOFE 通过签订协议来控制境内运营实体公司，完成企业的利润分配。</li>
                      </ul>
                      <h4 className="mb-1 text14 dark-gray pt-2 tostring">VIE架构中需要用到的协议</h4>
                      <ul className="default-list nodots mb-4">
                        <li>在安然事件发生之前，美国通用会计准则规定只有当一家公司对另一家公司拥有多数投票权才会要求合并报表。安然事件之后，美国财务会计标准委员会紧急出台了FIN46，规定只要这个实体符合VIE的标准，就需要合并报表。根据FIN46条款，凡是满足以下三个条件任一条件的SPV都应被视作VIE，将其损益状况并入“第一受益人”的资产负债表中：(1)风险股本很少，这个实体(公司)主要由外部投资支持，实体本身的股东只有很少的投票权；(2)实体(公司)的股东无法控制该公司；(3)股东享受的投票权和股东享受的利益分成不成比例。</li>
                        <li>因此为了境外上市主体能够按照境外会计准则合并境内运营实体的会计报表，WFOE一般会与境内运营实体（及其股东）签署关于控制权和利润的转移协议（以下合称“控制协议”或者“VIE 协议”）。控制协议一般包括如下协议：(1) 贷款协议. 由 WFOE 与境内运营实体的股东签订。WFOE 根据该协议向境内运营实体的股东提供贷款，然后该等股东将这笔贷款通过增资的方式注入境内运营实体；(2) 独家期权协议. 由 WFOE 与境内运营实体的股东签订。在该协议项下，境内运营实体的股东授予 WFOE 一项独家期权。根据该独家期权，WFOE 可以随时自己或者指定任意第三方主体购买境内运营实体股东所持有的境内运营实体的股权；(3) 股东表决权委托协议. 由 WFOE 与境内运营实体的股东签订。在该协议项下，境内运营实体的股东委托 WFOE 行使其在境内运营实体的股东表决权；(4) 独家业务合作协议. 由 WFOE 与境内运营实体签订。在该协议项下，WFOE 向境内运营实体提供独家的咨询和技术服务，从而达到转移利润的目的；(5) 股权质押协议. 由 WFOE 与境内运营实体的股东签订。在该协议项下，境内运营实体的股东将其在境内运营实体的股权质押给 WFOE 以担保上述四个协议的履行。</li>
                      </ul>
                      <p className="text12">VIE架构主体<a href="/order-form/54" className="link">点击开始注册</a></p>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[3] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 4)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">非盈利慈善机构</h3>
                  <p>新加坡的⾮非营利组织指主要目的为支持或参与公共利益，而不具任何商业利益的合法成立的组织。 与盈利组织不同在于:⾮非营利组织获得的“利润”被称为盈余，将被继续保留作为其他活动的经费，而不在其成员中分配利润。 非营利组织通常被称为“自愿福利组织” （VWOs）。 <br/>
                  非盈利性组织的特征: 1.由具备受托资格⼈人组成的受托董事会或管理委员会独⽴立管理运营; 2.独⽴立于政府之外; 3.为该组织以外的群体创造效益，通常为社会公益; 4.禁⽌止组织内成员分享公司利润; 5.只有在取得“慈善机构资格”后，才可获得全额免税。
                  </p>
                  <div className="accordion_content" ref="accordion_content5">
                    <div className="border-line">
                      <h4 className="mb-1 text14 dark-gray tostring">新加坡非盈利组织(基金会)种类:</h4>
                      <p className="text12">在低税国家设立运营主体：开设对公帐户和雇佣当地员工来支持业务所需的基础运营支持。在此，我们建议采用新加坡公司作为购销主体，以享受当地外汇便利和税务政</p>
                      <p className="text12"><strong>1.公共担保有限公司(Public company limited by guarantee)</strong></p>
                      <p className="text12"><strong>2.社团 (Society)</strong></p>
                      <p className="text12"><strong>3.慈善信托 (Charitable Trust)</strong></p>
                      <br/>
                      <p className="text12">以下为您重点介绍公共担保有限公司</p>
                      <p className="text12">公共担保有限公司是三种形式中相较最理想最实⽤用的组织形式，可作为独⽴立法⼈人 实体进⾏行经营活动，⽽而组织内成员仅承担有限责任。担保有限公司通常从事⾮非交 易性慈善、宗教、科学或艺术活动。</p>
                      <p className="text12"><strong>特征: </strong>从事基于国家、公共利益的市场经营活动，例如推⼲⼴广艺术和慈善。公司没有股本。 公司没有股东只有公司成员，成员保证/承诺承担公司清盘时所产生的所有负债综合。 成立了一家新加坡担保有限公司必须在其名称中具备后缀“LTD”。根据法律规定，担保有限责任公司是一个独立于公司成员以外的法人实体，拥有自己的权利。它可以起诉，或者被起诉，它可以⾃己的名义订⽴立合同，并能以自己的名义拥有财产。成⽴立担保有限公司优点及缺点:1.享有独立法⼈人权利。2.成员仅承担有限责任。3.必须接受公开披露和法律控制4.年度报告及合规要求包括审核要求都⽐比较复杂，并不总是适合⼩小团体。5.公司成立通常需要求助于专业咨询机构。</p>
                      <p className="text12"><strong>税务: </strong>公司资金收入来自于成员贡献部分可获得免税，或如果有超过 50%的总收入是 来自公司成员且为成员的不可扣税项目，同样可以申请免征所得税;获取全额免税需在公司注册成立后申请成为慈善机构。</p>
                      <br/>
                      <p className="text12">新加坡公司注册<a href="/order-form/1" className="link">点击开始注册</a></p>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[4] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 5)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">代币发行架构</h3>
                  <p>新加坡非盈利性质的公共担保有限公司（基金会）近年来常见于区块链代币发型架构；该主体性质为担保制，区别于股份制公司，该公司无股东和股份，治理结构由董事会和成员（guarantor)组成，因此也不存在分红和控股操作，所得收益仅能用于符合既定公共目的的经费开支，且需要每年提交审计报告；在区块链代币发行的实践中，因白皮书中所描述的项目均为对于未来事件的描述（尚未在当下发生），则代币投资人投资到项目方的代币资金的用途是用于项目未来的开发，这笔资产并不属于项目团队或者任何个人，只是由项目团队代全体投资人社群进行管理和支出，以完成白皮书中承诺的未来发展目标。因此非盈利的治理结构和运营规则符合代币发行的本质和管理需求，也被广泛采用。</p>
                  <div className="accordion_content" ref="accordion_content6">
                    <div className="border-line">
                      <div>
                        <h4 className="mb-1 text14 dark-gray tostring">常见代币发行架构</h4>
                        <p className="text12">离岸公司出任新加坡公共担保有限公司（基金会）的成员，实现对基金会的管理控制（75%以上成员投票可决定基金会董事的任免），由新加坡基金会100%控股一间离岸主体（多为BVI）作为发币主体，保证该发币主体的属性（非盈利）和解决因为新加坡主体直接作为发币主体可能带来的税务义务。</p>
                        <div className="mt-5 pb-5 text-center"> <img src="images/structure-zh.png" alt="structure" /></div>
                      </div>
                      <h4 className="mb-1 text14 dark-gray tostring">注意事项</h4>
                      <p className="text12">新加坡银行对于数字货币相关业务的银行开户仍处在高度敏感状态，用于代币发行的基金会和离岸公司目前仍处在开户困难的状态。但MAS对于金融创新的支持和鼓励，新加坡仍然成为在岸国家中对于数字货币行业最为友好的国际金融中心。部分项目在完成代币发行后，选择成立私人有限公司作为独立于发币主体之外的运营公司，往往能顺利完成对公帐户开设，银行开户完成后，与基金会签定运营协议，通过合规OTC将数字货币收入转为法币注入运营主体正常开展业务和履行税务义务。</p>
                      <br/>
                      <h4 className="mb-1 text14 dark-gray tostring">VIE架构中需要用到的协议</h4>
                      <p className="text12">在安然事件发生之前，美国通用会计准则规定只有当一家公司对另一家公司拥有多数投票权才会要求合并报表。安然事件之后，美国财务会计标准委员会紧急出台了FIN46，规定只要这个实体符合VIE的标准，就需要合并报表。根据FIN46条款，凡是满足以下三个条件任一条件的SPV都应被视作VIE，将其损益状况并入“第一受益人”的资产负债表中：(1)风险股本很少，这个实体(公司)主要由外部投资支持，实体本身的股东只有很少的投票权；(2)实体(公司)的股东无法控制该公司；(3)股东享受的投票权和股东享受的利益分成不成比例。</p>
                      <p className="text12">因此为了境外上市主体能够按照境外会计准则合并境内运营实体的会计报表，WFOE一般会与境内运营实体（及其股东）签署关于控制权和利润的转移协议（以下合称“控制协议”或者“VIE 协议”）。控制协议一般包括如下协议：(1) 贷款协议. 由 WFOE 与境内运营实体的股东签订。WFOE 根据该协议向境内运营实体的股东提供贷款，然后该等股东将这笔贷款通过增资的方式注入境内运营实体；(2) 独家期权协议. 由 WFOE 与境内运营实体的股东签订。在该协议项下，境内运营实体的股东授予 WFOE 一项独家期权。根据该独家期权，WFOE 可以随时自己或者指定任意第三方主体购买境内运营实体股东所持有的境内运营实体的股权；(3) 股东表决权委托协议. 由 WFOE 与境内运营实体的股东签订。在该协议项下，境内运营实体的股东委托 WFOE 行使其在境内运营实体的股东表决权；(4) 独家业务合作协议. 由 WFOE 与境内运营实体签订。在该协议项下，WFOE 向境内运营实体提供独家的咨询和技术服务，从而达到转移利润的目的；(5) 股权质押协议. 由 WFOE 与境内运营实体的股东签订。在该协议项下，境内运营实体的股东将其在境内运营实体的股权质押给 WFOE 以担保上述四个协议的履行。</p>
                      <br/>
                      <p className="text12">公共担保有限公司（基金会）<a href="#" className="link">点击开始注册</a></p>
                      <p className="text12">离岸发币主体<a href="#" className="link">点击开始注册</a></p>
                      <p className="text12">新加坡运营主体注册和开户<a href="#" className="link">点击开始注册</a></p>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[5] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 6)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">无形资产持有架构</h3>
                  <p>知识产权持有结构是指，个人或者机构通过离岸公司（如英属维尔京群岛或开曼群岛）持有知识产权（如版权或者专利），然后成立新加坡公司，将专利或版权授权给新加坡公司使用。</p>
                  <div className="accordion_content" ref="accordion_content7">
                    <div className="row mt-3 business-row">
                      <div className="col-md-6">
                        <div className="border-box">
                          <h5 className="mb-4 text14 dark-gray tostring">图示</h5>
                          <img src="images/property-holding-zh.png" alt="property-holding-zh" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="border-box">
                          <h4 className="mb-1 text14 dark-gray tostring">优势</h4>
                          <p>1. 离岸公司持有知识产权，对知识产权产生的收入进行税务优化</p>
                          <p>2. 运作模式灵活，可以开设银行账户后离岸操作，也可以在案投入运营</p>
                          <p>3. 新加坡公司收入可以通过分红回流股东公司，股东分红无需二次征税</p>
                          <p>4. 盈余资金可以保留在海外账户，享受海外的税收政策</p>
                          <p>5. 知识产权受新加坡知识产权法保护</p>
                          <br/>
                          <h4 className="mb-1 text14 dark-gray tostring">适用于</h4>
                          <p>品牌全球化的公司</p>
                          <p>持有多项知识产权的公司</p>
                          <p>有意向进行海外收入税务优化的公司</p>
                          <br/>
                          <p className="text12">新加坡公司注册<a href="/order-form/1" className="link">点击开始注册</a></p>
                          <p className="text12">BVI公司注册<a href="/order-form/54" className="link">点击开始注册</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={this.state.isActiveList[6] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 7)}>click</div>
                </div>
                <div className="business-section-box">
                  <h3 className="text18 site-color">资产管理架构</h3>
                  <p className="mb-0">在资产管理架构中，常由个人或者机构以独资或者合资的形式成立一个第三国或地区的离公司（比如开曼群岛，BVI，赛摩亚等），然后在新加坡按需成立一个或者多个全资子公司，用新加坡的子公司持有相关的资产。同时个人或者机构委托第三方机构，或者自己成立一个独立的资产管理公司来管理旗下资产。</p>
                  <div className="accordion_content" ref="accordion_content8">
                    <div className="border-line">
                      <h4 className="mb-1 text14 dark-gray tostring">图示</h4>
                      <div className="mt-5 pb-5 text-center">
                        <img src="images/asset-management-zh.png" alt="asset-management-zh" />
                      </div>
                      <p className="text12">个人或者机构在进行投资或者管理资产时，利用离岸公司信息保密、结构安全、身份自由等独有的特点，合理规划投融资项目与资产管理，规避政治壁垒和政策壁垒，达到投资与资产管理的目的。值得注意的事，成立资产管理公司通常需申领牌照，接受金融监管部门（新加坡MAS）的监管，更多详情，请联系Jenga专业顾问。</p>
                      <br/>
                      <p className="text12">新加坡公司注册<a href="/order-form/1" className="link">点击开始注册</a></p>
                      <p className="text12">BVI公司注册<a href="/order-form/54" className="link">点击开始注册</a></p>
                      <p className="text12">开曼公司注册<a href="/order-form/53" className="link">点击开始注册</a></p>
                      
                    </div>
                  </div>
                  <div className={this.state.isActiveList[7] ? "accordion_toggle acActive" : "accordion_toggle acNotactive"} onClick={this.handClick.bind(this, 8)}>click</div>
                </div>
              </div>
            </div>
          </section>
        )}

        <Footer />
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentLang: state.locales.currentLang
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CommonBusiness)