import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import { connect } from 'react-redux'

class HomeBanner extends Component {
	constructor() {
		super()
		this.videoPaly = this.videoPaly.bind(this)
		this.videoPause = this.videoPause.bind(this)
	}
	//<img src="/images/video.png" alt="#" class="shadow" />
	componentDidMount() {
		switch (this.props.currentLang) {
			case 'en':
				this.refs.bVideo_en.addEventListener('play', this.videoPaly)
				this.refs.bVideo_en.addEventListener('pause', this.videoPause)
				break;
			case 'zh':
				this.refs.bVideo_zh.addEventListener('play', this.videoPaly)
				this.refs.bVideo_zh.addEventListener('pause', this.videoPause)
				break;
			default:
				break;
		}
	}
	componentWillUnmount() {
		switch (this.props.currentLang) {
			case 'en':
				this.refs.bVideo_en.removeEventListener('play', this.videoPaly)
				this.refs.bVideo_en.removeEventListener('pause', this.videoPause)
				break;
			case 'zh':
				this.refs.bVideo_zh.removeEventListener('play', this.videoPaly)
				this.refs.bVideo_zh.removeEventListener('pause', this.videoPause)
				break;
			default:
				break;
		}
	}
	videoPaly() {
		switch (this.props.currentLang) {
			case 'en':
				this.refs.playBtn_en.style.display = 'none'
				break;
			case 'zh':
				this.refs.playBtn_zh.style.display = 'none'
				break;
			default:
				break;
		}
	}
	videoPause() {
		switch (this.props.currentLang) {
			case 'en':
				this.refs.playBtn_en.style.display = 'block'
				break;
			case 'zh':
				this.refs.playBtn_zh.style.display = 'block'
				break;
			default:
				break;
		}
	}
	playPause(type) {
		switch (type) {
			case 'en':
				this.refs.playBtn_en.style.display = 'none'
				this.refs.bVideo_en.play()
				break;
			case 'zh':
				this.refs.playBtn_zh.style.display = 'none'
				this.refs.bVideo_zh.play()
				break;
			default:
				break;
		}
	}

	render() {
		return (
			<div className="banner-row homeBanner pb-md-5">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<div className="incorp"> 
								{
									this.props.currentLang == 'en' ?
										<h1>
											<FormattedMessage id="Digital" defaultMessage="Digital"></FormattedMessage> <strong><FormattedMessage id="Incorporation, " defaultMessage="Incorporation, "></FormattedMessage><br /><FormattedMessage id="Secretary & Accounting" defaultMessage="Secretary & Accounting"></FormattedMessage></strong><br /> <FormattedMessage id="Solutions" defaultMessage="Solutions"></FormattedMessage>
										</h1> :
										<h1>
											<strong className="homeTitle">数字化公司秘书和 <br/>综合企业服务提供商</strong>
										</h1>
								}
								<p>
									<FormattedMessage id="home.incorporate_slider_content" defaultMessage="Incorporate your global entities in mainstream jurisdictions and access our comprehensive suite of business solutions"></FormattedMessage>
								</p>
							</div>
						</div>
						<div className="col-md-6" style={{ display: (this.props.currentLang == 'zh' ? 'none' : 'block') }}>
							<div className="vedio">
								<video id="bVideo_en" ref="bVideo_en" class="video" poster="images/play.png">
									<source src={"/videos/home-en.mp4"} type="video/mp4" />
								</video>
								<button id="playButton" ref="playBtn_en" class="playButton" onClick={this.playPause.bind(this, 'en')}></button>
							</div>
						</div>
						<div className="col-md-6" style={{ display: (this.props.currentLang == 'zh' ? 'block' : 'none') }}>
							<div className="vedio">
								<video id="bVideo_zh" ref="bVideo_zh" class="video" poster="images/play.png">
									<source src={"/videos/home-zh.mp4"} type="video/mp4" />
								</video>
								<button id="playButton" ref="playBtn_zh" class="playButton" onClick={this.playPause.bind(this, 'zh')}></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(HomeBanner)