import BaseService from './BaseService'

export default class ContactService extends BaseService {

	sendEmailSections(data) {
		return this.apiCall(
			`contactUs?_format=json`,
			'POST',
			{data},
			true
		).then((response) => {

			return response.data;
			
		})
	}

}