export const UPDATE_LOCALES = 'UPDATE_LOCALES'
export const CHANGE_LANGAUGE = 'CHANGE_LANGAUGE'

export const changeLanguage = (lang) => {
	
	const data = {
			type: CHANGE_LANGAUGE,
			payload: {
				language: lang
			}
	};

	global.switchLanguage(lang)

	return data

}