import React, { Component } from 'react'
import { connect }  from 'react-redux'

import { NavLink, Link } from "react-router-dom"
import { FormattedMessage } from 'react-intl'
import Langs from '../constants/Langs'
import localStorage from 'local-storage'

import jQuery from 'jquery';

import { changeLanguage } from '../actions/IntlActions'

class Header extends Component {

	constructor(props) {

		super(props)

		this.state = {
			languages: Langs.languages,
			selectLangName: '',
			selectedLang: this.props.currentLang,
			nowloca:window.location.pathname
		}

		this.handleLanguageChange = this.handleLanguageChange.bind(this)
		this.showDropdown = this.showDropdown.bind(this)
		this.hideDropdown = this.hideDropdown.bind(this)

	}

	componentDidMount(){
		jQuery(document).ready(function(){
			jQuery(".menu-toggle,.overlay-menu,.close-menu").on("click", function(e) {
				e.preventDefault();
				var f = jQuery(".main-nav");
				var t = jQuery(".overlay-menu");
				var xt = jQuery(".close-menu");
				var d = jQuery(".main-nav").width();
				var n = jQuery(".overlay-menu").width();
				f.toggleClass("open");
				t.toggleClass("open");
				xt.toggleClass("open");
				if (f.hasClass("open")) {
					f.animate({
						left: "0px",
						display: "block",
					})
				
				} else {
					f.animate({
						left: -d
					}, 280) 
				}; 
				if (t.hasClass("open")) {
					t.animate({
						left: "0px",
						display: "block",
					})
				
				} else {
					t.animate({
						left: -n 
					}, 280) 
				}
			})
		});
		
		jQuery(document).ready(function(){
				
			jQuery('#menu-top-menu li.active').addClass('open').children('ul').show();
			jQuery("ul.sub-menu").before("<span class='drop-menu'><i class='fa fa-angle-down'></i></span>");
			jQuery('#menu-top-menu li>.drop-menu,#menu-top-menu li>.drop-menu1').on('click', function(){
				
				var element = jQuery(this).parent('li');
				if (element.hasClass('open')) {
					element.removeClass('open');
					element.find('li').removeClass('open'); 
					element.find('ul').slideUp(200);
				} else {
					element.addClass('open');
					element.children('ul').slideDown(200);
					element.siblings('li').children('ul').slideUp(200);
					element.siblings('li').removeClass('open');
					element.siblings('li').find('li').removeClass('open');
					element.siblings('li').find('ul').slideUp(200); 
				}
		
			});
		
		});
	}

	handleLanguageChange(val) {

		this.setState({
			selectedLang: val
		})

		this.props.switchLanguage(val);

		localStorage.set('lang', val);

	}

	showDropdown(e) {

		e.stopPropagation();
		jQuery('div.select-styled.active').not(e.target).each(function(){
			jQuery(e.target).removeClass('active').next('ul.select-options').hide();
		});
		jQuery(e.target).addClass('active').next('ul.select-options').show();

	}

	hideDropdown(e) {

		e.stopPropagation();
		jQuery('div.select-styled.active').not(e.target).each(function(){
			jQuery(e.target).removeClass('active').next('ul.select-options').hide();
		});
		jQuery(e.target).removeClass('active').next('ul.select-options').hide();

	}

	render() {

		const self = this;

		/*
		<select className="selectpicker select-hidden" data-width="fit" onChange={this.handleLanguageChange}>
			{languages}
		</select>
		*/

		const languages = this.state.languages.map((language,index) => {

			if( this.state.selectedLang == language[0] ) {
				this.state.selectLangName = language[1];
			}

			return (
				<li value={language[0]} key={index} onClick={ () => { this.handleLanguageChange(language[0]) } }>
					<span>
						{language[1]}
					</span>
				</li>
			)

		})

		return (
			<header className="header">
				<div className="container">
					<div className="nav-min"><div className="overlay-menu"></div> 
						<nav className="navbar navbar-expand-lg navbar-light row navbar-default">
							<Link className="navbar-brand" to={'/'}>
								<img src="/images/logo.png" alt="logo" />
							</Link>
							<div className="right-bar d-flex flex-row">
							<button className="navbar-toggler menu-toggle" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
								<span className="navbar-toggler-icon"></span>
							</button>
							<ul className="defaultul">
							<li className="nav-item">
								<div className="nav-item lang">
									<div className="selectParent dropdown" onMouseLeave={this.hideDropdown} onClick={this.showDropdown}>
										<div className="select-styled dropdown-small dropdown-toggle" onMouseOver={this.showDropdown} >{ this.state.selectLangName }</div>
										<ul className="select-options sort-dropdown">
											{languages}
										</ul>
									</div>
								</div>
							</li>
							</ul>
							</div>
							
							<div className="navbar-collapse main-nav"><div className="close-menu"></div>
								<ul className="navbar-nav ml-auto" id="menu-top-menu">
									<li className="nav-item">
										<NavLink to={'/'} className="nav-link" activeClassName="active" exact>
											<FormattedMessage id="app.home"></FormattedMessage>
										</NavLink>
									</li>
									<li className="nav-item dropdown first-lavel">
										<NavLink to={'/our-solutions'} className="nav-link nav-link dropdown-toggle"  exact>
											<FormattedMessage id="app.our_solutions" defaultMessage="Our Solutions"></FormattedMessage>
										</NavLink>
										
										<ul className="sub-menu sub-menu-item" aria-labelledby="navbarDropdown">
												<div className="sub-menu-itembox">
													<li><a className="dropdown-item menu-title drop-menu1" href="#"><FormattedMessage id="COMPANY INCORPORATION" defaultMessage="COMPANY INCORPORATION"></FormattedMessage></a>
														<ul className="sub-menu">
														<li><a className={this.state.nowloca == '/order-form/1'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/1"><FormattedMessage id="SINGAPORE" defaultMessage="SINGAPORE"></FormattedMessage></a></li>
														<li><a className={this.state.nowloca == '/order-form/54'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/54"><FormattedMessage id="BRITISH VIRGIN ISLANDS" defaultMessage="BRITISH VIRGIN ISLANDS"></FormattedMessage></a></li>
														<li><a className={this.state.nowloca == '/order-form/53'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/53"><FormattedMessage id="CAYMAN" defaultMessage="CAYMAN"></FormattedMessage></a></li>
														<li><a className={this.state.nowloca == '/order-form/62'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/62"><FormattedMessage id="SEYCHELLES" defaultMessage="SEYCHELLES"></FormattedMessage></a></li> 
														<li><a className={this.state.nowloca == '/order-form/108'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/108"><FormattedMessage id="PANAMA" defaultMessage="PANAMA"></FormattedMessage></a></li> 
														</ul>
													</li>
													<li><a className="dropdown-item menu-title drop-menu1" href="#"><FormattedMessage id="GLOBAL STRUCTURING" defaultMessage="GLOBAL STRUCTURING"></FormattedMessage></a>
														<ul className="sub-menu">
															<li><a className={this.state.nowloca == '/common-business'?"dropdown-item dro_active":"dropdown-item"} href="/common-business"><FormattedMessage id="COMMON BUSINESS STRUCTURES" defaultMessage="COMMON BUSINESS STRUCTURES"></FormattedMessage></a></li>
															<li><a className={this.state.nowloca == '/order-form/67'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/67"><FormattedMessage id="SINGAPORE VARIABLE CAPITAL COMPANIES" defaultMessage="SINGAPORE VARIABLE CAPITAL COMPANIES"></FormattedMessage></a></li>
															<li><a className={this.state.nowloca == '/order-form/48'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/48"><FormattedMessage id="CAYMAN FUND" defaultMessage="CAYMAN FUND"></FormattedMessage></a></li>
															<li><a className="dropdown-item" href="#"><FormattedMessage id="SINGAPORE FUND" defaultMessage="SINGAPORE FUND"></FormattedMessage></a></li>
														</ul>
													</li>
												</div>
												<div className="sub-menu-itembox">
													<li className="dropdown"><a className="dropdown-item menu-title dropdown drop-menu1" href="#"><FormattedMessage id="IMMIGRATION & WORK PERMITS" defaultMessage="IMMIGRATION & WORK PERMITS"></FormattedMessage></a>
														<ul className="sub-menu">
														<li><a className={this.state.nowloca == '/order-form/65'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/65"><FormattedMessage id="EMPLOYMENT PASS" defaultMessage="EMPLOYMENT PASS"></FormattedMessage></a></li>
														<li><a className={this.state.nowloca == '/order-form/66'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/66"><FormattedMessage id="ENTERPASS" defaultMessage="ENTERPASS"></FormattedMessage></a></li>
														<li><a className={this.state.nowloca == '/order-form/107'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/107"><FormattedMessage id="DEPENDANT PASS" defaultMessage="DEPENDANT PASS"></FormattedMessage></a></li>
														</ul>
													</li>
													<li><a className="dropdown-item menu-title drop-menu1" href="#"><FormattedMessage id="TAX AND ACCOUNTING SERVICES" defaultMessage="TAX AND ACCOUNTING SERVICES"></FormattedMessage></a>										  
														<ul className="sub-menu">
															<li><a className={this.state.nowloca == '/order-form/55'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/55"><FormattedMessage id="BOOKKEEPING SERVICE" defaultMessage="BOOKKEEPING SERVICE"></FormattedMessage></a></li>
															<li><a className={this.state.nowloca == '/order-form/55'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/55"><FormattedMessage id="COMPILATION FINANCIAL STATEMENT" defaultMessage="COMPILATION FINANCIAL STATEMENT"></FormattedMessage></a></li>
															<li><a className={this.state.nowloca == '/order-form/24'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/24"><FormattedMessage id="CORPORATE TAX FILING" defaultMessage="CORPORATE TAX FILING"></FormattedMessage></a></li>
															<li><a className={this.state.nowloca == '/order-form/24'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/24"><FormattedMessage id="PERSONAL INCOME TAX" defaultMessage="PERSONAL INCOME TAX"></FormattedMessage></a></li>
														</ul>										  
													</li>
												</div>
												<div className="sub-menu-itembox">
													<li><a className="dropdown-item menu-title drop-menu1" href="#"><FormattedMessage id="CORPORATE SECRETARY" defaultMessage="CORPORATE SECRETARY"></FormattedMessage></a>										  
														<ul className="sub-menu">
														<li><a className={this.state.nowloca == '/order-form/11'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/11"><FormattedMessage id="SINGAPORE" defaultMessage="SINGAPORE"></FormattedMessage></a></li>
														<li><a className={this.state.nowloca == '/order-form/60'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/60"><FormattedMessage id="BRITISH VIRGIN ISLANDS" defaultMessage="BRITISH VIRGIN ISLANDS"></FormattedMessage></a></li>
														<li><a className={this.state.nowloca == '/order-form/58'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/58"><FormattedMessage id="CAYMAN" defaultMessage="CAYMAN"></FormattedMessage></a></li>
														<li><a className={this.state.nowloca == '/order-form/59'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/59"><FormattedMessage id="SEYCHELLES" defaultMessage="SEYCHELLES"></FormattedMessage></a></li>
														<li><a className={this.state.nowloca == '/order-form/111'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/111"><FormattedMessage id="PANAMA" defaultMessage="PANAMA"></FormattedMessage></a></li>
														</ul>
													</li>
													<li><a className="dropdown-item menu-title drop-menu1" href="#"><FormattedMessage id="TRADEMARK REGISTRATION" defaultMessage="TRADEMARK REGISTRATION"></FormattedMessage></a>
														<ul className="sub-menu">
															<li><a className={this.state.nowloca == '/order-form/45'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/45"><FormattedMessage id="SINGAPORE TRADEMARK" defaultMessage="SINGAPORE TRADEMARK"></FormattedMessage></a></li>
															<li><a className={this.state.nowloca == '/order-form/43'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/43"><FormattedMessage id="INTERNATIONAL TRADEMARK" defaultMessage="INTERNATIONAL TRADEMARK"></FormattedMessage></a></li>
															<li><a className={this.state.nowloca == '/order-form/44'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/44"><FormattedMessage id="TRADEMARK MANAGEMENT AND DISPUTE" defaultMessage="TRADEMARK MANAGEMENT AND DISPUTE"></FormattedMessage></a></li>
														</ul>	
													</li>
											</div>
										  {/* <li><a className="dropdown-item" href="#"><FormattedMessage id="COMPANY INCORPORATION" defaultMessage="COMPANY INCORPORATION"></FormattedMessage></a>
										  	<ul className="sub-menu">
												<li><a className={this.state.nowloca == '/order-form/1'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/1"><FormattedMessage id="SINGAPORE" defaultMessage="SINGAPORE"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/54'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/54"><FormattedMessage id="BRITISH VIRGIN ISLANDS" defaultMessage="BRITISH VIRGIN ISLANDS"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/53'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/53"><FormattedMessage id="CAYMAN" defaultMessage="CAYMAN"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/62'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/62"><FormattedMessage id="SEYCHELLES" defaultMessage="SEYCHELLES"></FormattedMessage></a></li> 
										    </ul>
										  </li>
										  <li className="dropdown"><a className="dropdown-item dropdown" href="#"><FormattedMessage id="IMMIGRATION & WORK PERMITS" defaultMessage="IMMIGRATION & WORK PERMITS"></FormattedMessage></a>
										  	<ul className="sub-menu">
												<li><a className={this.state.nowloca == '/order-form/65'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/65"><FormattedMessage id="EMPLOYMENT PASS" defaultMessage="EMPLOYMENT PASS"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/66'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/66"><FormattedMessage id="ENTERPASS" defaultMessage="ENTERPASS"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/65'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/65"><FormattedMessage id="DEPENDANT PASS" defaultMessage="DEPENDANT PASS"></FormattedMessage></a></li>
										    </ul>
										  </li>
										  <li><a className="dropdown-item" href="#"><FormattedMessage id="CORPORATE SECRETARY" defaultMessage="CORPORATE SECRETARY"></FormattedMessage></a>										  
										    <ul className="sub-menu">
												<li><a className={this.state.nowloca == '/order-form/11'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/11"><FormattedMessage id="SINGAPORE" defaultMessage="SINGAPORE"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/60'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/60"><FormattedMessage id="BRITISH VIRGIN ISLANDS" defaultMessage="BRITISH VIRGIN ISLANDS"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/58'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/58"><FormattedMessage id="CAYMAN" defaultMessage="CAYMAN"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/59'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/59"><FormattedMessage id="SEYCHELLES" defaultMessage="SEYCHELLES"></FormattedMessage></a></li>
										    </ul>
										  </li>
										  <li><a className="dropdown-item" href="#"><FormattedMessage id="GLOBAL STRUCTURING" defaultMessage="GLOBAL STRUCTURING"></FormattedMessage></a>
												<ul className="sub-menu">
													<li><a className={this.state.nowloca == '/common-business'?"dropdown-item dro_active":"dropdown-item"} href="/common-business"><FormattedMessage id="COMMON BUSINESS STRUCTURES" defaultMessage="COMMON BUSINESS STRUCTURES"></FormattedMessage></a></li>
													<li><a className={this.state.nowloca == '/order-form/67'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/67"><FormattedMessage id="SINGAPORE VARIABLE CAPITAL COMPANIES" defaultMessage="SINGAPORE VARIABLE CAPITAL COMPANIES"></FormattedMessage></a></li>
													<li><a className={this.state.nowloca == '/order-form/48'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/48"><FormattedMessage id="CAYMAN FUND" defaultMessage="CAYMAN FUND"></FormattedMessage></a></li>
													<li><a className="dropdown-item" href="#"><FormattedMessage id="SINGAPORE FUND" defaultMessage="SINGAPORE FUND"></FormattedMessage></a></li>
												</ul>
										  </li>
										  <li><a className="dropdown-item" href="#"><FormattedMessage id="TAX AND ACCOUNTING SERVICES" defaultMessage="TAX AND ACCOUNTING SERVICES"></FormattedMessage></a>										  
										  <ul className="sub-menu">
												<li><a className={this.state.nowloca == '/order-form/55'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/55"><FormattedMessage id="BOOKKEEPING SERVICE" defaultMessage="BOOKKEEPING SERVICE"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/55'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/55"><FormattedMessage id="COMPILATION FINANCIAL STATEMENT" defaultMessage="COMPILATION FINANCIAL STATEMENT"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/24'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/24"><FormattedMessage id="CORPORATE TAX FILING" defaultMessage="CORPORATE TAX FILING"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/24'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/24"><FormattedMessage id="PERSONAL INCOME TAX" defaultMessage="PERSONAL INCOME TAX"></FormattedMessage></a></li>
										    </ul>										  
										  </li>
										  <li><a className="dropdown-item" href="#"><FormattedMessage id="TRADEMARK REGISTRATION" defaultMessage="TRADEMARK REGISTRATION"></FormattedMessage></a>
										  <ul className="sub-menu">
												<li><a className={this.state.nowloca == '/order-form/45'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/45"><FormattedMessage id="SINGAPORE TRADEMARK" defaultMessage="SINGAPORE TRADEMARK"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/43'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/43"><FormattedMessage id="INTERNATIONAL TRADEMARK" defaultMessage="INTERNATIONAL TRADEMARK"></FormattedMessage></a></li>
												<li><a className={this.state.nowloca == '/order-form/44'?"dropdown-item dro_active":"dropdown-item"} href="/order-form/44"><FormattedMessage id="TRADEMARK MANAGEMENT AND DISPUTE" defaultMessage="TRADEMARK MANAGEMENT AND DISPUTE"></FormattedMessage></a></li>
										    </ul>	
										  </li> */}
										</ul>
									</li>
									<li className="nav-item">
										<NavLink to={'/about'} className="nav-link" exact>
											<FormattedMessage id="app.about_us" defaultMessage="About Us"></FormattedMessage>
										</NavLink>
									</li>
									<li className="nav-item">
										<NavLink className="nav-link" to={'/knowledge-center'}>
											<FormattedMessage id="app.knowledge_center" defaultMessage="Knowledge Center"></FormattedMessage>
										</NavLink>
									</li>
									
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</header>
		)
	}
}


const mapStateToProps = (state) => {
	return {
		orderForm: state.OrderForm.orderForm,
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	switchLanguage: (lang) => (dispatch) => {
		dispatch(changeLanguage(lang))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)