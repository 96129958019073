import React, { Component, Fragment } from 'react'

import { Link } from "react-router-dom"

import { connect } from 'react-redux'

class OrderSidebar extends Component {

	constructor(props) {

		super(props)

		this.assertTitle = this.assertTitle.bind(this)

		this.state = {};

		this.state.menus = {
			"menu-1": [
				[
					"Singapore",
					[
						{
							formId: 1,
							title: "Private Limited Company"
						},
						{
							formId: 5,
							title: "Company Limited By Guarantee"
						},
						{
							formId: 61,
							title: "Partnership"
						},
						{
							formId: 7,
							title: "Foreign Companies in Singapore"
						}
					]
				],
				[
					"Offshore",
					[
						{
							formId: 54,
							title: "British Virgin Island"
						},
						{
							formId: 53,
							title: "Cayman EC"
						},
						{
							formId: 52,
							title: "Cayman LLC"
						},
						{
							formId: 110,
							title: "Cayman ELP"
						},
						{
							formId: 62,
							title: "Seychelles"
						},
						{
							formId: 108,
							title: "Panama IBC"
						},
						{
							formId: 109,
							title: "Panama Foundation"
						},
					]
				]
			],
			"menu-2": [
				[
					"Singapore",
					[
						{
							formId: 11,
							title: "Private Limited Company"  
						},
						{
							formId: 23,
							title: "Company Limited By Guarantee"
						}
					]
				],
				[
					"Offshore",
					[
						{
							formId: 60,
							title: "British Virgin Island"
						},
						{
							formId: 58,
							title: "Cayman EC"
						},
						{
							formId: 57,
							title: "Cayman LLC"
						},
						{
							formId: 112,
							title: "Cayman ELP"
						},
						{
							formId: 59,
							title: "Seychelles"
						},
						{
							formId: 111,
							title: "Panama IBC"
						},
						{
							formId: 113,
							title: "Panama Foundation"
						},
					]
				]
			],
			"menu-3": [
				[
					"Fund",
					[
						{
							formId: 48,
							title: "Cayman GP/LP Fund"
						},
						{
							
							formId: 46,
							title: "Cayman SPC Fund"
						}
					]
				]
			]
		};

		this.state.zh_menus = {
			"menu-1": [
				[
					"新加坡",
					[
						{
							formId: 1,
							title: "私人有限公司"
						},
						{
							formId: 5,
							title: "公共担保有限公司"
						},
						{
							formId: 61,
							title: "合伙公司"
						},
						{
							formId: 7,
							title: "外国公司分支机构"
						}
					]
				],
				[
					"离岸公司",
					[
						{
							formId: 54,
							title: "英属维尔京群岛"
						},
						{
							formId: 53,
							title: "开曼可豁免公司"
						},
						{
							formId: 52,
							title: "开曼有限责任公司"
						},
						{
							formId: 110,
							title: "开曼豁免有限合伙"
						},
						{
							formId: 62,
							title: "塞舌尔"
						},
						{
							formId: 108,
							title: "巴拿马国际商务公司"
						},
						{
							formId: 109,
							title: "巴拿马基金会"
						}
					]
				]
			],
			"menu-2": [
				[
					"新加坡",
					[
						{
							formId: 11,
							title: "私人有限公司"  
						},
						{
							formId: 23,
							title: "公共担保有限公司"
						}
					]
				],
				[
					"离岸公司",
					[
						{
							formId: 60,
							title: "英属维尔京群岛"
						},
						{
							formId: 58,
							title: "开曼可豁免公司"
						},
						{
							formId: 57,
							title: "开曼有限责任公司"
						},
						{
							formId: 112,
							title: "开曼豁免有限合伙"
						},
						{
							formId: 59,
							title: "塞舌尔"
						},
						{
							formId: 111,
							title: "巴拿马国际商务公司"
						},
						{
							formId: 113,
							title: "巴拿马基金会"
						}
					]
				]
			],
			"menu-3": [
				[
					"基金",
					[
						{
							formId: 48,
							title: "开曼封闭式股权基金"
						},
						{
							
							formId: 46,
							title: "开曼独立投资组合基金"
						}
					]
				]
			]
		};

	}

	assertTitle(currentItem) {

		return this.props.currentLang == 'en' ? currentItem.title : currentItem.chinese_title

	}

	render() {

		const self = this;

		const sidebarMenuList = [];

		const currentMenu = self.props.orderForm.sidebar_menu;

		let selectedMenu;

		if(this.props.currentLang == 'en'){
			selectedMenu = this.state.menus[currentMenu] ? this.state.menus[currentMenu] : this.state.menus["menu-1"];
		}else{
			selectedMenu = this.state.zh_menus[currentMenu] ? this.state.zh_menus[currentMenu] : this.state.zh_menus["menu-1"];
		}

		for (let i = 0; i < selectedMenu.length; i++) {
			sidebarMenuList.push(
				<Fragment key={i}>
					<h3 className="cat-title">
						{selectedMenu[i][0]}
					</h3>
					<ul className="catlist">
						{ selectedMenu[i][1].map((frm,index) => {
							return (
								<li className={ self.props.orderForm.formId == frm.formId ? "active" : "" } key={index} >
									<Link to={'/order-form/' + frm.formId}>
										{ frm.title }
									</Link>
								</li>
							)
						})}
					</ul>
				</Fragment>
			);
		}

		return (
			<div className="col-md-3 left-sidebar">
				{ sidebarMenuList }
			</div>
		);

	}

}

const mapStateToProps = (state) => {
	return {
		orderForm: state.OrderForm.orderForm,
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSidebar)