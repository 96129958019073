import BaseService from './BaseService'

export default class AcraService extends BaseService {

	checkNameAvailability(term) {
		return this.apiCall(
			`acra/check`,
			'POST',
			{
				term
			},
			true
		).then((response) => {
			return response.data
		})
	}

	checkNameExistence(term) {
		return this.apiCall(
			`acra/checkExistence`,
			'POST',
			{
				term
			},
			true
		).then((response) => {
			return response.data
		})
	}

	setBizfileName(companyName) {
		return this.apiCall(
			`acra/setBizfileName`,
			'POST',
			{
				companyName
			},
			true
		).then((response) => {
			return response.data
		})
	}

	getBizfileName() {
		return this.apiCall(
			`acra/getBizfileName`,
			'POST',
			{},
			true
		).then((response) => {
			return response.data
		})
	}

}