import React, { Component, Fragment } from 'react'

import { Link } from "react-router-dom"

import { connect } from 'react-redux'

import ChildFormItem from './ChildFormItem'
import { FormattedNumber, FormattedMessage } from 'react-intl'

import { addOrderItem, removeOrderItem } from '../actions/OrderCartActions'
import paymentTypeAll from '../constants/payment_type'

class OrderFormItem extends Component {

	constructor(props) {

		super(props)

		this.toggleCartItem = this.toggleCartItem.bind(this)
		this.state = {
			isChecked: false
		}

		this.assertTitle = this.assertTitle.bind(this)
		this.assertDescription = this.assertDescription.bind(this)
		this.showSubTitle = this.showSubTitle.bind(this)

	}

	toggleCartItem(event) {

		/*if( this.props.itemType == "required" ) {
			return event.preventDefault();
		}*/

		const currentItem = this.props.currentItem
		currentItem.itemType = this.props.itemType

		if( event.target.checked ) {
			this.props.addToCart(currentItem, null)
		} else {
			this.props.removeFromCart(currentItem, null)
		}

		this.setState({
			isChecked: event.target.checked
		});

	}

	assertTitle(currentItem) {

		// return this.props.currentLang == 'en' ? currentItem.title : currentItem.chinese_title
		if(this.props.currentLang == 'en'){
			return currentItem.title
		} else {
			let cn_sub_title = '';
			if(currentItem.cn_sub_title){
				cn_sub_title = currentItem.cn_sub_title;
			}
			return (
			<div className={"item-title1 itemTitle-productId" + currentItem.product_id }>{currentItem.chinese_title}<span className="item-title1-tips">{cn_sub_title}</span>
			{this.props.currentLang == 'en'? '':<span className='title-link' dangerouslySetInnerHTML={{ __html: currentItem.title_link }} />}
			</div>
			)
		}

	}

	assertDescription(currentItem) {

		return this.props.currentLang == 'en' ? currentItem.description : currentItem.chinese_description

	}

	// showPaymentType(currentItem) {
	// 	let innerContent = '';
	// 	return currentItem.payment_type == '' ? '' : <span>(
	// 		{
	// 			~currentItem.payment_type.indexOf('Contact') ? (
	// 				<Fragment>
	// 					{currentItem.payment_type.replace(/(Contact Us)/g,'')}
	// 					<Link to={'/contact'}>Contact Us</Link>
	// 				</Fragment>
	// 			) : currentItem.payment_type
	// 		}
	// 	)</span>
	// }
	showPaymentType(currentItem) {
		let innerContent = '';
		let payment_type = currentItem.payment_type;
		if(this.props.currentLang == 'en'){
			if(payment_type == ''){
				return '';
			}else{
				return <span>(
					{
						~payment_type.indexOf('Contact') ? (
							<Fragment>
								{payment_type.replace(/(Contact Us)/g,'')}
								<Link to={'/contact'}>Contact Us</Link>
							</Fragment>
						) : payment_type
					}
					)</span>;
			}
		}else{
			if(paymentTypeAll[payment_type]){
				let cn_txt = paymentTypeAll[payment_type];
				if(payment_type == 'Please Contact Us'){
					return <Fragment>请<Link to={'/contact'}>联系我们</Link></Fragment>
				}else{
					return '(' + cn_txt + ')';
				}
			}else{
				return ''
			}
		}
	}

	showSubTitle(currentItem) {
		return this.props.currentLang == 'en' ? currentItem.en_sub_title : ''
	}

	render() {

		const currentIndex = this.props.currentIndex
		const currentItem = this.props.currentItem
		const itemType = this.props.itemType

		if( this.props.existInCart( currentItem.product_id, this.props.cart ) && this.state.isChecked != true ) {
			this.setState({
				isChecked: true
			})
		}

		let smallTitle = '';

		if( itemType == 'recommended' )
			smallTitle = (<FormattedMessage id="orderCart.recommended_items" defaultMessage="Recommended Items"></FormattedMessage>);
		else if( itemType == 'addon' )
			smallTitle = (<FormattedMessage id="orderCart.valued_services" defaultMessage="Value-Added Services"></FormattedMessage>);
		else if( itemType == 'maintenance' )
			smallTitle = (<FormattedMessage id="orderCart.maintenance_services" defaultMessage="Maintenance Services"></FormattedMessage>);

		const childItems = currentItem.child.map((currentChildItem) => {
			return (
				<ChildFormItem currentItem={currentChildItem} parentItem={currentItem} />
			)
		});

		//<span className="price">{currentItem.price} {currentItem.currency}</span>

		return (
			<Fragment>
				<li className={ "product-" + currentItem.product_id + " " + currentItem.itemClass }>
					{
						currentIndex == 0 && smallTitle ? (
							<Fragment>
								<li className="item-type-heading heading_2">
									<span className="small-title"> {smallTitle}</span>
									{
										this.props.orderForm.required.length == 0 ? <FormattedMessage id="Price(USD)" defaultMessage="Price(USD)"></FormattedMessage> : ''
									}
								</li>
							</Fragment>
						) : ''
					}
					<div  className="d-flex row justify-content-between">
						<div className="col-sm-8">
							{
								(currentItem.price > 0 || currentItem.isFree) && !currentItem.checkboxHidden ? (
									<label className="custom-checkbox">
										<input type="checkbox" onChange={this.toggleCartItem} checked={this.state.isChecked} />
										<span className="check"></span>
									</label>
								) : ''
							}
							<div className="item-title">{this.assertTitle(currentItem)} <span className="item-title-suffix"> <span style={{fontFamily:'HelveticaNeueMedium'}}>{this.showSubTitle(currentItem)}</span> {this.showPaymentType(currentItem)}</span> {
								this.props.currentLang == 'zh' && currentItem.itemClass.indexOf('contact-us')!=-1 ? (<span style={{fontWeight:'normal'}}>
									, <a href="https://jengacorp.com/contact">联系我们</a>获取报价
								</span>):''
							}</div>
							<p dangerouslySetInnerHTML={{ __html: this.assertDescription(currentItem) }}></p>
						</div>
						<div className="col-sm-3">
							{
								currentItem.price > 0 ? (
									<span className="price">
										{currentItem.price_prefix ? currentItem.price_prefix+' ' : ''}
										$<FormattedNumber value={currentItem.price} minimumFractionDigits={0} ></FormattedNumber>
										{this.props.currentLang == 'en'?'':(<p>{currentItem.pay_after}</p>)}
									</span>
								) : (
									currentItem.isFree ? (
										<span className="price">
											{currentItem.itemClass ? '':<FormattedMessage id="Free" defaultMessage="Free"></FormattedMessage>}
										</span>
									) : ''
								)
							}
						</div>
					</div>
					{ childItems }
				</li>
				{ currentItem.requiredAfter ? (
					<li className="title-top">
						<div className="small-title">
						<FormattedMessage id="Required Items" defaultMessage="Required Items"></FormattedMessage>
						</div>
						{/* <FormattedMessage id="Price(USD)" defaultMessage="Price(USD)"></FormattedMessage> */}
					</li>
				) : '' }
			</Fragment>
		)

	}

}

const mapStateToProps = (state) => {
	return {
		orderForm: state.OrderForm.orderForm,
		cart: state.OrderCart.orderItems,
		currentLang: state.locales.currentLang
	}
}

const mapDispatchToProps = {
	existInCart: (itemId, cart) => (dispatch) => {
		const found = cart.find((item) => item.product_id == itemId)
		if( found )
			return true;
		return false;
	},
	addToCart: (orderItem,parentItem) => (dispatch) => {
		dispatch(addOrderItem({orderItem, parentItem}))
	},
	removeFromCart: (orderItem,parentItem) => (dispatch) => {
		dispatch(removeOrderItem({orderItem, parentItem}))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderFormItem)